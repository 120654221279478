//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import api from "../../services/api";
import { server } from "../../services/constants";

export default {
  computed: {
    version() {
      return process.env.VUE_APP_VERSION;
    }
  },
  methods: {
    onMouseEnter() {
      if (!this.isClicked) {
        this.isDoubleClicked = false; 
        this.$store.state.Drawer = false;
        console.log("Mouse entered, Drawer is now false");
      }
    },
    onMouseLeave() {
      if (!this.isClicked) {
        this.$store.state.Drawer = true;
        console.log("Mouse left, Drawer is now true");
      }
    },

    onClickMenuItem(link,mode) {

      this.isClicked = true;
      localStorage.setItem(server.MODELMENU, link.index);
      
      this.$router.push(link.route).catch((err) => {});

      if(mode == `mainmenu`){
        if(link.items.length == 0){
          setTimeout(() => {
            this.isClicked = false;
          }, 50); 
        }else{

          if(this.isBeForMenu == link.title){
            this.isDoubleClicked = !this.isDoubleClicked;
          }else{
            this.isDoubleClicked = true;
            this.isBeForMenu = link.title;
          }

          if(!this.isDoubleClicked){
            setTimeout(() => {
              this.isClicked = false;
            }, 50); 
          }
        }
      }else{
        this.isDoubleClicked = !this.isDoubleClicked;
        this.isClicked = false;
        this.$store.state.Drawer = true;
      }


    },
    onClickLogo() {
      this.isDoubleClicked = false; 
      this.isClicked = true; // ตั้งค่า isClicked เป็น true เมื่อคลิกโลโก้
      this.$store.state.Drawer = true; // ตั้งค่า Drawer เป็น true
      
      setTimeout(() => {
        this.isClicked = false; // คืนค่าเป็น false หลังจากเวลาที่กำหนด
      }, 500); // หน่วงเวลา 500ms หลังจากคลิก
    },
    closeSubMenu(){
      this.$store.state.Drawer = true;
      setTimeout(() => {
        this.isClicked = false; // คืนค่าเป็น false หลังจากเวลาที่กำหนด
      }, 50);
    }
  },
  async mounted() {
    this.$store.state.modelmenu = parseInt(
      localStorage.getItem(server.MODELMENU)
    );

    // let loader = this.$loading.show({
    //   // Optional parameters
    //   color: "green",
    //   loader: "dots",
    //   container: this.fullPage ? null : this.$refs.formContainer,
    //   canCancel: false,
    //   width: 120,
    //   height: 120,
    //   backgroundColor: "#ffffff",
    //   opacity: 0.5,
    //   zIndex: 999,
    //   onCancel: this.onCancel,
    // });

    //   alert("initialize");
    // const res_get = await api.getSettingGroupMenu();
    const res_get = await api.getListMenuDetailById(
      localStorage.getItem(server.AUTHORIZE_ID)
    );

    this.authorize = res_get.data;
    if(this.authorize.length == 0){
      this.authorize = [{}];
    }
    // console.log("authorize: " + JSON.stringify(this.authorize));
    // loader.hide();

    // check menu
    // 1.dashboard
    // 2.employee
    // 3.customer
    // 4.1 group-menu
    // 4.2 menu-detail
    // 4.3 setting-group-menu
    // 4.4 setting-menu-detail
    // 5.report
    // 6.1 so-list
    // 6.2 add-so
    // 7.1 advance-list
    // 7.2 new-advance
    
    this.authorize.forEach((item, indexauthorize) => {
      // alert(item.cmd_route);

      // dashboard menu
      //   if (item.cmd_route == "dashboard" && item.smd_view == 0) {
      //   let getremoveindex = [];
      //   this.menus.forEach((itemmenu, index) => {
      //     if (itemmenu.title == "Dashboard") {
      //       getremoveindex.push(index);
      //     }
      //   });
      //   for (var i = getremoveindex.length - 1; i >= 0; i--)
      //     this.menus.splice(getremoveindex[i], 1);
      // }

      // administrator menu
      // cut administrator company
      if (!this.authorize.some(item => item.cmd_route === 'company') || item.cmd_route == "company" && item.smd_view == 0) {
        this.subcompany = true;
        let getremoveindex = [];
        let indexsubmenu = -1;
        this.menus.forEach((itemmenu, index) => {
          if (itemmenu.title == "Administrator") {
            indexsubmenu = index;
            this.menus[index].items.forEach((x, i) => {
              if (x.title == "Company") {
                getremoveindex.push(i);
              }
            });
          }
        });
        for (var i = getremoveindex.length - 1; i >= 0; i--)
          this.menus[indexsubmenu].items.splice(getremoveindex[i], 1);
      }

      // cut administrator department
      if (!this.authorize.some(item => item.cmd_route === 'department') || item.cmd_route == "department" && item.smd_view == 0) {
        this.subdepartment = true;
        let getremoveindex = [];
        let indexsubmenu = -1;
        this.menus.forEach((itemmenu, index) => {
          if (itemmenu.title == "Administrator") {
            indexsubmenu = index;
            this.menus[index].items.forEach((x, i) => {
              if (x.title == "Department") {
                getremoveindex.push(i);
              }
            });
          }
        });
        for (var i = getremoveindex.length - 1; i >= 0; i--)
          this.menus[indexsubmenu].items.splice(getremoveindex[i], 1);
      }

      // cut administrator position
      if (!this.authorize.some(item => item.cmd_route === 'position') || item.cmd_route == "position" && item.smd_view == 0) {
        this.subposition = true;
        let getremoveindex = [];
        let indexsubmenu = -1;
        this.menus.forEach((itemmenu, index) => {
          if (itemmenu.title == "Administrator") {
            indexsubmenu = index;
            this.menus[index].items.forEach((x, i) => {
              if (x.title == "Position") {
                getremoveindex.push(i);
              }
            });
          }
        });
        for (var i = getremoveindex.length - 1; i >= 0; i--)
          this.menus[indexsubmenu].items.splice(getremoveindex[i], 1);
      }

      // cut administrator jobtitle
      if (!this.authorize.some(item => item.cmd_route === 'jobtitle') || item.cmd_route == "jobtitle" && item.smd_view == 0) {
        this.subjobtitle = true;
        let getremoveindex = [];
        let indexsubmenu = -1;
        this.menus.forEach((itemmenu, index) => {
          if (itemmenu.title == "Administrator") {
            indexsubmenu = index;
            this.menus[index].items.forEach((x, i) => {
              if (x.title == "Job Title") {
                getremoveindex.push(i);
              }
            });
          }
        });
        for (var i = getremoveindex.length - 1; i >= 0; i--)
          this.menus[indexsubmenu].items.splice(getremoveindex[i], 1);
      }

      // cut administrator leave-quota
      if (!this.authorize.some(item => item.cmd_route === 'leave-quota') || item.cmd_route == "leave-quota" && item.smd_view == 0) {
        this.subleavequota = true;
        let getremoveindex = [];
        let indexsubmenu = -1;
        this.menus.forEach((itemmenu, index) => {
          if (itemmenu.title == "Administrator") {
            indexsubmenu = index;
            this.menus[index].items.forEach((x, i) => {
              if (x.title == "Leave Quota") {
                getremoveindex.push(i);
              }
            });
          }
        });
        for (var i = getremoveindex.length - 1; i >= 0; i--)
          this.menus[indexsubmenu].items.splice(getremoveindex[i], 1);
      }

      // cut administrator document-setup
      if (!this.authorize.some(item => item.cmd_route === 'document-setup') || item.cmd_route == "document-setup" && item.smd_view == 0) {
        this.subdocumentsetup = true;
        let getremoveindex = [];
        let indexsubmenu = -1;
        this.menus.forEach((itemmenu, index) => {
          if (itemmenu.title == "Administrator") {
            indexsubmenu = index;
            this.menus[index].items.forEach((x, i) => {
              if (x.title == "Document Setup") {
                getremoveindex.push(i);
              }
            });
          }
        });
        for (var i = getremoveindex.length - 1; i >= 0; i--)
          this.menus[indexsubmenu].items.splice(getremoveindex[i], 1);
      }

      // cut administrator contact-list
      if (!this.authorize.some(item => item.cmd_route === 'contact-list') || item.cmd_route == "contact-list" && item.smd_view == 0) {
        this.subcontactlist = true;
        let getremoveindex = [];
        let indexsubmenu = -1;
        this.menus.forEach((itemmenu, index) => {
          if (itemmenu.title == "Administrator") {
            indexsubmenu = index;
            this.menus[index].items.forEach((x, i) => {
              if (x.title == "IP Contact") {
                getremoveindex.push(i);
              }
            });
          }
        });
        for (var i = getremoveindex.length - 1; i >= 0; i--)
          this.menus[indexsubmenu].items.splice(getremoveindex[i], 1);
      }

      // cut administrator ip-price-list
      if (!this.authorize.some(item => item.cmd_route === 'ip-price-list') || item.cmd_route == "ip-price-list" && item.smd_view == 0) {
        this.subippricelist = true;
        let getremoveindex = [];
        let indexsubmenu = -1;
        this.menus.forEach((itemmenu, index) => {
          if (itemmenu.title == "Administrator") {
            indexsubmenu = index;
            this.menus[index].items.forEach((x, i) => {
              if (x.title == "IP Price List") {
                getremoveindex.push(i);
              }
            });
          }
        });
        for (var i = getremoveindex.length - 1; i >= 0; i--)
          this.menus[indexsubmenu].items.splice(getremoveindex[i], 1);
      }

      // cut administrator invoice-title
      if (!this.authorize.some(item => item.cmd_route === 'invoice-title') || item.cmd_route == "invoice-title" && item.smd_view == 0) {
        this.subinvoicetitle = true;
        let getremoveindex = [];
        let indexsubmenu = -1;
        this.menus.forEach((itemmenu, index) => {
          if (itemmenu.title == "Administrator") {
            indexsubmenu = index;
            this.menus[index].items.forEach((x, i) => {
              if (x.title == "Invoice Title") {
                getremoveindex.push(i);
              }
            });
          }
        });
        for (var i = getremoveindex.length - 1; i >= 0; i--)
          this.menus[indexsubmenu].items.splice(getremoveindex[i], 1);
      }

      // cut administrator miscellaneous-list
      if (!this.authorize.some(item => item.cmd_route === 'miscellaneous-list') || item.cmd_route == "miscellaneous-list" && item.smd_view == 0) {
        this.submiscellaneouslist = true;
        let getremoveindex = [];
        let indexsubmenu = -1;
        this.menus.forEach((itemmenu, index) => {
          if (itemmenu.title == "Administrator") {
            indexsubmenu = index;
            this.menus[index].items.forEach((x, i) => {
              if (x.title == "Invoice Miscellaneous") {
                getremoveindex.push(i);
              }
            });
          }
        });
        for (var i = getremoveindex.length - 1; i >= 0; i--)
          this.menus[indexsubmenu].items.splice(getremoveindex[i], 1);
      }

      // cut administrator im-master-placeofissue-list
      if (!this.authorize.some(item => item.cmd_route === 'im-master-placeofissue-list') || 
        item.cmd_route == "im-master-placeofissue-list" &&
        item.smd_view == 0
      ) {
        this.subimmasterplaceofissuelist = true;
        let getremoveindex = [];
        let indexsubmenu = -1;
        this.menus.forEach((itemmenu, index) => {
          if (itemmenu.title == "Administrator") {
            indexsubmenu = index;
            this.menus[index].items.forEach((x, i) => {
              if (x.title == "Immigration Place of issue") {
                getremoveindex.push(i);
              }
            });
          }
        });
        for (var i = getremoveindex.length - 1; i >= 0; i--)
          this.menus[indexsubmenu].items.splice(getremoveindex[i], 1);
      }

      // cut administrator im-master-workplaceofissue-list
      if (
        !this.authorize.some(item => item.cmd_route === 'im-master-workplaceofissue-list') || 
        item.cmd_route == "im-master-workplaceofissue-list" &&
        item.smd_view == 0
      ) {
        this.subimmasterworkplaceofissuelist = true;
        let getremoveindex = [];
        let indexsubmenu = -1;
        this.menus.forEach((itemmenu, index) => {
          if (itemmenu.title == "Administrator") {
            indexsubmenu = index;
            this.menus[index].items.forEach((x, i) => {
              if (x.title == "Immigration Work Place of issue") {
                getremoveindex.push(i);
              }
            });
          }
        });
        for (var i = getremoveindex.length - 1; i >= 0; i--)
          this.menus[indexsubmenu].items.splice(getremoveindex[i], 1);
      }

      // cut administrator leave-holiday
      if (!this.authorize.some(item => item.cmd_route === 'leave-holiday') || item.cmd_route == "leave-holiday" && item.smd_view == 0) {
        this.subleaveholiday = true;
        let getremoveindex = [];
        let indexsubmenu = -1;
        this.menus.forEach((itemmenu, index) => {
          if (itemmenu.title == "Administrator") {
            indexsubmenu = index;
            this.menus[index].items.forEach((x, i) => {
              if (x.title == "Public Holiday") {
                getremoveindex.push(i);
              }
            });
          }
        });
        for (var i = getremoveindex.length - 1; i >= 0; i--)
          this.menus[indexsubmenu].items.splice(getremoveindex[i], 1);
      }

      // cut administrator training-list
      if (!this.authorize.some(item => item.cmd_route === 'training-list') || item.cmd_route == "training-list" && item.smd_view == 0) {
        this.subtraininglist = true;
        let getremoveindex = [];
        let indexsubmenu = -1;
        this.menus.forEach((itemmenu, index) => {
          if (itemmenu.title == "Administrator") {
            indexsubmenu = index;
            this.menus[index].items.forEach((x, i) => {
              if (x.title == "Training") {
                getremoveindex.push(i);
              }
            });
          }
        });
        for (var i = getremoveindex.length - 1; i >= 0; i--)
          this.menus[indexsubmenu].items.splice(getremoveindex[i], 1);
      }   
      
      if (!this.authorize.some(item => item.cmd_route === 'leave-title') || item.cmd_route == "leave-title" && item.smd_view == 0) {
        this.subleavetitle = true;
        let getremoveindex = [];
        let indexsubmenu = -1;
        this.menus.forEach((itemmenu, index) => {
          if (itemmenu.title == "Administrator") {
            indexsubmenu = index;
            this.menus[index].items.forEach((x, i) => {
              if (x.title == "Leave Title") {
                getremoveindex.push(i);
              }
            });
          }
        });
        for (var i = getremoveindex.length - 1; i >= 0; i--)
          this.menus[indexsubmenu].items.splice(getremoveindex[i], 1);
      }   
      if (!this.authorize.some(item => item.cmd_route === 'advance-title') || item.cmd_route == "advance-title" && item.smd_view == 0) {
        this.subadvancetitle = true;
        let getremoveindex = [];
        let indexsubmenu = -1;
        this.menus.forEach((itemmenu, index) => {
          if (itemmenu.title == "Administrator") {
            indexsubmenu = index;
            this.menus[index].items.forEach((x, i) => {
              if (x.title == "Advance Title") {
                getremoveindex.push(i);
              }
            });
          }
        });
        for (var i = getremoveindex.length - 1; i >= 0; i--)
          this.menus[indexsubmenu].items.splice(getremoveindex[i], 1);
      }   
      if (!this.authorize.some(item => item.cmd_route === 'employee') || item.cmd_route == "employee" && item.smd_view == 0) {
        this.subemployee = true;
        let getremoveindex = [];
        let indexsubmenu = -1;
        this.menus.forEach((itemmenu, index) => {
          if (itemmenu.title == "Administrator") {
            indexsubmenu = index;
            this.menus[index].items.forEach((x, i) => {
              if (x.title == "Employee") {
                getremoveindex.push(i);
              }
            });
          }
        });
        for (var i = getremoveindex.length - 1; i >= 0; i--)
          this.menus[indexsubmenu].items.splice(getremoveindex[i], 1);
      }   

      // =====================================================================================================================================================
      // cut employee
      // if (!this.authorize.some(item => item.cmd_route === 'employee') || item.cmd_route == "employee" && item.smd_view == 0) {
      //   let getremoveindex = [];
      //   this.menus.forEach((itemmenu, index) => {
      //     if (itemmenu.title == "Employee") {
      //       getremoveindex.push(index);
      //     }
      //   });
      //   for (var i = getremoveindex.length - 1; i >= 0; i--)
      //     this.menus.splice(getremoveindex[i], 1);
      // }
      // =====================================================================================================================================================
      // cut customer
      if (!this.authorize.some(item => item.cmd_route === 'customer') || item.cmd_route == "customer" && item.smd_view == 0) {
        let getremoveindex = [];
        this.menus.forEach((itemmenu, index) => {
          if (itemmenu.title == "Customer") {
            getremoveindex.push(index);
          }
        });
        for (var i = getremoveindex.length - 1; i >= 0; i--)
          this.menus.splice(getremoveindex[i], 1);
      }

      // =====================================================================================================================================================

      // cut config group-menu
      if (!this.authorize.some(item => item.cmd_route === 'group-menu') || item.cmd_route == "group-menu" && item.smd_view == 0) {
        this.subgroupmenu = true;
        let getremoveindex = [];
        let indexsubmenu = -1;
        this.menus.forEach((itemmenu, index) => {
          if (itemmenu.title == "Configuration") {
            indexsubmenu = index;
            this.menus[index].items.forEach((x, i) => {
              if (x.title == "Group Menu") {
                getremoveindex.push(i);
              }
            });
          }
        });
        for (var i = getremoveindex.length - 1; i >= 0; i--)
          this.menus[indexsubmenu].items.splice(getremoveindex[i], 1);
      }

      // cut config menu-detail
      if (!this.authorize.some(item => item.cmd_route === 'menu-detail') || item.cmd_route == "menu-detail" && item.smd_view == 0) {
        this.submenudetail = true;
        let getremoveindex = [];
        let indexsubmenu = -1;
        this.menus.forEach((itemmenu, index) => {
          if (itemmenu.title == "Configuration") {
            indexsubmenu = index;
            this.menus[index].items.forEach((x, i) => {
              if (x.title == "Sub Menu") {
                getremoveindex.push(i);
              }
            });
          }
        });
        for (var i = getremoveindex.length - 1; i >= 0; i--)
          this.menus[indexsubmenu].items.splice(getremoveindex[i], 1);
      }

      // cut config setting-group-menu
      if (!this.authorize.some(item => item.cmd_route === 'setting-group-menu') || item.cmd_route == "setting-group-menu" && item.smd_view == 0) {
        this.subsettinggroupmenu = true;
        let getremoveindex = [];
        let indexsubmenu = -1;
        this.menus.forEach((itemmenu, index) => {
          if (itemmenu.title == "Configuration") {
            indexsubmenu = index;
            this.menus[index].items.forEach((x, i) => {
              if (x.title == "Setting Authorization") {
                getremoveindex.push(i);
              }
            });
          }
        });
        for (var i = getremoveindex.length - 1; i >= 0; i--)
          this.menus[indexsubmenu].items.splice(getremoveindex[i], 1);
      }

      // cut config mapping-keyword
      if (!this.authorize.some(item => item.cmd_route === 'mapping-keyword') || item.cmd_route == "mapping-keyword" && item.smd_view == 0) {
        this.submappingkeyword = true;
        let getremoveindex = [];
        let indexsubmenu = -1;
        this.menus.forEach((itemmenu, index) => {
          if (itemmenu.title == "Configuration") {
            indexsubmenu = index;
            this.menus[index].items.forEach((x, i) => {
              if (x.title == "IP Mapping Keyword") {
                getremoveindex.push(i);
              }
            });
          }
        });
        for (var i = getremoveindex.length - 1; i >= 0; i--)
          this.menus[indexsubmenu].items.splice(getremoveindex[i], 1);
      }
      // cut config doc running
      if (!this.authorize.some(item => item.cmd_route === 'doc-running') || item.cmd_route == "doc-running" && item.smd_view == 0) {
        this.subdocrunning = true;
        let getremoveindex = [];
        let indexsubmenu = -1;
        this.menus.forEach((itemmenu, index) => {
          if (itemmenu.title == "Configuration") {
            indexsubmenu = index;
            this.menus[index].items.forEach((x, i) => {
              if (x.title == "Doc-Running") {
                getremoveindex.push(i);
              }
            });
          }
        });
        for (var i = getremoveindex.length - 1; i >= 0; i--)
          this.menus[indexsubmenu].items.splice(getremoveindex[i], 1);
      }

      // =====================================================================================================================================================

      // cut Time Sheet my-activity
      if (!this.authorize.some(item => item.cmd_route === 'my-activity') || item.cmd_route == "my-activity" && item.smd_view == 0) {
        this.submyactivity = true;
        let getremoveindex = [];
        let indexsubmenu = -1;
        this.menus.forEach((itemmenu, index) => {
          if (itemmenu.title == "Time Sheet") {
            indexsubmenu = index;
            this.menus[index].items.forEach((x, i) => {
              if (x.title == "My Activity") {
                getremoveindex.push(i);
              }
            });
          }
        });
        for (var i = getremoveindex.length - 1; i >= 0; i--)
          this.menus[indexsubmenu].items.splice(getremoveindex[i], 1);
      }

      // cut Time Sheet new-activity
      if (!this.authorize.some(item => item.cmd_route === 'new-activity') || item.cmd_route == "new-activity" && item.smd_view == 0) {
        this.subnewactivity = true;
        let getremoveindex = [];
        let indexsubmenu = -1;
        this.menus.forEach((itemmenu, index) => {
          if (itemmenu.title == "Time Sheet") {
            indexsubmenu = index;
            this.menus[index].items.forEach((x, i) => {
              if (x.title == "New Activity") {
                getremoveindex.push(i);
              }
            });
          }
        });
        for (var i = getremoveindex.length - 1; i >= 0; i--)
          this.menus[indexsubmenu].items.splice(getremoveindex[i], 1);
      }

      // cut Time Sheet my-time-sheet
      if (!this.authorize.some(item => item.cmd_route === 'my-time-sheet') || item.cmd_route == "my-time-sheet" && item.smd_view == 0) {
        this.submytimesheet = true;
        let getremoveindex = [];
        let indexsubmenu = -1;
        this.menus.forEach((itemmenu, index) => {
          if (itemmenu.title == "Time Sheet") {
            indexsubmenu = index;
            this.menus[index].items.forEach((x, i) => {
              if (x.title == "My Time Sheet") {
                getremoveindex.push(i);
              }
            });
          }
        });
        for (var i = getremoveindex.length - 1; i >= 0; i--)
          this.menus[indexsubmenu].items.splice(getremoveindex[i], 1);
      }

      // cut Time Sheet approve-time-sheet
      if (!this.authorize.some(item => item.cmd_route === 'approve-time-sheet') || item.cmd_route == "approve-time-sheet" && item.smd_view == 0) {
        this.subapprovetimesheet = true;
        let getremoveindex = [];
        let indexsubmenu = -1;
        this.menus.forEach((itemmenu, index) => {
          if (itemmenu.title == "Time Sheet") {
            indexsubmenu = index;
            this.menus[index].items.forEach((x, i) => {
              if (x.title == "Approve Time Sheet") {
                getremoveindex.push(i);
              }
            });
          }
        });
        for (var i = getremoveindex.length - 1; i >= 0; i--)
          this.menus[indexsubmenu].items.splice(getremoveindex[i], 1);
      }

      // cut Time Sheet activity-report
      if (!this.authorize.some(item => item.cmd_route === 'activity-report') || item.cmd_route == "activity-report" && item.smd_view == 0) {
        this.subactivityreport = true;
        let getremoveindex = [];
        let indexsubmenu = -1;
        this.menus.forEach((itemmenu, index) => {
          if (itemmenu.title == "Time Sheet") {
            indexsubmenu = index;
            this.menus[index].items.forEach((x, i) => {
              if (x.title == "Activity Report") {
                getremoveindex.push(i);
              }
            });
          }
        });
        for (var i = getremoveindex.length - 1; i >= 0; i--)
          this.menus[indexsubmenu].items.splice(getremoveindex[i], 1);
      }

      // cut Time Sheet client-timesheet-report
      if (!this.authorize.some(item => item.cmd_route === 'client-timesheet-report') || item.cmd_route == "client-timesheet-report" && item.smd_view == 0) {
        this.subclienttimesheetreport = true;
        let getremoveindex = [];
        let indexsubmenu = -1;
        this.menus.forEach((itemmenu, index) => {
          if (itemmenu.title == "Time Sheet") {
            indexsubmenu = index;
            this.menus[index].items.forEach((x, i) => {
              if (x.title == "Client Timesheet Report") {
                getremoveindex.push(i);
              }
            });
          }
        });
        for (var i = getremoveindex.length - 1; i >= 0; i--)
          this.menus[indexsubmenu].items.splice(getremoveindex[i], 1);
      }

      // cut Time Sheet employee-timesheet-report
      if (!this.authorize.some(item => item.cmd_route === 'employee-timesheet-report') || item.cmd_route == "employee-timesheet-report" && item.smd_view == 0) {
        this.subemployeetimesheetreport = true;
        let getremoveindex = [];
        let indexsubmenu = -1;
        this.menus.forEach((itemmenu, index) => {
          if (itemmenu.title == "Time Sheet") {
            indexsubmenu = index;
            this.menus[index].items.forEach((x, i) => {
              if (x.title == "Employee Timesheet Report") {
                getremoveindex.push(i);
              }
            });
          }
        });
        for (var i = getremoveindex.length - 1; i >= 0; i--)
          this.menus[indexsubmenu].items.splice(getremoveindex[i], 1);
      }

      // cut Time Sheet attendant-report
      if (!this.authorize.some(item => item.cmd_route === 'attendant-report') || item.cmd_route == "attendant-report" && item.smd_view == 0) {
        this.subattendantreport = true;
        let getremoveindex = [];
        let indexsubmenu = -1;
        this.menus.forEach((itemmenu, index) => {
          if (itemmenu.title == "Time Sheet") {
            indexsubmenu = index;
            this.menus[index].items.forEach((x, i) => {
              if (x.title == "Attendant Report") {
                getremoveindex.push(i);
              }
            });
          }
        });
        for (var i = getremoveindex.length - 1; i >= 0; i--)
          this.menus[indexsubmenu].items.splice(getremoveindex[i], 1);
      }

      // cut Time Sheet summary-timesheets-activity-report
      if (
        !this.authorize.some(item => item.cmd_route === 'summary-timesheets-activity-report') || 
        item.cmd_route == "summary-timesheets-activity-report" &&
        item.smd_view == 0
      ) {
        this.subsummarytimesheetsactivityreport = true;
        let getremoveindex = [];
        let indexsubmenu = -1;
        this.menus.forEach((itemmenu, index) => {
          if (itemmenu.title == "Time Sheet") {
            indexsubmenu = index;
            this.menus[index].items.forEach((x, i) => {
              if (x.title == "Timesheet & Activity Summary Report") {
                getremoveindex.push(i);
              }
            });
          }
        });
        for (var i = getremoveindex.length - 1; i >= 0; i--)
          this.menus[indexsubmenu].items.splice(getremoveindex[i], 1);
      }
      // cut Time Sheet summary-timesheets-activity-report
      if (
        !this.authorize.some(item => item.cmd_route === 'report-detail-time-sheet') || 
        item.cmd_route == "report-detail-time-sheet" &&
        item.smd_view == 0
      ) {
        this.subreportdetailtimesheet = true;
        let getremoveindex = [];
        let indexsubmenu = -1;
        this.menus.forEach((itemmenu, index) => {
          if (itemmenu.title == "Time Sheet") {
            indexsubmenu = index;
            this.menus[index].items.forEach((x, i) => {
              if (x.title == "Report Detail Time Sheet") {
                getremoveindex.push(i);
              }
            });
          }
        });
        for (var i = getremoveindex.length - 1; i >= 0; i--)
          this.menus[indexsubmenu].items.splice(getremoveindex[i], 1);
      }

      // =====================================================================================================================================================
      // cut Leave leave-list
      if (!this.authorize.some(item => item.cmd_route === 'my-time-off') || item.cmd_route == "my-time-off" && item.smd_view == 0) {
        this.subleavelist = true;
        let getremoveindex = [];
        let indexsubmenu = -1;
        this.menus.forEach((itemmenu, index) => {
          if (itemmenu.title == "Leave Request") {
            indexsubmenu = index;
            this.menus[index].items.forEach((x, i) => {
              if (x.title == "My Time Off") {
                getremoveindex.push(i);
              }
            });
          }
        });
        for (var i = getremoveindex.length - 1; i >= 0; i--)
          this.menus[indexsubmenu].items.splice(getremoveindex[i], 1);
      }

      // cut Leave approver-leave
      if (!this.authorize.some(item => item.cmd_route === 'approver-time-off') || item.cmd_route == "approver-time-off" && item.smd_view == 0) {
        this.subapproverleave = true;
        let getremoveindex = [];
        let indexsubmenu = -1;
        this.menus.forEach((itemmenu, index) => {
          if (itemmenu.title == "Leave Request") {
            indexsubmenu = index;
            this.menus[index].items.forEach((x, i) => {
              if (x.title == "Approve Time Off") {
                getremoveindex.push(i);
              }
            });
          }
        });
        for (var i = getremoveindex.length - 1; i >= 0; i--)
          this.menus[indexsubmenu].items.splice(getremoveindex[i], 1);
      }

      // cut Leave leave-report
      if (!this.authorize.some(item => item.cmd_route === 'leave-report') || item.cmd_route == "leave-report" && item.smd_view == 0) {
        this.subleavereport = true;
        let getremoveindex = [];
        let indexsubmenu = -1;
        this.menus.forEach((itemmenu, index) => {
          if (itemmenu.title == "Leave Request") {
            indexsubmenu = index;
            this.menus[index].items.forEach((x, i) => {
              if (x.title == "Leave Report") {
                getremoveindex.push(i);
              }
            });
          }
        });
        for (var i = getremoveindex.length - 1; i >= 0; i--)
          this.menus[indexsubmenu].items.splice(getremoveindex[i], 1);
      }

      // =====================================================================================================================================================
      // cut Sales Order so-list
      if (!this.authorize.some(item => item.cmd_route === 'so-list') || item.cmd_route == "so-list" && item.smd_view == 0) {
        this.subsolist = true;
        let getremoveindex = [];
        let indexsubmenu = -1;
        this.menus.forEach((itemmenu, index) => {
          if (itemmenu.title == "Sales Order") {
            indexsubmenu = index;
            this.menus[index].items.forEach((x, i) => {
              if (x.title == "Sales Order") {
                getremoveindex.push(i);
              }
            });
          }
        });
        for (var i = getremoveindex.length - 1; i >= 0; i--)
          this.menus[indexsubmenu].items.splice(getremoveindex[i], 1);
      }

      // cut Sales Order sales-outstanding
      if (!this.authorize.some(item => item.cmd_route === 'sales-outstanding') || item.cmd_route == "sales-outstanding" && item.smd_view == 0) {
        this.subsalesoutstanding = true;
        let getremoveindex = [];
        let indexsubmenu = -1;
        this.menus.forEach((itemmenu, index) => {
          if (itemmenu.title == "Sales Order") {
            indexsubmenu = index;
            this.menus[index].items.forEach((x, i) => {
              if (x.title == "Sales Outstanding") {
                getremoveindex.push(i);
              }
            });
          }
        });
        for (var i = getremoveindex.length - 1; i >= 0; i--)
          this.menus[indexsubmenu].items.splice(getremoveindex[i], 1);
      }

      // cut Sales Order sales-report
      if (!this.authorize.some(item => item.cmd_route === 'sales-report') || item.cmd_route == "sales-report" && item.smd_view == 0) {
        this.subsalesreport = true;
        let getremoveindex = [];
        let indexsubmenu = -1;
        this.menus.forEach((itemmenu, index) => {
          if (itemmenu.title == "Sales Order") {
            indexsubmenu = index;
            this.menus[index].items.forEach((x, i) => {
              if (x.title == "Sales Report") {
                getremoveindex.push(i);
              }
            });
          }
        });
        for (var i = getremoveindex.length - 1; i >= 0; i--)
          this.menus[indexsubmenu].items.splice(getremoveindex[i], 1);
      }

      // =====================================================================================================================================================
      // cut Advance advance-list
      if (!this.authorize.some(item => item.cmd_route === 'advance-list') || item.cmd_route == "advance-list" && item.smd_view == 0) {
        this.subadvancelist = true;
        let getremoveindex = [];
        let indexsubmenu = -1;
        this.menus.forEach((itemmenu, index) => {
          if (itemmenu.title == "Expenses") {
            indexsubmenu = index;
            this.menus[index].items.forEach((x, i) => {
              if (x.title == "My Advance") {
                getremoveindex.push(i);
              }
            });
          }
        });
        for (var i = getremoveindex.length - 1; i >= 0; i--)
          this.menus[indexsubmenu].items.splice(getremoveindex[i], 1);
      }

      // cut Advance clearadvance-list
      if (!this.authorize.some(item => item.cmd_route === 'clearadvance-list') || item.cmd_route == "clearadvance-list" && item.smd_view == 0) {
        this.subclearadvancelist = true;
        let getremoveindex = [];
        let indexsubmenu = -1;
        this.menus.forEach((itemmenu, index) => {
          if (itemmenu.title == "Expenses") {
            indexsubmenu = index;
            this.menus[index].items.forEach((x, i) => {
              if (x.title == "Clear Advance") {
                getremoveindex.push(i);
              }
            });
          }
        });
        for (var i = getremoveindex.length - 1; i >= 0; i--)
          this.menus[indexsubmenu].items.splice(getremoveindex[i], 1);
      }

      // cut Advance approve-advance-list
      if (!this.authorize.some(item => item.cmd_route === 'approve-advance-list') || item.cmd_route == "approve-advance-list" && item.smd_view == 0) {
        this.subapproveadvancelist = true;
        let getremoveindex = [];
        let indexsubmenu = -1;
        this.menus.forEach((itemmenu, index) => {
          if (itemmenu.title == "Expenses") {
            indexsubmenu = index;
            this.menus[index].items.forEach((x, i) => {
              if (x.title == "Approve Advance") {
                getremoveindex.push(i);
              }
            });
          }
        });
        for (var i = getremoveindex.length - 1; i >= 0; i--)
          this.menus[indexsubmenu].items.splice(getremoveindex[i], 1);
      }

       // cut petty-cash-voucher
      if (!this.authorize.some(item => item.cmd_route === 'petty-cash-voucher') || item.cmd_route == "petty-cash-voucher" && item.smd_view == 0) {
        this.subpettycashvoucher = true;
        let getremoveindex = [];
        let indexsubmenu = -1;
        this.menus.forEach((itemmenu, index) => {
          if (itemmenu.title == "Expenses") {
            indexsubmenu = index;
            this.menus[index].items.forEach((x, i) => {
              if (x.title == "My Petty Cash") {
                getremoveindex.push(i);
              }
            });
          }
        });
        for (var i = getremoveindex.length - 1; i >= 0; i--)
          this.menus[indexsubmenu].items.splice(getremoveindex[i], 1);
      }

      // cut approve-petty-cash
      if (!this.authorize.some(item => item.cmd_route === 'approve-petty-cash') || item.cmd_route == "approve-petty-cash" && item.smd_view == 0) {
        this.subapprovepettycash = true;
        let getremoveindex = [];
        let indexsubmenu = -1;
        this.menus.forEach((itemmenu, index) => {
          if (itemmenu.title == "Expenses") {
            indexsubmenu = index;
            this.menus[index].items.forEach((x, i) => {
              if (x.title == "Approve Petty Cash") {
                getremoveindex.push(i);
              }
            });
          }
        });
        for (var i = getremoveindex.length - 1; i >= 0; i--)
          this.menus[indexsubmenu].items.splice(getremoveindex[i], 1);
      }

      // =====================================================================================================================================================

      // cut Accountant Advance account-pending-advance-list
      if (
        !this.authorize.some(item => item.cmd_route === 'account-pending-advance-list') || 
        item.cmd_route == "account-pending-advance-list" &&
        item.smd_view == 0
      ) {
        this.subaccountpendingadvancelist = true;
        let getremoveindex = [];
        let indexsubmenu = -1;
        this.menus.forEach((itemmenu, index) => {
          if (itemmenu.title == "Accountant Advance") {
            indexsubmenu = index;
            this.menus[index].items.forEach((x, i) => {
              if (x.title == "Pending Advance") {
                getremoveindex.push(i);
              }
            });
          }
        });
        for (var i = getremoveindex.length - 1; i >= 0; i--)
          this.menus[indexsubmenu].items.splice(getremoveindex[i], 1);
      }

      // cut Accountant Advance account-clear-advance-list
      if (
        !this.authorize.some(item => item.cmd_route === 'account-clear-advance-list') || 
        item.cmd_route == "account-clear-advance-list" &&
        item.smd_view == 0
      ) {
        this.subaccountclearadvancelist = true;
        let getremoveindex = [];
        let indexsubmenu = -1;
        this.menus.forEach((itemmenu, index) => {
          if (itemmenu.title == "Accountant Advance") {
            indexsubmenu = index;
            this.menus[index].items.forEach((x, i) => {
              if (x.title == "Clear Advance") {
                getremoveindex.push(i);
              }
            });
          }
        });
        for (var i = getremoveindex.length - 1; i >= 0; i--)
          this.menus[indexsubmenu].items.splice(getremoveindex[i], 1);
      }

      // cut Accountant Advance account-reporting-advance-list
      if (
        !this.authorize.some(item => item.cmd_route === 'account-reporting-advance-list') || 
        item.cmd_route == "account-reporting-advance-list" &&
        item.smd_view == 0
      ) {
        this.subaccountreportingadvancelist = true;
        let getremoveindex = [];
        let indexsubmenu = -1;
        this.menus.forEach((itemmenu, index) => {
          if (itemmenu.title == "Accountant Advance") {
            indexsubmenu = index;
            this.menus[index].items.forEach((x, i) => {
              if (x.title == "Reporting Advance") {
                getremoveindex.push(i);
              }
            });
          }
        });
        for (var i = getremoveindex.length - 1; i >= 0; i--)
          this.menus[indexsubmenu].items.splice(getremoveindex[i], 1);
      }
      if (
        !this.authorize.some(item => item.cmd_route === 'approve-account-petty-cash') || 
        item.cmd_route == "approve-account-petty-cash" &&
        item.smd_view == 0
      ) {
        this.subaccountapproverpettycash = true;
        let getremoveindex = [];
        let indexsubmenu = -1;
        this.menus.forEach((itemmenu, index) => {
          if (itemmenu.title == "Accountant Advance") {
            indexsubmenu = index;
            this.menus[index].items.forEach((x, i) => {
              if (x.title == "Approve Petty Cash") {
                getremoveindex.push(i);
              }
            });
          }
        });
        for (var i = getremoveindex.length - 1; i >= 0; i--)
          this.menus[indexsubmenu].items.splice(getremoveindex[i], 1);
      }

      // =====================================================================================================================================================

      // cut Invoice invoice
      if (!this.authorize.some(item => item.cmd_route === 'invoice') || item.cmd_route == "invoice" && item.smd_view == 0) {
        this.subinvoice = true;
        let getremoveindex = [];
        let indexsubmenu = -1;
        this.menus.forEach((itemmenu, index) => {
          if (itemmenu.title == "Invoice") {
            indexsubmenu = index;
            this.menus[index].items.forEach((x, i) => {
              if (x.title == "Invoice") {
                getremoveindex.push(i);
              }
            });
          }
        });
        for (var i = getremoveindex.length - 1; i >= 0; i--)
          this.menus[indexsubmenu].items.splice(getremoveindex[i], 1);
      }

      // cut Invoice adjust-invoice
      if (!this.authorize.some(item => item.cmd_route === 'adjust-invoice') || item.cmd_route == "adjust-invoice" && item.smd_view == 0) {
        this.subadjustinvoice = true;
        let getremoveindex = [];
        let indexsubmenu = -1;
        this.menus.forEach((itemmenu, index) => {
          if (itemmenu.title == "Invoice") {
            indexsubmenu = index;
            this.menus[index].items.forEach((x, i) => {
              if (x.title == "Adjust Invoice") {
                getremoveindex.push(i);
              }
            });
          }
        });
        for (var i = getremoveindex.length - 1; i >= 0; i--)
          this.menus[indexsubmenu].items.splice(getremoveindex[i], 1);
      }

      // cut Invoice receive
      if (!this.authorize.some(item => item.cmd_route === 'receive') || item.cmd_route == "receive" && item.smd_view == 0) {
        this.subreceive = true;
        let getremoveindex = [];
        let indexsubmenu = -1;
        this.menus.forEach((itemmenu, index) => {
          if (itemmenu.title == "Invoice") {
            indexsubmenu = index;
            this.menus[index].items.forEach((x, i) => {
              if (x.title == "Receipt") {
                getremoveindex.push(i);
              }
            });
          }
        });
        for (var i = getremoveindex.length - 1; i >= 0; i--)
          this.menus[indexsubmenu].items.splice(getremoveindex[i], 1);
      }

      // cut Invoice adjust-receipt
      if (!this.authorize.some(item => item.cmd_route === 'adjust-receipt') || item.cmd_route == "adjust-receipt" && item.smd_view == 0) {
        this.subadjustreceive = true;
        let getremoveindex = [];
        let indexsubmenu = -1;
        this.menus.forEach((itemmenu, index) => {
          if (itemmenu.title == "Invoice") {
            indexsubmenu = index;
            this.menus[index].items.forEach((x, i) => {
              if (x.title == "Adjust Tax Receipt") {
                getremoveindex.push(i);
              }
            });
          }
        });
        for (var i = getremoveindex.length - 1; i >= 0; i--)
          this.menus[indexsubmenu].items.splice(getremoveindex[i], 1);
      }

      // cut Invoice credit-note
      if (!this.authorize.some(item => item.cmd_route === 'credit-note') || item.cmd_route == "credit-note" && item.smd_view == 0) {
        this.subcreditnote = true;
        let getremoveindex = [];
        let indexsubmenu = -1;
        this.menus.forEach((itemmenu, index) => {
          if (itemmenu.title == "Invoice") {
            indexsubmenu = index;
            this.menus[index].items.forEach((x, i) => {
              if (x.title == "Credit Note") {
                getremoveindex.push(i);
              }
            });
          }
        });
        for (var i = getremoveindex.length - 1; i >= 0; i--)
          this.menus[indexsubmenu].items.splice(getremoveindex[i], 1);
      }

      // cut Invoice invoice-report
      if (!this.authorize.some(item => item.cmd_route === 'invoice-report') || item.cmd_route == "invoice-report" && item.smd_view == 0) {
        this.subinvoicereport = true;
        let getremoveindex = [];
        let indexsubmenu = -1;
        this.menus.forEach((itemmenu, index) => {
          if (itemmenu.title == "Invoice") {
            indexsubmenu = index;
            this.menus[index].items.forEach((x, i) => {
              if (x.title == "Invoice Report") {
                getremoveindex.push(i);
              }
            });
          }
        });
        for (var i = getremoveindex.length - 1; i >= 0; i--)
          this.menus[indexsubmenu].items.splice(getremoveindex[i], 1);
      }

      if (!this.authorize.some(item => item.cmd_route === 'receipt-report') || item.cmd_route == "receipt-report" && item.smd_view == 0) {
        this.subreceivereport = true;
        let getremoveindex = [];
        let indexsubmenu = -1;
        this.menus.forEach((itemmenu, index) => {
          if (itemmenu.title == "Invoice") {
            indexsubmenu = index;
            this.menus[index].items.forEach((x, i) => {
              if (x.title == "Receipt Report") {
                getremoveindex.push(i);
              }
            });
          }
        });
        for (var i = getremoveindex.length - 1; i >= 0; i--)
          this.menus[indexsubmenu].items.splice(getremoveindex[i], 1);
      }

      // =====================================================================================================================================================
      // cut Immigration immigration-master
      if (!this.authorize.some(item => item.cmd_route === 'immigration-master') || item.cmd_route == "immigration-master" && item.smd_view == 0) {
        this.subimmigrationmaster = true;
        let getremoveindex = [];
        let indexsubmenu = -1;
        this.menus.forEach((itemmenu, index) => {
          if (itemmenu.title == "Immigration") {
            indexsubmenu = index;
            this.menus[index].items.forEach((x, i) => {
              if (x.title == "Immigration Master") {
                getremoveindex.push(i);
              }
            });
          }
        });
        for (var i = getremoveindex.length - 1; i >= 0; i--)
          this.menus[indexsubmenu].items.splice(getremoveindex[i], 1);
      }

      // cut Immigration immigration-transaction
      if (!this.authorize.some(item => item.cmd_route === 'immigration-transaction') || item.cmd_route == "immigration-transaction" && item.smd_view == 0) {
        this.subimmigrationtransaction = true;
        let getremoveindex = [];
        let indexsubmenu = -1;
        this.menus.forEach((itemmenu, index) => {
          if (itemmenu.title == "Immigration") {
            indexsubmenu = index;
            this.menus[index].items.forEach((x, i) => {
              if (x.title == "Immigration Transaction") {
                getremoveindex.push(i);
              }
            });
          }
        });
        for (var i = getremoveindex.length - 1; i >= 0; i--)
          this.menus[indexsubmenu].items.splice(getremoveindex[i], 1);
      }

      // =====================================================================================================================================================
      // cut D-Document
      if (!this.authorize.some(item => item.cmd_route === 'd-document') || item.cmd_route == "d-document" && item.smd_view == 0) {
        let getremoveindex = [];
        this.menus.forEach((itemmenu, index) => {
          if (itemmenu.title == "D-Document") {
            getremoveindex.push(index);
          }
        });
        for (var i = getremoveindex.length - 1; i >= 0; i--)
          this.menus.splice(getremoveindex[i], 1);
      }

      // =====================================================================================================================================================
      // cut IP Trademark master-trademark-list
      if (!this.authorize.some(item => item.cmd_route === 'master-trademark-list') || item.cmd_route == "master-trademark-list" && item.smd_view == 0) {
        this.submastertrademarklist = true;
        let getremoveindex = [];
        let indexsubmenu = -1;
        this.menus.forEach((itemmenu, index) => {
          if (itemmenu.title == "IP Trademark") {
            indexsubmenu = index;
            this.menus[index].items.forEach((x, i) => {
              if (x.title == "Master Trademark") {
                getremoveindex.push(i);
              }
            });
          }
        });
        for (var i = getremoveindex.length - 1; i >= 0; i--)
          this.menus[indexsubmenu].items.splice(getremoveindex[i], 1);
      }

      // cut IP Trademark appointment-trademark-list
      if (
        !this.authorize.some(item => item.cmd_route === 'appointment-trademark-list') || 
        item.cmd_route == "appointment-trademark-list" &&
        item.smd_view == 0
      ) {
        this.subappointmenttrademarklist = true;
        let getremoveindex = [];
        let indexsubmenu = -1;
        this.menus.forEach((itemmenu, index) => {
          if (itemmenu.title == "IP Trademark") {
            indexsubmenu = index;
            this.menus[index].items.forEach((x, i) => {
              if (x.title == "Appointment Trademark") {
                getremoveindex.push(i);
              }
            });
          }
        });
        for (var i = getremoveindex.length - 1; i >= 0; i--)
          this.menus[indexsubmenu].items.splice(getremoveindex[i], 1);
      }

      // cut IP Trademark import-tm
      if (!this.authorize.some(item => item.cmd_route === 'import-tm') || item.cmd_route == "import-tm" && item.smd_view == 0) {
        this.subimporttm = true;
        let getremoveindex = [];
        let indexsubmenu = -1;
        this.menus.forEach((itemmenu, index) => {
          if (itemmenu.title == "IP Trademark") {
            indexsubmenu = index;
            this.menus[index].items.forEach((x, i) => {
              if (x.title == "Import Trademark") {
                getremoveindex.push(i);
              }
            });
          }
        });
        for (var i = getremoveindex.length - 1; i >= 0; i--)
          this.menus[indexsubmenu].items.splice(getremoveindex[i], 1);
      }

      // =====================================================================================================================================================
      // cut IP Patent master-patent-list
      if (!this.authorize.some(item => item.cmd_route === 'master-patent-list') || item.cmd_route == "master-patent-list" && item.smd_view == 0) {
        this.submasterpatentlist = true;
        let getremoveindex = [];
        let indexsubmenu = -1;
        this.menus.forEach((itemmenu, index) => {
          if (itemmenu.title == "IP Patent") {
            indexsubmenu = index;
            this.menus[index].items.forEach((x, i) => {
              if (x.title == "Master Patent") {
                getremoveindex.push(i);
              }
            });
          }
        });
        for (var i = getremoveindex.length - 1; i >= 0; i--)
          this.menus[indexsubmenu].items.splice(getremoveindex[i], 1);
      }

      // cut IP Patent appointment-patent-list
      if (!this.authorize.some(item => item.cmd_route === 'appointment-patent-list') || item.cmd_route == "appointment-patent-list" && item.smd_view == 0) {
        this.subappointmentpatentlist = true;
        let getremoveindex = [];
        let indexsubmenu = -1;
        this.menus.forEach((itemmenu, index) => {
          if (itemmenu.title == "IP Patent") {
            indexsubmenu = index;
            this.menus[index].items.forEach((x, i) => {
              if (x.title == "Appointment Patent") {
                getremoveindex.push(i);
              }
            });
          }
        });
        for (var i = getremoveindex.length - 1; i >= 0; i--)
          this.menus[indexsubmenu].items.splice(getremoveindex[i], 1);
      }

      // =====================================================================================================================================================
      // cut Logs log-in-out-log-list
      if (!this.authorize.some(item => item.cmd_route === 'log-in-out-log-list') || item.cmd_route == "log-in-out-log-list" && item.smd_view == 0) {
        this.subloginoutloglist = true;
        let getremoveindex = [];
        let indexsubmenu = -1;
        this.menus.forEach((itemmenu, index) => {
          if (itemmenu.title == "Logs") {
            indexsubmenu = index;
            this.menus[index].items.forEach((x, i) => {
              if (x.title == "Login Logs") {
                getremoveindex.push(i);
              }
            });
          }
        });
        for (var i = getremoveindex.length - 1; i >= 0; i--)
          this.menus[indexsubmenu].items.splice(getremoveindex[i], 1);
      }

      // cut Logs transaction-log-list
      if (!this.authorize.some(item => item.cmd_route === 'transaction-log-list') || item.cmd_route == "transaction-log-list" && item.smd_view == 0) {
        this.subtransactionloglist = true;
        let getremoveindex = [];
        let indexsubmenu = -1;
        this.menus.forEach((itemmenu, index) => {
          if (itemmenu.title == "Logs") {
            indexsubmenu = index;
            this.menus[index].items.forEach((x, i) => {
              if (x.title == "Transaction Logs") {
                getremoveindex.push(i);
              }
            });
          }
        });
        for (var i = getremoveindex.length - 1; i >= 0; i--)
          this.menus[indexsubmenu].items.splice(getremoveindex[i], 1);
      }

      // =====================================================================================================================================================

      
      if (indexauthorize == this.authorize.length - 1) {
        // cut main menu administrator
        if (
          this.subcompany &&
          this.subdepartment &&
          this.subposition &&
          this.subjobtitle &&
          this.subleavequota &&
          this.subdocumentsetup &&
          this.subcontactlist &&
          this.subippricelist &&
          this.subinvoicetitle &&
          this.submiscellaneouslist &&
          this.subimmasterplaceofissuelist &&
          this.subimmasterworkplaceofissuelist &&
          this.subleaveholiday &&
          this.subtraininglist &&
          this.subleavetitle &&
          this.subadvancetitle &&
          this.subemployee 
        ) {
          let getremoveindex = [];
          this.menus.forEach((itemmenu, index) => {
            if (itemmenu.title == "Administrator") {
              getremoveindex.push(index);
            }
          });
          for (var i = getremoveindex.length - 1; i >= 0; i--)
            this.menus.splice(getremoveindex[i], 1);
        }
        // =====================================================================================================================================================
        // cut main menu Configuration
        if (
          this.subgroupmenu &&
          this.submenudetail &&
          this.subsettinggroupmenu &&
          this.submappingkeyword &&
          this.subdocrunning
        ) {
          let getremoveindex = [];
          this.menus.forEach((itemmenu, index) => {
            if (itemmenu.title == "Configuration") {
              getremoveindex.push(index);
            }
          });
          for (var i = getremoveindex.length - 1; i >= 0; i--)
            this.menus.splice(getremoveindex[i], 1);
        }
        // =====================================================================================================================================================
        // cut main menu TimeSheet
        if (
          this.submyactivity &&
          this.subnewactivity &&
          this.submytimesheet &&
          this.subapprovetimesheet &&
          this.subactivityreport &&
          this.subclienttimesheetreport &&
          this.subemployeetimesheetreport &&
          this.subattendantreport &&
          this.subsummarytimesheetsactivityreport &&
          this.subreportdetailtimesheet
        ) {
          let getremoveindex = [];
          this.menus.forEach((itemmenu, index) => {
            if (itemmenu.title == "Time Sheet") {
              getremoveindex.push(index);
            }
          });
          for (var i = getremoveindex.length - 1; i >= 0; i--)
            this.menus.splice(getremoveindex[i], 1);
        }
        // =====================================================================================================================================================
        // cut main menu Leave
        if (this.subleavelist && this.subapproverleave && this.subleavereport) {
          let getremoveindex = [];
          this.menus.forEach((itemmenu, index) => {
            if (itemmenu.title == "Leave Request") {
              getremoveindex.push(index);
            }
          });
          for (var i = getremoveindex.length - 1; i >= 0; i--)
            this.menus.splice(getremoveindex[i], 1);
        }

        // =====================================================================================================================================================
        // cut main menu Sales Order
        if (this.subsolist && this.subsalesoutstanding && this.subsalesreport) {
          let getremoveindex = [];
          this.menus.forEach((itemmenu, index) => {
            if (itemmenu.title == "Sales Order") {
              getremoveindex.push(index);
            }
          });
          for (var i = getremoveindex.length - 1; i >= 0; i--)
            this.menus.splice(getremoveindex[i], 1);
        }

        // =====================================================================================================================================================
        // cut main menu Expense
        if (
          this.subadvancelist &&
          this.subclearadvancelist &&
          this.subapproveadvancelist &&
          this.subpettycashvoucher &&
          this.subapprovepettycash
        ) {
          let getremoveindex = [];
          this.menus.forEach((itemmenu, index) => {
            if (itemmenu.title == "Expenses") {
              getremoveindex.push(index);
            }
          });
          for (var i = getremoveindex.length - 1; i >= 0; i--)
            this.menus.splice(getremoveindex[i], 1);
        }

        // =====================================================================================================================================================
        // cut main menu Accountant Advance
        if (
          this.subaccountpendingadvancelist &&
          this.subaccountclearadvancelist &&
          this.subaccountreportingadvancelist &&
          this.subaccountapproverpettycash
        ) {
          let getremoveindex = [];
          this.menus.forEach((itemmenu, index) => {
            if (itemmenu.title == "Accountant Advance") {
              getremoveindex.push(index);
            }
          });
          for (var i = getremoveindex.length - 1; i >= 0; i--)
            this.menus.splice(getremoveindex[i], 1);
        }

        // =====================================================================================================================================================
        // cut main menu Invoice
        if (
          this.subinvoice &&
          this.subadjustinvoice &&
          this.subreceive &&
          this.subadjustreceive &&
          this.subcreditnote &&
          this.subinvoicereport &&
          this.subreceivereport
        ) {
          let getremoveindex = [];
          this.menus.forEach((itemmenu, index) => {
            if (itemmenu.title == "Invoice") {
              getremoveindex.push(index);
            }
          });
          for (var i = getremoveindex.length - 1; i >= 0; i--)
            this.menus.splice(getremoveindex[i], 1);
        }

        // =====================================================================================================================================================
        // cut main menu Immigration
        if (this.subimmigrationmaster && this.subimmigrationtransaction) {
          let getremoveindex = [];
          this.menus.forEach((itemmenu, index) => {
            if (itemmenu.title == "Immigration") {
              getremoveindex.push(index);
            }
          });
          for (var i = getremoveindex.length - 1; i >= 0; i--)
            this.menus.splice(getremoveindex[i], 1);
        }
        // =====================================================================================================================================================
        // cut main menu IP Trademark
        if (
          this.submastertrademarklist &&
          this.subappointmenttrademarklist &&
          this.subimporttm
        ) {
          let getremoveindex = [];
          this.menus.forEach((itemmenu, index) => {
            if (itemmenu.title == "IP Trademark") {
              getremoveindex.push(index);
            }
          });
          for (var i = getremoveindex.length - 1; i >= 0; i--)
            this.menus.splice(getremoveindex[i], 1);
        }

        // =====================================================================================================================================================
        // cut main menu IP Trademark
        if (this.submasterpatentlist && this.subappointmentpatentlist) {
          let getremoveindex = [];
          this.menus.forEach((itemmenu, index) => {
            if (itemmenu.title == "IP Patent") {
              getremoveindex.push(index);
            }
          });
          for (var i = getremoveindex.length - 1; i >= 0; i--)
            this.menus.splice(getremoveindex[i], 1);
        }

        // =====================================================================================================================================================
        // cut main menu Logs
        if (this.subloginoutloglist && this.subtransactionloglist) {
          let getremoveindex = [];
          this.menus.forEach((itemmenu, index) => {
            if (itemmenu.title == "Logs") {
              getremoveindex.push(index);
            }
          });
          for (var i = getremoveindex.length - 1; i >= 0; i--)
            this.menus.splice(getremoveindex[i], 1);
        }

        // =====================================================================================================================================================
        //sort by menu no
        this.menus.forEach((x, i) => {
          if (x.items.length > 0) {
            this.menus[i].items.sort((a, b) => {
              return a.no - b.no;
            });
          }
        });
        // =====================================================================================================================================================
        this.menus.forEach((x, i) => {
          x.index = i;
          if (x.items.length > 0) {
            x.items.forEach((itemitems, index) => {
              itemitems.index = i;
              itemitems.title = `${itemitems.title}`;
            });
          }
        });
        // =====================================================================================================================================================
      }
    });

    // sort 
    this.menus[1].items.sort((a,b) => {
      return a.no - b.no;
    })
    this.menus[10].items.sort((a,b) => {
      return a.no - b.no;
    })

    this.selectedMenu = this.menus.findIndex(
      (menu) => menu[2][1] == this.$route.path
    );

    this.selectedMenu = this.menus.findIndex(
      (menu) =>
        menu.items.findIndex((item) => item.route == this.$route.path) != -1
    );
    // alert(this.selectedMenu);

    if (this.checksubadmin == false) {
      let getremoveindex = [];
      this.menus.forEach((itemmenu, index) => {
        if (itemmenu.title == "Administrator") {
          getremoveindex.push(index);
        }
      });
      for (var i = getremoveindex.length - 1; i >= 0; i--)
        this.menus.splice(getremoveindex[i], 1);
    }
    // if (this.checkemployee == false) {
    //   let getremoveindex = [];
    //   this.menus.forEach((itemmenu, index) => {
    //     if (itemmenu.title == "Employee") {
    //       getremoveindex.push(index);
    //     }
    //   });
    //   for (var i = getremoveindex.length - 1; i >= 0; i--)
    //     this.menus.splice(getremoveindex[i], 1);
    // }
    if (this.checkcustomer == false) {
      let getremoveindex = [];
      this.menus.forEach((itemmenu, index) => {
        if (itemmenu.title == "Customer") {
          getremoveindex.push(index);
        }
      });
      for (var i = getremoveindex.length - 1; i >= 0; i--)
        this.menus.splice(getremoveindex[i], 1);
    }
    if (this.checksubconfig == false) {
      let getremoveindex = [];
      this.menus.forEach((itemmenu, index) => {
        if (itemmenu.title == "Configuration") {
          getremoveindex.push(index);
        }
      });
      for (var i = getremoveindex.length - 1; i >= 0; i--)
        this.menus.splice(getremoveindex[i], 1);
    }
    if (this.checksubtimesheet == false) {
      let getremoveindex = [];
      this.menus.forEach((itemmenu, index) => {
        if (itemmenu.title == "Time Sheet") {
          getremoveindex.push(index);
        }
      });
      for (var i = getremoveindex.length - 1; i >= 0; i--)
        this.menus.splice(getremoveindex[i], 1);
    }
    if (this.checksubleave == false) {
      let getremoveindex = [];
      this.menus.forEach((itemmenu, index) => {
        if (itemmenu.title == "Leave Request") {
          getremoveindex.push(index);
        }
      });
      for (var i = getremoveindex.length - 1; i >= 0; i--)
        this.menus.splice(getremoveindex[i], 1);
    }
    if (this.checksubso == false) {
      let getremoveindex = [];
      this.menus.forEach((itemmenu, index) => {
        if (itemmenu.title == "Sales Order") {
          getremoveindex.push(index);
        }
      });
      for (var i = getremoveindex.length - 1; i >= 0; i--)
        this.menus.splice(getremoveindex[i], 1);
    }
    if (this.checksubadvance == false) {
      let getremoveindex = [];
      this.menus.forEach((itemmenu, index) => {
        if (itemmenu.title == "Expenses") {
          getremoveindex.push(index);
        }
      });
      for (var i = getremoveindex.length - 1; i >= 0; i--)
        this.menus.splice(getremoveindex[i], 1);
    }
    if (this.checksubaccount == false) {
      let getremoveindex = [];
      this.menus.forEach((itemmenu, index) => {
        if (itemmenu.title == "Accountant Advance") {
          getremoveindex.push(index);
        }
      });
      for (var i = getremoveindex.length - 1; i >= 0; i--)
        this.menus.splice(getremoveindex[i], 1);
    }
    if (this.checksubinvoice == false) {
      let getremoveindex = [];
      this.menus.forEach((itemmenu, index) => {
        if (itemmenu.title == "Invoice") {
          getremoveindex.push(index);
        }
      });
      for (var i = getremoveindex.length - 1; i >= 0; i--)
        this.menus.splice(getremoveindex[i], 1);
    }
    if (this.checksubimmigration == false) {
      let getremoveindex = [];
      this.menus.forEach((itemmenu, index) => {
        if (itemmenu.title == "Immigration") {
          getremoveindex.push(index);
        }
      });
      for (var i = getremoveindex.length - 1; i >= 0; i--)
        this.menus.splice(getremoveindex[i], 1);
    }
    if (this.checkddoc == false) {
      let getremoveindex = [];
      this.menus.forEach((itemmenu, index) => {
        if (itemmenu.title == "D-Document") {
          getremoveindex.push(index);
        }
      });
      for (var i = getremoveindex.length - 1; i >= 0; i--)
        this.menus.splice(getremoveindex[i], 1);
    }
    if (this.checksubiptm == false) {
      let getremoveindex = [];
      this.menus.forEach((itemmenu, index) => {
        if (itemmenu.title == "IP Trademark") {
          getremoveindex.push(index);
        }
      });
      for (var i = getremoveindex.length - 1; i >= 0; i--)
        this.menus.splice(getremoveindex[i], 1);
    }
    if (this.checksubippt == false) {
      let getremoveindex = [];
      this.menus.forEach((itemmenu, index) => {
        if (itemmenu.title == "IP Patent") {
          getremoveindex.push(index);
        }
      });
      for (var i = getremoveindex.length - 1; i >= 0; i--)
        this.menus.splice(getremoveindex[i], 1);
    }
    if (this.checksubippt == false) {
      let getremoveindex = [];
      this.menus.forEach((itemmenu, index) => {
        if (itemmenu.title == "Logs") {
          getremoveindex.push(index);
        }
      });
      for (var i = getremoveindex.length - 1; i >= 0; i--)
        this.menus.splice(getremoveindex[i], 1);
    }

  },
  data() {
    return {
      isBeForMenu: "", 
      isDoubleClicked: false, 
      isClicked: false, 
      subloginoutloglist: false,
      subtransactionloglist: false,
      submasterpatentlist: false,
      subappointmentpatentlist: false,
      submastertrademarklist: false,
      subappointmenttrademarklist: false,
      subimporttm: false,
      subimmigrationmaster: false,
      subimmigrationtransaction: false,
      subinvoice: false,
      subadjustinvoice: false,
      subreceive: false,
      subadjustreceive: false,
      subcreditnote: false,
      subinvoicereport: false,
      subreceivereport: false,
      subadvancelist: false,
      subclearadvancelist: false,
      subapproveadvancelist: false,
      subpettycashvoucher: false,
      subapprovepettycash: false,
      subsolist: false,
      subsalesoutstanding: false,
      subsalesreport: false,
      subleavereport: false,
      subapproverleave: false,
      subleavelist: false,
      subsummarytimesheetsactivityreport: false,
      subreportdetailtimesheet: false,
      subattendantreport: false,
      subemployeetimesheetreport: false,
      subclienttimesheetreport: false,
      subactivityreport: false,
      subapprovetimesheet: false,
      submytimesheet: false,
      subnewactivity: false,
      submyactivity: false,
      subgroupmenu: false,
      submenudetail: false,
      subsettinggroupmenu: false,
      submappingkeyword: false,
      subdocrunning: false,
      subtraininglist: false,
      subleavetitle: false,
      subadvancetitle: false,
      subemployee: false,
      subleaveholiday: false,
      subimmasterworkplaceofissuelist: false,
      subimmasterplaceofissuelist: false,
      submiscellaneouslist: false,
      subinvoicetitle: false,
      subippricelist: false,
      subcontactlist: false,
      subdocumentsetup: false,
      subleavequota: false,
      subjobtitle: false,
      subposition: false,
      subdepartment: false,
      subcompany: false,
      checksubadmin: false,
      checkemployee: false,
      checkcustomer: false,
      checksubconfig: false,
      checksubtimesheet: false,
      checksubleave: false,
      checksubso: false,
      checksubadvance: false,
      checksubaccount: false,
      checksubinvoice: false,
      checksubimmigration: false,
      checksubiptm: false,
      checksubippt: false,
      checkddoc: false,
      isActive: false,
      items: [
        { text: "Real-Time", icon: "mdi-clock" },
        { text: "Audience", icon: "mdi-account" },
        { text: "Conversions", icon: "mdi-flag" },
      ],
      authorize: [],
      selectedMenu: 0,
      // menus: [
      //   ["mdi-apps-box", "Stock", "/stock"],
      //   ["mdi-chart-areaspline", "Report", "/report"],
      //   ["mdi mdi-account-multiple", "Employee", "/employee"],
      //   ["mdi mdi-account-multiple", "Customer", "/customer"],
      //   ["mdi mdi-account-convert", "Configuration", "/config"],
      //   ["mdi mdi-sitemap", "Menu setting", "/roleconfig"],
      // ],
      menus: [
        {
          index: 0,
          action: "mdi-view-dashboard",
          title: "Dashboard",
          items: [],
          route: "/dashboard",
        },
        {
          index: 1,
          action: "mdi-application-cog-outline",
          title: "Administrator",
          items: [
            {
              index: 1,
              no: 1,
              title: "Company",
              route: "/company",
            },
            {
              index: 1,
              no: 2,
              title: "Department",
              route: "/department",
            },
            {
              index: 1,
              no: 3,
              title: "Position",
              route: "/position",
            },
            {
              index: 1,
              no: 4,
              title: "Employee",
              route: "/employee",
            },
            {
              index: 1,
              no: 5,
              title: "Job Title",
              route: "/jobtitle",
            },
            {
              index: 1,
              no: 6,
              title: "Leave Quota",
              route: "/leave-quota",
            },
            {
              index: 1,
              no: 7,
              title: "Document Setup",
              route: "/document-setup",
            },
            {
              index: 1,
              no: 8,
              title: "IP Contact",
              route: "/contact-list",
            },
            {
              index: 1,
              no: 9,
              title: "IP Price List",
              route: "/ip-price-list",
            },
            {
              index: 1,
              no: 10,
              title: "Invoice Title",
              route: "/invoice-title",
            },
            {
              index: 1,
              no: 11,
              title: "Invoice Miscellaneous",
              route: "/miscellaneous-list",
            },
            {
              index: 1,
              no: 12,
              title: "Immigration Place of issue",
              route: "/im-master-placeofissue-list",
            },
            {
              index: 1,
              no: 13,
              title: "Immigration Work Place of issue",
              route: "/im-master-workplaceofissue-list",
            },
            {
              index: 1,
              no: 14,
              title: "Public Holiday",
              route: "/leave-holiday",
            },
            {
              index: 1,
              no: 15,
              title: "Training",
              route: "/training-list",
            },
            {
              index: 1,
              no: 16,
              title: "Leave Title",
              route: "/leave-title",
            },
            {
              index: 1,
              no: 17,
              title: "Advance Title",
              route: "/advance-title",
            },
            // {
            //   index: 1,
            //   no: 17,
            //   title: "Employee",
            //   route: "/employee",
            // },
          ],
        },
        {
          index: 2,
          action: "mdi-wrench-cog-outline",
          title: "Configuration",
          items: [
            {
              index: 2,
              no: 1,
              title: "Group Menu",
              route: "/group-menu",
            },
            {
              index: 2,
              no: 2,
              title: "Sub Menu",
              route: "/menu-detail",
            },
            {
              index: 2,
              no: 3,
              title: "Setting Authorization",
              route: "/setting-group-menu",
            },
            {
              index: 2,
              no: 4,
              title: "IP Mapping Keyword",
              route: "/mapping-keyword",
            },
            {
              index: 2,
              no: 5,
              title: "Doc-Running",
              route: "/doc-running",
            },
          ],
        },
        // {
        //   index: 3,
        //   action: "mdi mdi-account-multiple",
        //   title: "Employee",
        //   items: [],
        //   route: "/employee",
        // },
        {
          index: 4,
          action: "mdi mdi-account",
          title: "Customer",
          items: [],
          route: "/customer",
        },
        {
          index: 5,
          action: "mdi-timetable",
          title: "Time Sheet",
          items: [
            {
              index: 5,
              no: 1,
              title: "My Activity",
              route: "/my-activity",
            },
            {
              index: 5,
              no: 2,
              title: "New Activity",
              route: "/new-activity",
            },
            {
              index: 5,
              no: 3,
              title: "My Time Sheet",
              route: "/my-time-sheet",
            },
            {
              index: 5,
              no: 4,
              title: "Approve Time Sheet",
              route: "/approve-time-sheet",
            },
            {
              index: 5,
              no: 5,
              title: "Activity Report",
              route: "/activity-report",
            },
            {
              index: 5,
              no: 6,
              title: "Client Timesheet Report",
              route: "/client-timesheet-report",
            },
            {
              index: 5,
              no: 7,
              title: "Employee Timesheet Report",
              route: "/employee-timesheet-report",
            },
            {
              index: 5,
              no: 8,
              title: "Attendant Report",
              route: "/attendant-report",
            },
            {
              index: 5,
              no: 9,
              title: "Timesheet & Activity Summary Report",
              route: "/summary-timesheets-activity-report",
            },
            {
              index: 5,
              no: 10,
              title: "Report Detail Time Sheet",
              route: "/report-detail-time-sheet",
            },
          ],
        },
        {
          index: 6,
          action: "mdi-clock-outline",
          title: "Leave Request",
          items: [
            {
              index: 6,
              no: 1,
              title: "My Time Off",
              route: "/my-time-off",
            },
            {
              index: 6,
              no: 2,
              title: "Approve Time Off",
              route: "/approver-time-off",
            },
            {
              index: 6,
              no: 3,
              title: "Leave Report",
              route: "/leave-report",
            },
          ],
        },

        {
          index: 7,
          action: "mdi-file-document-outline",
          title: "Sales Order",
          items: [
            {
              index: 7,
              no: 1,
              title: "Sales Order",
              route: "/so-list",
            },
            {
              index: 7,
              no: 2,
              title: "Sales Outstanding",
              route: "/sales-outstanding",
            },
            {
              index: 7,
              no: 3,
              title: "Sales Report",
              route: "/sales-report",
            },
          ],
        },
        {
          index: 8,
          action: "mdi-currency-usd",
          title: "Expenses",

          items: [
            {
              index: 8,
              no: 1,
              title: "My Advance",
              route: "/advance-list",
            },
            {
              index: 8,
              no: 2,
              title: "Clear Advance",
              route: "/clearadvance-list",
            },
            {
              index: 8,
              no: 3,
              title: "Approve Advance",
              route: "/approve-advance-list",
            },
            {
              index: 8,
              no: 4,
              title: "My Petty Cash",
              route: "/petty-cash-voucher",
            },
            {
              index: 8,
              no: 5,
              title: "Approve Petty Cash",
              route: "/approve-petty-cash",
            },
          ],
        },
        {
          index: 9,
          action: "mdi-calculator",
          title: "Accountant Advance",

          items: [
            {
              index: 9,
              no: 1,
              title: "Pending Advance",
              route: "/account-pending-advance-list",
            },
            {
              index: 9,
              no: 2,
              title: "Clear Advance",
              route: "/account-clear-advance-list",
            },
            {
              index: 9,
              no: 3,
              title: "Reporting Advance",
              route: "/account-reporting-advance-list",
            },
            {
              index: 9,
              no: 4,
              title: "Approve Petty Cash",
              route: "/approve-account-petty-cash",
            },
          ],
        },
        {
          index: 10,
          action: "mdi-invoice-list-outline",
          title: "Invoice",
          items: [
            {
              index: 10,
              no: 1,
              title: "Invoice",
              route: "/invoice",
            },
            {
              index: 10,
              no: 2,
              title: "Adjust Invoice",
              route: "/adjust-invoice",
            },
            {
              index: 10,
              no: 3,
              title: "Receipt",
              route: "/receive",
            },
            {
              index: 10,
              no: 4,
              title: "Adjust Tax Receipt",
              route: "/adjust-receipt",
            },
            {
              index: 10,
              no: 5,
              title: "Credit Note",
              route: "/credit-note",
            },
            {
              index: 10,
              no: 6,
              title: "Invoice Report",
              route: "/invoice-report",
            },
            {
              index: 10,
              no: 7,
              title: "Receipt Report",
              route: "/receipt-report",
            },
          ],
        },
        {
          index: 11,
          action: "mdi-plane-car",
          title: "Immigration",

          items: [
            {
              index: 11,
              no: 1,
              title: "Immigration Master",
              route: "/immigration-master",
            },
            {
              index: 11,
              no: 2,
              title: "Immigration Transaction",
              route: "/immigration-transaction",
            },
          ],
        },
        {
          index: 12,
          action: "mdi-file-document-edit-outline",
          title: "D-Document",
          items: [],
          route: "/d-document",
        },
        {
          index: 13,
          action: "mdi-trademark",
          title: "IP Trademark",
          items: [
            {
              index: 13,
              no: 1,
              title: "Master Trademark",
              route: "/master-trademark-list",
            },
            {
              index: 13,
              no: 2,
              title: "Appointment Trademark",
              route: "/appointment-trademark-list",
            },
            {
              index: 13,
              no: 3,
              title: "Import Trademark",
              route: "/import-tm",
            },
          ],
        },
        {
          index: 14,
          action: "mdi-notebook-multiple",
          title: "IP Patent",
          items: [
            {
              index: 14,
              no: 1,
              title: "Master Patent",
              route: "/master-patent-list",
            },
            {
              index: 14,
              no: 2,
              title: "Appointment Patent",
              route: "/appointment-patent-list",
            },
          ],
        },
        {
          index: 15,
          action: "mdi-head-cog-outline",
          title: "Logs",
          items: [
            {
              index: 15,
              no: 1,
              title: "Login Logs",
              route: "/log-in-out-log-list",
            },
            {
              index: 15,
              no: 2,
              title: "Transaction Logs",
              route: "/transaction-log-list",
            },
          ],
        },
      ],
    };
  },
  watch: {
    $route(to, from) {
      // this.selectedMenu = this.menus.items.findIndex(
      //   (menu) => menu[2][1] == to.path
      // );

      this.selectedMenu = this.menus.findIndex(
        (menu) => menu.items.findIndex((item) => item.route == to.path) != -1
      );

      // alert(this.selectedMenu);
    },
  },
};
