//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import api from "@/services/api";
import { server } from "../services/constants";
import StockCard from "@/components/cards/StockCard";
import SuccessDialog from "@/components/cards/SuccessDialog";
import SuccessDialogPush from "@/components/cards/SuccessDialogPush";
import unity from "@/unity/unity";

//maps 
import { Loader } from "@googlemaps/js-api-loader";



export default {
  name: "NewAdvance",
  data() {
    return {

      //maps 
      openMap: false,
      map: null,
      directionsService: null,
      directionsRenderer: null,
      startLocation: null,
      destination: null,
      distance: null,
      startLocationInput: null,
      destinationInput: null,
      startSuggestions: [],
      destinationSuggestions: [],
      autocompleteStart: null,
      autocompleteDestination: null,
      selectedPoint: "start",
      startMarker: null,
      destinationMarker: null,
      currentInfoWindow: null,
      //end maps

      searchCode: "",
      companyIdTravel: localStorage.getItem(server.COMPANYID),
      showbtnadd: true,
      showbtnapprove: false,
      advance_code: [],
      advance_code_list: [],
      advanceCodeListSelected: [],
      advance_code_id: 0,
      dialogAdd: false,
      dialogSendMail: false,
      text_color: "text-h5 green--text text-center",
      title: "green",
      message: "green",
      link: "",
      customer: [],
      customer_list: [],
      customer_id: 0,
      emp_draw: 0,
      emp_draw_jobtitle: 0,
      options: {
        penColor: "#000000",
        backgroundColor: "rgb(255,255,255)",
      },
      initial_data2: "",
      initial_data: "",
      pickbank: false,
      pickcheque: false,
      checkcheque: false,
      checkchequefilled: false,
      showsig1: false,
      showsig2: false,
      showbuttonsavesig1: false,
      showbuttonsavesig2: false,
      defaultwordbuttonsig1: "Signature",
      defaultwordbuttonsig2: "Signature",
      totalleftoveramount: 0.0,
      sumwithdrawamount: 0.0,
      sunamountcleared: 0.0,
      showallamount: "",
      desserts: [
        {
          no: "1",
          detail: "",
          withdrawamount: 0,
        },
      ],
      selectbankname: "",
      bankname: [
        "ธนาคารกรุงเทพ",
        "ธนาคารกสิกรไทย",
        "ธนาคารกรุงไทย",
        "ธนาคารทหารไทยธนชาต",
        "ธนาคารไทยพาณิชย์",
        "ธนาคารกรุงศรีอยุธยา",
        "ธนาคารเกียรตินาคินภัทร",
        "ธนาคารซีไอเอ็มบีไทย",
        "ธนาคารทิสโก้",
        "ธนาคารยูโอบี",
        "ธนาคารไทยเครดิตเพื่อรายย่อย",
        "ธนาคารแลนด์ แอนด์ เฮ้าส์",
        "ธนาคารไอซีบีซี (ไทย)",
        "ธนาคารพัฒนาวิสาหกิจขนาดกลางและขนาดย่อมแห่งประเทศไทย",
        "ธนาคารเพื่อการเกษตรและสหกรณ์การเกษตร",
        "ธนาคารเพื่อการส่งออกและนำเข้าแห่งประเทศไทย",
        "ธนาคารออมสิน",
        "ธนาคารอาคารสงเคราะห์",
        "ธนาคารอิสลามแห่งประเทศไทย",
      ],
      radioType: ["cash", "cheque"],
      radioGroup: "",
      setusername: localStorage.getItem(server.USERNAME),
      role: localStorage.getItem(server.ROLE),
      department: "",
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      dateCurrent: unity.formatDate(
        new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10)
      ),
      menu: false,
      modal: false,
      menu2: false,
      search: "",
      selectedProductId: "",
      confirmDeleteDlg: false,
      mDataArray: [],
      // mDataArray: [{ id: "1", detail: "gg", amout: "1000" }],
      fullPage: true,
      dialog: false,
      dialogDelete: false,
      descriptionTravel: "",
      headers: [
        {
          text: "Code",
          value: "code",
          class: "my-header4-style ",
        },
        {
          text: "Description",
          value: "description",
          class: "my-header-style",
        },
        { text: "Amount", value: "amount", class: "my-header2-style " },
        {
          text: "Action",
          value: "actions",
          class: "my-header3-style  text-center",
        },
      ],
      desserts: [],
      jobtitle_list: [],
      editedIndex: -1,
      editedItem: {
        code: "",
        description: "",
        amount: 0,
        travel_flag: 0
      },
      defaultItem: {
        code: "",
        description: "",
        amount: 0,
      },
      advance_d: {
        advance_hid: "",
        advance_title: "",
        description: "",
        amount: 0,
      },
      advance_h: {
        advance_id: "",
        customer_id: 0,
        employee_id: 0,
        department_id: 0,
        company_id: localStorage.getItem(server.COMPANYID),
        advance_date: null,
        sum_amount: 0,
        clear_date: null,
        payment_type: null,
        bank_name: null,
        bank_number: null,
        cheque_number: null,
        status: null,
        remark: null,
        create_by: null,
        create_date: null,
      },
      lbl_date_request: false,
      dialogSignature: false,
      dialogSaveDraft: false,
      messageSaveDraft: "",
      oldDescription: "",
      paymentType: "",
      bankName: "",
      bankNumber: "",
      chequeNumber: "",
      remark: "",
      emp_list: [],
      sendMail: {
        name: "",
        email: "",
        advance_id: "",
        link: "",
      },
      account: {
        signature: "",
      },
      advance_log: {
        module: "AD",
        module_id: 0,
        approve_id: 0,
        approve_status: 0,
        approve_date: null,
      },
    };
  },
  components: {
    StockCard,
    SuccessDialog,
    SuccessDialogPush,
  },
  computed: {
    version() {
      return this.initial_data;
    },
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
    computedExpectedClearDateFormatted() {
      return unity.formatDate(this.date);
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    openMap(newVal) {
      if (newVal) {
        this.$nextTick(() => {
          this.initMap();
        });
      } else {
        this.resetMapData();
      }
    }
  },

  async mounted() {
    // this.$showLoader();
    this.$hideLoader();

    this.$forceUpdate();

    this.initialize();
    this.loadEmp();
    this.loadJobtitle();
    this.loadCustomer();
    this.loadAdvanceCode();

    const res_acc = await api.getAccountDetail(
      localStorage.getItem(server.USER_ID)
    );

    this.setusername =
      res_acc.data[0].prename_e_n +
      " " +
      res_acc.data[0].firstname_e_n +
      " " +
      res_acc.data[0].lastname_e_n;

    // alert(res_acc.data[0].department_i_d);
    const res_department = await api.getDepartmentByID(
      res_acc.data[0].department_i_d
    );

    this.department = res_department.data[0].name;

    console.log(
      "res_department : ",
      JSON.stringify(res_department.data[0].name)
    );

    this.department_view = res_department.data[0].name;
    // console.log("res_acc : ", JSON.stringify(res_acc.data[0].signature));

    this.user_sign = res_acc.data[0].signature;

    this.showsig1 = true;
    this.dialogSignature = true;
    this.showdefaultsignature();
    this.savesig1();
    // this.$refs.firstsignaturePad.lockSignaturePad();

    // let yourUrlString = window.location;
    // alert(yourUrlString);

    // let parser = document.createElement("a");
    // parser.href = yourUrlString;

    // parser.protocol; // => "http:"
    // parser.hostname; // => "example.com"
    // parser.port; // => "3000"
    // parser.pathname; // => "/pathname/"
    // parser.search; // => "?search=test"
    // parser.hash; // => "#hash"
    // parser.host; // => "example.com:3000"

    // alert(parser.pathname);

    const userId = localStorage.getItem(server.USER_ID);
    if (userId && api.isLoggedIn()) {
      // this.$router.push("/");
    } else {
      this.$store.state.isLogged = false;
      this.$router.push("/login");
    }
    // console.log("Login mounted : " + api.isLoggedIn());
    // console.log("Login mounted : " + userId);
    // await this.loadProducts();

    //maps
    try {
      this.initMap();
    } catch (error) {
      console.error("Error in mounted hook:", error);
    }
    this.$hideLoader();
    //end maps
  },
  methods: {

    //maps

    initMap() {
      const loader = new Loader({
        apiKey: "AIzaSyB5uUwAd4WVFRXzRQLC9XTcgrUPOn-NZTo", // แทนที่ด้วย API Key ของคุณ
        version: "weekly",
        libraries: ["places"],
      });

      loader.load().then(() => {
        this.map = new google.maps.Map(document.getElementById("map"), {
          center: { lat: 13.7563, lng: 100.5018 },
          zoom: 13,
        });

        this.directionsService = new google.maps.DirectionsService();
        this.directionsRenderer = new google.maps.DirectionsRenderer();
        this.directionsRenderer.setMap(this.map);

        this.map.addListener("click", this.handleMapClick);
      });
    },



    // ฟังก์ชันสำหรับรีเซ็ตข้อมูลแผนที่
    resetMapData() {
      // รีเซ็ตค่าต่างๆ ที่เกี่ยวข้องกับสถานที่
      this.startLocation = null;
      this.destination = null;
      this.startLocationInput = null;
      this.destinationInput = null;
      this.distance = null;

      // ลบมาร์กเกอร์เก่า
      if (this.startMarker) {
        this.startMarker.setMap(null);
        this.startMarker = null;
      }
      if (this.destinationMarker) {
        this.destinationMarker.setMap(null);
        this.destinationMarker = null;
      }

      // รีเซ็ต autocomplete suggestions
      this.startSuggestions = [];
      this.destinationSuggestions = [];

      // รีเซ็ตค่าของ autocomplete inputs
      if (this.autocompleteStart) {
        this.autocompleteStart.set('place', null); // เคลียร์ค่า autocomplete start
      }
      if (this.autocompleteDestination) {
        this.autocompleteDestination.set('place', null); // เคลียร์ค่า autocomplete destination
      }

      // รีเซ็ตแผนที่
      if (this.directionsRenderer) {
        this.directionsRenderer.setMap(null);
      }
      if (this.directionsService) {
        this.directionsService = null;
      }

      // หากต้องการให้แผนที่กลับไปที่ตำแหน่งเริ่มต้น
      if (this.map) {
        this.map.setCenter({ lat: 13.7563, lng: 100.5018 });
        this.map.setZoom(13);
      }
    },
  
    initializeAutocomplete(type) {
      const inputElement = type === "start" 
        ? this.$refs.startInput.$el.querySelector("input") 
        : this.$refs.destinationInput.$el.querySelector("input");

      const autocomplete = new google.maps.places.Autocomplete(inputElement);

      autocomplete.addListener("place_changed", () => {
        const place = autocomplete.getPlace();
        if (!place.geometry) return;

        // กำหนดข้อมูลสถานที่และการปักหมุดบนแผนที่
        if (type === "start") {
          this.startLocation = place.geometry.location;
          this.startLocationInput = place.formatted_address; // เก็บค่าที่เลือกจาก autocomplete

          // ลบมาร์กเกอร์เก่าถ้ามี แล้วเพิ่มมาร์กเกอร์ใหม่สำหรับจุดเริ่มต้น
          if (this.startMarker) {
            this.startMarker.setMap(null);
          }
          this.startMarker = new google.maps.Marker({
            position: this.startLocation,
            map: this.map,
            icon: { url: "http://maps.google.com/mapfiles/ms/icons/green-dot.png" }
          });

          // ตั้งค่าแผนที่ให้โฟกัสไปยังตำแหน่งที่ค้นหา
          this.map.panTo(this.startLocation);

        } else if (type === "destination") {
          this.destination = place.geometry.location;
          this.destinationInput = place.formatted_address; // เก็บค่าที่เลือกจาก autocomplete

          // ลบมาร์กเกอร์เก่าถ้ามี แล้วเพิ่มมาร์กเกอร์ใหม่สำหรับจุดปลายทาง
          if (this.destinationMarker) {
            this.destinationMarker.setMap(null);
          }
          this.destinationMarker = new google.maps.Marker({
            position: this.destination,
            map: this.map,
            icon: { url: "http://maps.google.com/mapfiles/ms/icons/red-dot.png" }
          });

          // ตั้งค่าแผนที่ให้โฟกัสไปยังตำแหน่งที่ค้นหา
          this.map.panTo(this.destination);
        }

        // เช็คว่าผู้ใช้เลือกจุดเริ่มต้นและปลายทางครบหรือไม่
        if (this.startLocation && this.destination) {
          
          this.calculateDistance(); // คำนวณระยะทางทันทีเมื่อเลือกครบทั้งสองจุด
        }
      });

      if (type === "start") {
        this.autocompleteStart = autocomplete;
      } else {
        this.autocompleteDestination = autocomplete;
      }
    },

    handleMapClick(e) {
      if (this.selectedPoint === "start") {
        this.startLocation = e.latLng;
        // อัปเดตค่า v-model ที่ใช้กับ v-text-field
        this.startLocationInput = `Latitude: ${e.latLng.lat()}, Longitude: ${e.latLng.lng()}`;

        if (this.startMarker) {
          this.startMarker.setMap(null);
        }
        this.startMarker = new google.maps.Marker({
          position: e.latLng,
          map: this.map,
          icon: { url: "http://maps.google.com/mapfiles/ms/icons/green-dot.png" }
        });

        this.selectedPoint = "destination"; // เปลี่ยนเป็นจุดปลายทาง
      } else {
        this.destination = e.latLng;
        // อัปเดตค่า v-model ที่ใช้กับ v-text-field
        this.destinationInput = `Latitude: ${e.latLng.lat()}, Longitude: ${e.latLng.lng()}`;

        if (this.destinationMarker) {
          this.destinationMarker.setMap(null);
        }
        this.destinationMarker = new google.maps.Marker({
          position: e.latLng,
          map: this.map,
          icon: { url: "http://maps.google.com/mapfiles/ms/icons/red-dot.png" }
        });

        this.selectedPoint = "start"; // เปลี่ยนเป็นจุดเริ่มต้น
      }

      // เช็คว่าผู้ใช้เลือกจุดเริ่มต้นและปลายทางครบหรือไม่
      if (this.startLocation && this.destination) {
        this.calculateDistance(); // คำนวณระยะทางทันที
      }
    },


    calculateDistance() {
      if (!this.startLocation || !this.destination) {
        this.distance = "Please select both start and destination points.";
        return;
      }

      const request = {
        origin: this.startLocation,
        destination: this.destination,
        travelMode: google.maps.TravelMode.DRIVING,
      };

      // กำหนดค่าให้กับ directionsRenderer ให้มีสีแดง
      const routeOptions = {
        polylineOptions: {
          strokeColor: "#FF0000", // สีเส้นทางเป็นสีแดง
          strokeWeight: 5, // ความหนาของเส้น
        },
        suppressMarkers: true
      };

      this.directionsService.route(request, (result, status) => {
  if (status === google.maps.DirectionsStatus.OK) {
    // การแสดงเส้นทางบนแผนที่
    this.directionsRenderer.setOptions(routeOptions);
    this.directionsRenderer.setDirections(result);

    // แสดงระยะทางในแผนที่
    this.distance = result.routes[0].legs[0].distance.text;

    // ปิด InfoWindow ที่เปิดอยู่ก่อนหน้านี้
    if (this.currentInfoWindow) {
      this.currentInfoWindow.close();
    }

    // สร้าง InfoWindow ใหม่
    this.currentInfoWindow = new google.maps.InfoWindow({
      content: `ระยะทาง: ${this.distance}`,
      position: this.destination, // แสดงที่ปลายทาง
    });

    // เปิด InfoWindow ใหม่
    this.currentInfoWindow.open(this.map);

    // เมื่อ InfoWindow โหลดเสร็จแล้ว, ปรับแต่ง CSS ของปุ่มต่างๆ
    google.maps.event.addListener(this.currentInfoWindow, 'domready', () => {
      // ซ่อนปุ่ม close ของ InfoWindow
      const closeButton = document.querySelector('.gm-ui-hover-effect');
      if (closeButton) {
        closeButton.style.display = 'none'; // ซ่อนปุ่ม close
      }
    });

    // แสดงระยะทางใน description (สามารถใช้ได้กับข้อมูลของคุณ)
    this.editedItem.description = this.oldDescription;
    this.editedItem.description = this.editedItem.description + " จุดเริ่มต้น: " + this.startLocation;
    this.editedItem.description = this.editedItem.description + " ปลายทาง: " + this.destination;
    this.editedItem.description = this.editedItem.description + " ระยะทางรวม: " + this.distance;
  } else {
    this.distance = "Error calculating distance.";
  }
});
    },

    //end map

    openPreviewMaps(data){
     // ใช้ Regular Expression เพื่อดึงพิกัดเริ่มต้นและปลายทาง
     const regex = /\(([^)]+)\)/g;
      const matches = [...data.matchAll(regex)];
      
      if (matches.length >= 2) {
        const origin = matches[0][1];  // พิกัดจุดเริ่มต้น (origin)
        const destination = matches[1][1];  // พิกัดจุดปลายทาง (destination)

        const url = `https://www.google.com/maps/dir/?api=1&origin=${encodeURIComponent(origin)}&destination=${encodeURIComponent(destination)}`;
        window.open(url, '_blank');
      } else {
        console.error('ไม่พบพิกัดจุดเริ่มต้นและปลายทางในข้อมูล');
      }
    },

    closeDialogSendmail() {
      this.dialogSendMail = false;
      this.$router.push("/advance-list");
    },
    async loadEmp() {
      const result = await api.getAccountslist_by_ComID(
        localStorage.getItem(server.COMPANYID)
      );

      this.emp_list = result.data;
    },
    async loadJobtitle() {
      const result = await api.getAllJobtitleByCompany(
        localStorage.getItem(server.COMPANYID)
      );

      this.jobtitle_list = result.data;
    },
    async loadAdvanceCode() {
      // console.log("loadAdvanceCode");

      let company_id = localStorage.getItem(server.COMPANYID);

      // console.log("loadAdvanceCode company_id:" + company_id);

      const result = await api.getAllAdvanceCodeByCompany(company_id);

      // console.log("loadAdvanceCode : ", JSON.stringify(result.data));
      this.advance_code = result.data;

      var dataList = [];
      this.advanceCodeListSelected = [];

      result.data.forEach((item) => {
        var dataPush = {
          id: item.id,
          code: item.code,
          description: item.description,
          travel_flag: item.travel_flag,
          descriptionShow: item.code + " " + item.description,
        }

        if(item.status == "A"){
          this.advanceCodeListSelected.push(item.code + " " + item.description)
          dataList.push(dataPush)
        }
      });
      
      this.advance_code_list =  dataList;
    },


    onSelectAdvanceCodeChange(acvance_code) {
      var tmp = acvance_code.split(" ");

      let selectedItem = this.advance_code_list.find(item => item.code === tmp[0]);
      
      if (selectedItem) {
        this.editedItem.code = selectedItem.code;
        this.editedItem.description = selectedItem.description; // ถ้าต้องการแสดงคำอธิบายด้วย
        this.editedItem.travel_flag = selectedItem.travel_flag; // ถ้าต้องการแสดงคำอธิบายด้วย
      }
      this.editedItem.code = this.editedItem.code;
      this.oldDescription = this.editedItem.description;
      console.log(this.editedItem);
      if(this.editedItem.travel_flag){
        this.openMap = true;
      }else{
        this.openMap = false;
      }
    },

    setupAlertDialog(status, title, message, text_color) {
      this.title = title;
      this.message = message;
      this.dialogAdd = status;
      this.text_color = text_color;
    },
    setupAlertDialogPush(status, title, message, text_color, link) {
      this.title = title;
      this.message = message;
      this.dialogAdd = status;
      this.text_color = text_color;
      this.link = link;
    },
    async saveAdvanceDraft() {
      // alert("saveAdvanceDraft");
      let loader = this.$loading.show({
        // Optional parameters
        color: "green",
        loader: "dots",
        container: this.fullPage ? null : this.$refs.formContainer,
        canCancel: false,
        width: 120,
        height: 120,
        backgroundColor: "#ffffff",
        opacity: 0.5,
        zIndex: 999,
        onCancel: this.onCancel,
      });

      this.advance_h.customer_id = this.customer_id;
      this.advance_h.employee_id = localStorage.getItem(server.USER_ID);
      this.advance_h.department_id = localStorage.getItem(server.DEPARTMENT_ID);
      this.advance_h.emp_draw = this.emp_draw;
      this.advance_h.emp_draw_jobtitle = this.emp_draw_jobtitle;
      this.advance_h.advance_date = unity.parseDate(this.dateCurrent);
      this.advance_h.sum_amount = this.sumwithdrawamount;
      this.advance_h.clear_date = this.date;
      this.advance_h.payment_type = this.paymentType;
      this.advance_h.bank_name = this.selectbankname;
      this.advance_h.bank_number = this.bankNumber;
      this.advance_h.cheque_number = this.chequeNumber;
      this.advance_h.status = 1;
      this.advance_h.remark = this.remark;
      this.advance_h.create_by = localStorage.getItem(server.USER_ID);
      this.advance_h.create_date = unity.parseDate(this.dateCurrent);

      console.log("advance_h : " + JSON.stringify(this.advance_h));
      console.log("advance_d : " + JSON.stringify(this.advance_d));

      const res_h = await api.addAdvanceH(this.advance_h);
      if (res_h.status == 201) {
        if (this.desserts.length > 0) {
          this.desserts.forEach((item) => {
            this.advance_log.module_id = res_h.data.id;
            this.advance_d.advance_hid = res_h.data.id;
            this.advance_d.advance_title = item.code;
            this.advance_d.description = item.description;
            this.advance_d.amount = item.amount;
            api.addAdvanceD(this.advance_d);
          });
        }

        // this.advance_log.module_id = this.$route.params.id;
        this.advance_log.approve_id = localStorage.getItem(server.USER_ID);
        this.advance_log.approve_status = 1;
        this.advance_log.approve_date = new Date();

        // this.$store.state.global_dialog = true;
        // this.setupAlertDialog(
        //   true,
        //   "Information Saved",
        //   "Information successfully saved",
        //   "text-h5 green--text text-center"
        // );
        // this.$router.push("/advance-list");

        const res_log = await api.addAdvanceLog(this.advance_log);
        // console.log("res_log : " + JSON.stringify(res_log.status));

        if (res_log.status == 201) {
          this.$store.state.global_push_dialog = true;
          this.setupAlertDialogPush(
            true,
            "Information Saved",
            "Information successfully saved",
            "text-h5 green--text text-center",
            "/advance-list"
          );
        } else {
          this.$store.state.global_dialog = true;
          this.setupAlertDialog(
            true,
            "Information Saved",
            "Please complete all of the required fields.",
            "text-h5 red--text text-center"
          );
        }
      } else if(res_h.status == 203){
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Information Saved",
          "Please kindly check the value of document running.",
          "text-h5 red--text text-center"
        );
      }else {
        // alert("ไม่สามารถบันทึกได้");
        // this.messageSaveDraft = "ไม่สามารถบันทึกได้";
        // this.dialogSaveDraft = true;
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Information Saved",
          "Please complete all of the required fields.",
          "text-h5 red--text text-center"
        );
      }

      loader.hide();
    },

    async saveAdvanceRequest() {
      // alert("saveAdvanceDraft");

      if (this.emp_draw == 0) {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Information Saved",
          "Please complete all of the required fields.",
          "text-h5 red--text text-center"
        );
        this.$refs["emp_draw"].focus();
        return;
      }
      if (this.emp_draw_jobtitle == 0) {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Information Saved",
          "Please complete all of the required fields.",
          "text-h5 red--text text-center"
        );
        this.$refs["Job Title"].focus();
        return;
      }
      if (this.customer_id == 0) {
        // alert("กรุณาเลือกลูกค้า");
        // this.messageSaveDraft = "กรุณาเลือกลูกค้า";
        // this.dialogSaveDraft = true;
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Information Saved",
          "Please complete all of the required fields.",
          "text-h5 red--text text-center"
        );
        this.$refs["For Custome"].focus();
        return;
      }

      if (this.desserts.length <= 0) {
        // alert("กรุณาเลือกลูกค้า");
        // this.messageSaveDraft = "กรุณากรอกรายละเอียด Advance Detail";
        // this.dialogSaveDraft = true;
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Information Saved",
          "Please complete all of the required fields.",
          "text-h5 red--text text-center"
        );
        this.$refs["txtCode"].focus();
        return;
      }else{
        if(this.editedItem.description != ""){
          this.$store.state.global_dialog = true;
          this.setupAlertDialog(
            true,
            "Information Saved",
            "Please complete all of the required fields.",
            "text-h5 red--text text-center"
          );
          this.$refs["txtAmount"].focus();
          return;
        }
      }

      if (this.paymentType == "") {
        // alert("กรุณาเลือกลูกค้า");
        // this.messageSaveDraft = "กรุณาเลือกวิธีการชำระเงิน";
        // this.dialogSaveDraft = true;
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Information Saved",
          "Please complete all of the required fields.",
          "text-h5 red--text text-center"
        );
        return;
      }

      if (this.paymentType == "transfer") {
        // alert("กรุณาเลือกลูกค้า");
        if (this.selectbankname == "" || this.bankNumber == null) {
          // this.messageSaveDraft = "กรุณากรอกข้อมูลธนาคารให้ถูกต้อง";
          // this.dialogSaveDraft = true;
          this.$store.state.global_dialog = true;
          this.setupAlertDialog(
            true,
            "Information Saved",
            "Please complete all of the required fields.",
            "text-h5 red--text text-center"
          );
          this.$refs["Select Bank"].focus();
          return;
        }
      } else if (this.paymentType == "cheque") {
        // alert("กรุณาเลือกลูกค้า");
        if (this.chequeNumber == "") {
          // this.messageSaveDraft = "กรุณากรอกเลขที่เช็คให้ถูกต้อง";
          // this.dialogSaveDraft = true;
          this.$store.state.global_dialog = true;
          this.setupAlertDialog(
            true,
            "Information Saved",
            "Please complete all of the required fields.",
            "text-h5 red--text text-center"
          );
          this.$refs["Cheque No"].focus();
          return;
        }
      }

      if (this.user_sign == "" || this.user_sign == null) {
        // alert("กรุณาเลือกลูกค้า");
        // this.messageSaveDraft = "กรุณาเลือกลงลายมือชื่อ";
        // this.dialogSaveDraft = true;
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Information Saved",
          "Please complete all of the required fields.",
          "text-h5 red--text text-center"
        );
        return;
      }

      let loader = this.$loading.show({
        // Optional parameters
        color: "green",
        loader: "dots",
        container: this.fullPage ? null : this.$refs.formContainer,
        canCancel: false,
        width: 120,
        height: 120,
        backgroundColor: "#ffffff",
        opacity: 0.5,
        zIndex: 999,
        onCancel: this.onCancel,
      });

      this.advance_h.customer_id = this.customer_id;
      this.advance_h.employee_id = localStorage.getItem(server.USER_ID);
      this.advance_h.department_id = localStorage.getItem(server.DEPARTMENT_ID);
      this.advance_h.advance_date = unity.parseDate(this.dateCurrent);
      this.advance_h.sum_amount = this.sumwithdrawamount;
      this.advance_h.clear_date = this.date;
      this.advance_h.payment_type = this.paymentType;
      this.advance_h.bank_name = this.selectbankname;
      this.advance_h.bank_number = this.bankNumber;
      this.advance_h.cheque_number = this.chequeNumber;
      this.advance_h.status = 2;
      this.advance_h.remark = this.remark;
      this.advance_h.create_by = localStorage.getItem(server.USER_ID);
      this.advance_h.create_date = unity.parseDate(this.dateCurrent);
      this.advance_h.emp_draw = this.emp_draw;
      this.advance_h.emp_draw_jobtitle = this.emp_draw_jobtitle;

      console.log("advance_h : " + JSON.stringify(this.advance_h));
      console.log("advance_d : " + JSON.stringify(this.advance_d));

      const res_h = await api.addAdvanceH(this.advance_h);
      if (res_h.status == 201) {
        if (this.desserts.length > 0) {
          this.desserts.forEach((item) => {
            this.advance_log.module_id = res_h.data.id;
            this.advance_d.advance_hid = res_h.data.id;
            this.advance_d.advance_title = item.code;
            this.advance_d.description = item.description;
            this.advance_d.amount = item.amount;
            api.addAdvanceD(this.advance_d);
          });

          // this.advance_log.module_id = this.$route.params.id;
          this.advance_log.approve_id = localStorage.getItem(server.USER_ID);
          this.advance_log.approve_status = 2;
          this.advance_log.approve_date = new Date();

          const res_log = await api.addAdvanceLog(this.advance_log);
          // console.log("res_log : " + JSON.stringify(res_log.status));

          if (res_log.status == 201) {
            // this.$store.state.global_dialog = true;
            // this.setupAlertDialogPush(
            //   true,
            //   "Information Saved",
            //   "Information successfully saved",
            //   "text-h5 green--text text-center",
            //   "/advance-list"
            // );

            this.sendMail.name = localStorage.getItem(server.FULLNAME);
            this.sendMail.email = localStorage.getItem(
              server.APPROVE_LEVEL1_EMAIL
            );
            this.sendMail.advance_id = res_h.data.id;
            console.log("send_request : " + JSON.stringify(this.sendMail));
            const send_request = await api.sendMail(this.sendMail);

            console.log("send_request : " + JSON.stringify(send_request));
            if (send_request.status == 200) {
              this.$store.state.global_push_dialog = true;
              // this.setupAlertDialog(
              //   true,
              //   "Information Saved",
              //   "บันทึกข้อมูลและส่งอีเมล์ถึงผู้อนุมัติสำเร็จ",
              //   "text-h5 green--text text-center"
              // );
              // this.dialogSendMail = true;

              this.setupAlertDialogPush(
                true,
                "Information Saved",
                "Request successfully saved and sent to approver.",
                "text-h5 green--text text-center",
                "/advance-list"
              );
            } else {
              // alert("ไม่สามารถบันทึกได้");
              // this.messageSaveDraft = "ไม่สามารถบันทึกได้";
              // this.dialogSaveDraft = true;
              this.$store.state.global_dialog = true;
              this.setupAlertDialog(
                true,
                "Information Saved",
                "Please complete all of the required fields.",
                "text-h5 red--text text-center"
              );
            }
          } else {
            this.$store.state.global_dialog = true;
            this.setupAlertDialog(
              true,
              "Information Saved",
              "Please complete all of the required fields.",
              "text-h5 red--text text-center"
            );
          }
        }
      } else if(res_h.status == 203){
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Information Saved",
          "Please kindly check the value of document running.",
          "text-h5 red--text text-center"
        );
      }else{
        // alert("ไม่สามารถบันทึกได้");
        // this.messageSaveDraft = "ไม่สามารถบันทึกได้";
        // this.dialogSaveDraft = true;
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Information Saved",
          "Please complete all of the required fields.",
          "text-h5 red--text text-center"
        );
      }

      loader.hide();
    },

    changeFocusText(t) {
      // alert(t);
      if (t === 1) {
        this.$refs["txtCode"].focus();
      } else if (t === 2) {
        this.$refs["txtDescription"].focus();
      } else if (t === 3) {
        if (this.editedItem.code == "") {
          this.$store.state.global_dialog = true;
          this.setupAlertDialog(
            true,
            "Information Saved",
            "Please complete all of the required fields.",
            "text-h5 red--text text-center"
          );
          this.$refs["txtCode"].focus();
          return;
        } else {
          this.$refs["txtAmount"].focus();
        }
      }
    },
    initialize() {
      // this.desserts = [
      //   {
      //     code: "1",
      //     description: "test1",
      //     amount: 121,
      //   },
      //   {
      //     code: "2",
      //     description: "test2",
      //     amount: 35,
      //   },
      //   {
      //     code: "3",
      //     description: "test3",
      //     amount: 75,
      //   },
      // ];

      this.sumwithdrawamount = 0;

      this.desserts.forEach((item) => {
        this.sumwithdrawamount += item.amount;
      });
    },

    editItem(item) {
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.desserts.splice(this.editedIndex, 1);
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeSaveDraft() {
      this.dialogSaveDraft = false;
    },

    closeDelete() {
      this.sumwithdrawamount = 0;

      this.desserts.forEach((item) => {
        this.sumwithdrawamount += parseInt(item.amount, 10);
      });

      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.editedItem.code == "") {
        this.$store.state.global_dialog = true;
          this.setupAlertDialog(
              true,
              "Information Saved",
              "Please complete all of the required fields.",
              "text-h5 red--text text-center"
          );
        this.$refs["txtCode"].focus();
        return;
      }

      if (this.editedItem.amount == "0" || this.editedItem.amount == 0) {
        this.$store.state.global_dialog = true;
          this.setupAlertDialog(
              true,
              "Information Saved",
              "Please complete all of the required fields.",
              "text-h5 red--text text-center"
          );
        this.$refs["txtAmount"].focus();
        return;
      }
      if(this.editedItem.travel_flag){
        if(!this.startLocation){
          this.$store.state.global_dialog = true;
          this.setupAlertDialog(
              true,
              "Information Saved",
              "Please complete all of the required fields.",
              "text-h5 red--text text-center"
          );
          this.$refs["startInput"].focus();
          return;
        }
        if(!this.destination){
          this.$store.state.global_dialog = true;
          this.setupAlertDialog(
              true,
              "Information Saved",
              "Please complete all of the required fields.",
              "text-h5 red--text text-center"
          );
          this.$refs["destinationInput"].focus();
          return;
        }
        if(!this.distance){
          this.$store.state.global_dialog = true;
          this.setupAlertDialog(
              true,
              "Information Saved",
              "ไม่สามารถบันทึกได้",
              "text-h5 red--text text-center"
          );
          return;
        }
      }
      if (this.editedIndex > -1) {
        Object.assign(this.desserts[this.editedIndex], this.editedItem);
      } else {
        this.desserts.push(this.editedItem);
      }

      this.sumwithdrawamount = 0;

      this.desserts.forEach((item) => {
        this.sumwithdrawamount += parseInt(item.amount, 10);
      });
      console.log("save : " + JSON.stringify(this.desserts));


      this.changeFocusText(1);
      this.close();

      this.openMap = false;
    },
    onSelectCustomerChange(search_customer) {
      var tmp = null;
      tmp = this.customer.filter((c) => c.name.includes(search_customer));

      this.customer_id = tmp[0].id;
      // console.log("customer : " + this.customer_id);
    },

    async loadCustomer() {
      // console.log("loadCustomer");
      const result = await api.getAllCustomerListactiveCompany({
        params: {
          company_id: localStorage.getItem(server.COMPANYID),
        },
      });
      // console.log("result.data : ", JSON.stringify(result.data));
      this.customer = result.data;

      result.data.forEach((item) => {
        this.customer_list.push(item.name);
      });
    },
    submit() {
      let loader = this.$loading.show({
        // Optional parameters
        color: "green",
        loader: "dots",
        container: this.fullPage ? null : this.$refs.formContainer,
        canCancel: false,
        width: 120,
        height: 120,
        backgroundColor: "#F1F4F7",
        opacity: 0.5,
        zIndex: 999,
        onCancel: this.onCancel,
      });
      // simulate AJAX
      setTimeout(() => {
        loader.hide();
      }, 2000);
    },
    editsig1() {
      this.dialogSignature = true;
      this.lbl_date_request = false;
      this.showsig1 = true;
      this.showbuttonsavesig1 = true;
      this.$refs.firstsignaturePad.openSignaturePad();
    },
    editsig2() {
      this.showsig2 = true;
      this.showbuttonsavesig2 = true;
      this.$refs.secondsignaturePad.openSignaturePad();
    },
    clearsig1() {
      this.lbl_date_request = false;
      this.$refs.firstsignaturePad.clearSignature();
    },
    clearsig2() {
      this.$refs.secondsignaturePad.clearSignature();
    },
    async savesig1() {
      this.lbl_date_request = true;
      this.defaultwordbuttonsig1 = "Edit";
      const { isEmpty, data } = this.$refs.firstsignaturePad.saveSignature();
      this.showbuttonsavesig1 = false;
      this.$refs.firstsignaturePad.lockSignaturePad();
      // console.log("user_sign : " + data);
      // console.log("user_sign1 : " + this.user_sign);
      this.user_sign = data;
      this.account.signature = data;
      let loader = this.$loading.show({
        // Optional parameters
        color: "green",
        loader: "dots",
        container: this.fullPage ? null : this.$refs.formContainer,
        canCancel: false,
        width: 120,
        height: 120,
        backgroundColor: "#ffffff",
        opacity: 0.5,
        zIndex: 999,
        onCancel: this.onCancel,
      });
      await api.updateAccount(
        localStorage.getItem(server.USER_ID),
        this.account
      );
      loader.hide();
    },
    savesig2() {
      this.defaultwordbuttonsig2 = "Edit";
      const { isEmpty, data } = this.$refs.secondsignaturePad.saveSignature();
      this.showbuttonsavesig2 = false;
      this.$refs.secondsignaturePad.lockSignaturePad();
    },
    changeradio() {
      console.log(this.radioGroup);
      if (this.radioGroup === "cheque") {
        this.paymentType = "cheque";
        this.pickbank = false;
        this.pickcheque = true;
      } else if (this.radioGroup === "bank") {
        this.paymentType = "transfer";
        this.pickcheque = false;
        this.pickbank = true;
      } else {
        this.paymentType = "cash";
        this.pickcheque = false;
        this.pickbank = false;
      }
    },

    showdefaultsignature() {
      this.$nextTick(() => {
        // this.$refs.signaturePaddefault.fromDataURL(this.initial_data);
        // this.$refs.signaturePaddefault.lockSignaturePad();
        this.$refs.firstsignaturePad.fromDataURL(this.user_sign);
        // this.$refs.firstsignaturePad.lockSignaturePad();
        this.$refs.secondsignaturePad.fromDataURL(this.initial_data2);
        // this.$refs.secondsignaturePad.lockSignaturePad();
      });
    },
  },
  async beforeCreate() {
    console.log("beforeCreate");
    this.$store.state.navMenu = true;
    // this.showdefaultsignature();
  },
  async created() {
    // this.showdefaultsignature();
  },
};
