//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import StockCard from "@/components/cards/StockCard";
import SuccessDialog from "@/components/cards/SuccessDialog";
import SuccessDialogPush from "@/components/cards/SuccessDialogPush";
import SuccessDialogPushTimeSheet from "@/components/cards/SuccessDialogPushTimeSheet";
import api from "@/services/api";
import { server } from "@/services/constants";
import { language } from "./language/Activity.js";
import unity from "@/unity/unity";

export default {
  name: "SO-list",
  data(vm) {
    return {
      checkbox: false,
      menu: false,
      menu2: false,
      menu4: false,
      timesheetname: "",
      period_from: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      period_to: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      menu3: false,
      search: "",
      toggleEnable: false,
      selectstatus: "",
      dataAll: [],
      status: ["Draft", "Submit"],
      datefrom: null,
      dateto: null,
      // datefrom: vm.formatDate(new Date().toISOString().substr(0, 10)),
      // dateto: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      //   .toISOString()
      //   .substr(0, 10),
      dateCurrent: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      seticonsearchmore: { icon: "mdi mdi-chevron-down", action: false },
      setlanguage: language.en,
      defaultlanguage: "en",
      // regexlang: "th",
      chooselanguage: [
        { name: "en", flag: "gb" },
        { name: "th", flag: "th" },
      ],
      aa: "สวัสดี",
      dialogEdit: false,
      dialogDelete: false,
      dialogAdd: false,
      dialogSendMail: false,
      text_color: "text-h5 green--text text-center",
      title: "green",
      message: "green",
      link: "",
      timesheet_id: "",
      timesheet_no: "",
      customer: [],
      mDataArray: [],
      position: [],
      approver: [],
      department: [],
      department_list: [],
      position_list: [],
      Emp_list: [],
      company: [],
      authorize: [],
      dialogApprover: false,
      // mDataArray: [
      //   {
      //     no: 1,
      //     date: "1/11/2022",
      //     name: "testname",
      //     customer: "ABS co Ltd",
      //     amount: "10,000",
      //     status: "Unapproved",
      //   },
      //   {
      //     no: 2,
      //     date: "2/11/2022",
      //     name: "testname2",
      //     customer: "ABS co Ltd",
      //     amount: "3,000",
      //     status: "Approved",
      //   },
      // ],
      headers: [
        {
          text: "Name",
          value: "name",
          class: "bg-colorth tabletextwhite",
          divider: true,
          width: "10%",
        },
        {
          text: " Job Title/Case ",
          value: "job_name",
          class: "bg-colorth tabletextwhite",
          divider: true,
          width: "12%",
        },
        {
          text: "Customer",
          value: "customer_name",
          class: "bg-colorth tabletextwhite",
          divider: true,
          width: "13%",
        },
        {
          text: "Check-In",
          value: "checkInDate_show",
          class: "bg-colorth tabletextwhite",
          divider: true,
          width: "105px",
        },

        {
          text: "Check-Out",
          value: "checkOutDate_show",
          class: "bg-colorth tabletextwhite",
          divider: true,
          width: "105px",
        },
        {
          text: "Hour",
          value: "duration",
          class: "bg-colorth tabletextwhite",
          divider: true,
          width: "7%",
        },
        {
          text: "Charge Type",
          value: "chargeType",
          class: "bg-colorth tabletextwhite",
          divider: true,
          width: "10%",
        },
        {
          text: "Prospect",
          value: "prospect",
          class: "bg-colorth tabletextwhite",
          divider: true,
          width: "9%",
        },
        {
          text: "Detail",
          value: "detail",
          class: "bg-colorth tabletextwhite",
          divider: true,
          width: "14%",
        },
      ],
      selected: [],
      checkcusid: 0,
      additem: {
        customer_id: 0,
        employee_id: localStorage.getItem(server.USER_ID),
        company_id: localStorage.getItem(server.COMPANYID),
        period_from: "",
        period_to: "",
        timesheet_name: "",
        status: 1,
        create_by: localStorage.getItem(server.USER_ID),
      },
      addA_D: {},
      disableinstatus: true,
      charge_list: [
        { value: "T/C", name: "Time Charge" },
        { value: "N/C", name: "No Charge" },
        { value: "F/C", name: "Fix Charge" },
        { value: "OFF", name: "ทำงานออฟฟิต" },
      ],
      jobTitle_list: [],
      searchCharge: null,
      searchChargeJob: null,
      searchEmp: [],
      searchStu_date: 0,
    };
  },
  components: {
    StockCard,
    SuccessDialog,
    SuccessDialogPush,
    SuccessDialogPushTimeSheet,
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    dialogApprover(val) {
      val || this.closeReplace();
    },
  },
  async beforeCreate() {
    // console.log("beforeCreate");
    this.$store.state.navMenu = true;
  },
  async created() {
    // this.headers = language.en.headertable;
    // this.initialize();
  },
  beforeUpdate() {},
  computed: {
    computedPeriodFromFormatted() {
      return unity.formatDate(this.period_from);
    },
    computedPeriodToFormatted() {
      return unity.formatDate(this.period_to);
    },
    computeddatefromFormatted() {
      return unity.formatDate(this.datefrom);
    },
    computeddateToFormatted() {
      return unity.formatDate(this.dateto);
    },

    itemsForSelected() {
      if (this.search.length) {
        return this.dataSource.filter((item) => this.search.includes(item));
      }
      return this.dataSource;
    },
  },
  async mounted() {
    await api.checkVersion();
    this.userId = localStorage.getItem(server.USER_ID);
    let yourUrlString = window.location;
    // alert(yourUrlString);

    let parser = document.createElement("a");
    parser.href = yourUrlString;

    this.authorize_id = localStorage.getItem(server.AUTHORIZE_ID);

    // alert("authorize_id:" + this.authorize_id);
    if (this.authorize_id == null || this.authorize_id == 0) {
      // this.$router.push("/login");
      this.$store.state.global_dialog = true;
      this.setupAlertDialog(
        true,
        "Authorize Failed",
        "Please Logout And Login Again",
        "text-h5 red--text text-center"
      );
      this.$router.back();
    }

    const router_path = parser.pathname.replace("/", "");

    const res_auth = await api.getAuthorize(this.userId, router_path);

    // console.log("res_auth:" + JSON.stringify(res_auth.data));

    this.authorize_view = res_auth.data[0].smd_view >= 1 ? true : false;
    this.authorize_add = res_auth.data[0].smd_add >= 1 ? true : false;
    this.authorize_edit = res_auth.data[0].smd_edit >= 1 ? true : false;
    this.authorize_del = res_auth.data[0].smd_del >= 1 ? true : false;

    // console.log("res_auth:" + JSON.stringify(res_auth.data));
    // console.log("authorize_view:" + this.authorize_view);
    // console.log("authorize_add:" + this.authorize_add);
    // console.log("authorize_edit:" + this.authorize_edit);
    // console.log("authorize_del:" + this.authorize_del);
    // this.$router.back();

       if (!this.authorize_view) {
      this.$router.push("/not-found");
    }

    // ----------------- Check Authorize ---------------------------

    let comp_id = localStorage.getItem(server.COMPANYID);

    this.userId = localStorage.getItem(server.USER_ID);
    if (this.userId && api.isLoggedIn()) {
      // this.$router.push("/");
    } else {
      this.$store.state.isLogged = false;
      this.$router.push("/login");
    }
    await this.loaddataSO();
    await this.loadEmp();
  },
  methods: {
    editItem() {},
    async loadEmp() {
      const result = await api.getAccountslist_by_ComID(
        localStorage.getItem(server.COMPANYID)
      );

      this.Emp_list = result.data;
    },

    showsearchmore() {
      this.toggleEnable = !this.toggleEnable;
      this.seticonsearchmore.action = !this.seticonsearchmore.action;
      if (this.seticonsearchmore.action === true) {
        this.seticonsearchmore.icon = "mdi mdi-chevron-up";
      } else {
        this.seticonsearchmore.icon = "mdi mdi-chevron-down";
      }
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${year}-${month - 1}-${day}`;
    },
    finddatafromdate() {
      this.searchStu_date = 1;
      this.mDataArray = this.dataAll.filter((item) => {
        if (this.searchCharge == null) {
          if (this.searchChargeJob == null) {
            if (this.datefrom != null && this.dateto != null) {
              if (
                item.checkInDate_S >= this.datefrom &&
                item.checkOutDate_S <= this.dateto
              ) {
                return item;
              }
            }
            if (this.datefrom == null && this.dateto == null) {
              return item;
            }
          } else {
            if (this.datefrom != null && this.dateto != null) {
              if (
                item.checkInDate_S >= this.datefrom &&
                item.checkOutDate_S <= this.dateto &&
                item.job_name == this.searchChargeJob
              ) {
                return item;
              }
            }
            if (this.datefrom == null && this.dateto == null) {
              if (item.job_name == this.searchChargeJob) {
                return item;
              }
            }
          }
        } else {
          if (this.searchChargeJob == null) {
            if (this.datefrom != null && this.dateto != null) {
              if (
                item.checkInDate_S >= this.datefrom &&
                item.checkOutDate_S <= this.dateto &&
                item.chargeType == this.searchCharge
              ) {
                return item;
              }
            }
            if (this.datefrom == null && this.dateto == null) {
              if (item.chargeType == this.searchCharge) {
                return item;
              }
            }
          } else {
            if (this.datefrom != null && this.dateto != null) {
              if (
                item.checkInDate_S >= this.datefrom &&
                item.checkOutDate_S <= this.dateto &&
                item.chargeType == this.searchCharge &&
                item.job_name == this.searchChargeJob
              ) {
                return item;
              }
            }
            if (this.datefrom == null && this.dateto == null) {
              if (
                item.chargeType == this.searchCharge &&
                item.job_name == this.searchChargeJob
              ) {
                return item;
              }
            }
          }
        }
      });
    },
    findChaege() {
      if(this.searchEmp.length > 0){
        this.mDataArray = this.dataAll.filter((item) => {
          if (this.datefrom == null || this.dateto == null) {
            if (this.searchCharge != null) {
              if (this.searchChargeJob != null) {
                if (
                  item.chargeType == this.searchCharge &&
                  item.job_name == this.searchChargeJob
                ) {
                  for(let i=0;i<this.searchEmp.length;i++){
                    if(this.searchEmp[i] == Number(item.userId)){
                      return item;
                    }
                  }   
                }
              } else {
                if (item.chargeType == this.searchCharge) {
                  for(let i=0;i<this.searchEmp.length;i++){
                    if(this.searchEmp[i] == Number(item.userId)){
                      return item;
                    }
                  }
                }
              }
            } else {
              if (this.searchChargeJob != null) {
                if (item.job_name == this.searchChargeJob) {
                  for(let i=0;i<this.searchEmp.length;i++){
                    if(this.searchEmp[i] == Number(item.userId)){
                      return item;
                    }
                  }
                }
              } else {
                for(let i=0;i<this.searchEmp.length;i++){
                  if(this.searchEmp[i] == Number(item.userId)){
                    return item;
                  }
                }
              }
            }
          }
          if (this.datefrom != null && this.dateto != null) {
            if (this.searchCharge != null) {
              if (this.searchChargeJob != null) {
                if (
                  item.chargeType == this.searchCharge &&
                  item.checkInDate_S >= this.datefrom &&
                  item.checkOutDate_S <= this.dateto &&
                  item.job_name == this.searchChargeJob
                ) {
                  for(let i=0;i<this.searchEmp.length;i++){
                    if(this.searchEmp[i] == Number(item.userId)){
                      return item;
                    }
                  }
                }
              } else {
                if (
                  item.chargeType == this.searchCharge &&
                  item.checkInDate_S >= this.datefrom &&
                  item.checkOutDate_S <= this.dateto
                ) {
                  for(let i=0;i<this.searchEmp.length;i++){
                    if(this.searchEmp[i] == Number(item.userId)){
                      return item;
                    }
                  }
                }
              }
            } else {
              if (this.searchChargeJob != null) {
                if (
                  item.checkInDate_S >= this.datefrom &&
                  item.checkOutDate_S <= this.dateto &&
                  item.job_name == this.searchChargeJob
                ) {
                  for(let i=0;i<this.searchEmp.length;i++){
                    if(this.searchEmp[i] == Number(item.userId)){
                      return item;
                    }
                  }
                }
              } else {
                if (
                  item.checkInDate_S >= this.datefrom &&
                  item.checkOutDate_S <= this.dateto
                ) {
                  for(let i=0;i<this.searchEmp.length;i++){
                    if(this.searchEmp[i] == Number(item.userId)){
                      return item;
                    }
                  }
                }
              }
            }
          }
        });

      }else{
        this.mDataArray = this.dataAll.filter((item) => {
          if (this.datefrom == null || this.dateto == null) {
            if (this.searchCharge != null) {
              if (this.searchChargeJob != null) {
                if (
                  item.chargeType == this.searchCharge &&
                  item.job_name == this.searchChargeJob
                ) {
                  return item;
                }
              } else {
                if (item.chargeType == this.searchCharge) {
                  return item;
                }
              }
            } else {
              if (this.searchChargeJob != null) {
                if (item.job_name == this.searchChargeJob) {
                  return item;
                }
              } else {
                return item;
              }
            }
          }
          if (this.datefrom != null && this.dateto != null) {
            if (this.searchCharge != null) {
              if (this.searchChargeJob != null) {
                if (
                  item.chargeType == this.searchCharge &&
                  item.checkInDate_S >= this.datefrom &&
                  item.checkOutDate_S <= this.dateto &&
                  item.job_name == this.searchChargeJob
                ) {
                  return item;
                }
              } else {
                if (
                  item.chargeType == this.searchCharge &&
                  item.checkInDate_S >= this.datefrom &&
                  item.checkOutDate_S <= this.dateto
                ) {
                  return item;
                }
              }
            } else {
              if (this.searchChargeJob != null) {
                if (
                  item.checkInDate_S >= this.datefrom &&
                  item.checkOutDate_S <= this.dateto &&
                  item.job_name == this.searchChargeJob
                ) {
                  return item;
                }
              } else {
                if (
                  item.checkInDate_S >= this.datefrom &&
                  item.checkOutDate_S <= this.dateto
                ) {
                  return item;
                }
              }
            }
          }
        });
      }
    },
    onClicktoadSolist(id) {
      this.$router.push(`/edit-so/${id}`);
    },
    async loaddataSO() {
      // jobTitle_list
      const res_joblist = await api.getAllJobtitleByCompany(
        localStorage.getItem(server.COMPANYID)
      );
      // console.log("res_joblist.data")
      // console.log(res_joblist.data)
      this.jobTitle_list = res_joblist.data;
      const result = await api.getActivityAllByCompany(
        localStorage.getItem(server.COMPANYID)
      );
      this.dataAll = result.data;
      // console.log("dateAll:")
      console.log(this.dataAll);
      this.mDataArray = result.data;
      this.mDataArray.forEach((item, index) => {
        item.no = index + 1;
      });

      // this.$showLoader();
      this.$hideLoader();
    },
    async closeaddItem() {
      this.dialogApprover = false;
      this.timesheetname = "";
      this.selected = [];
      //   this.$nextTick(() => {
      //     this.editedItem = Object.assign({}, this.defaultItem);
      //     this.editedIndex = -1;
      //   });
    },
    async saveTimeSheet() {
      const resCheckDoc = await api.checkDocRunning("TimeSheet",localStorage.getItem(server.COMPANYID))
      if(resCheckDoc.status == 200){
        if(resCheckDoc.data[0].resCheckDoc){
          // alert(this.period_from  + ":" + this.period_to)
          if (this.selected.length > 0) {
                  let cusid = 0;
                  let cusidget = this.selected[0].customerId;
                  for (let i = 0; i < this.selected.length; i++) {
                    cusid = this.selected[i].customerId;
                    if (cusidget == cusid) {
                      this.checkcusid = 0;
                    } else {
                      this.checkcusid = 1;
                      break;
                    }
                  }
                } else {
                  this.$store.state.global_dialog = true;
                  this.setupAlertDialog(
                    true,
                    "Failed",
                    "Please Select My Activity",
                    "text-h5 red--text text-center"
                  );
                  return;
                }
                if (this.timesheetname == "") {
                  this.$store.state.global_dialog = true;
                  this.setupAlertDialog(
                    true,
                    "Failed",
                    "Please enter TimeSheet Name",
                    "text-h5 red--text text-center"
                  );
                  return;
                }

                //console.log(this.selected)
                //alert(this.checkcusid)
                let formData = new FormData();
                if (this.checkcusid == 0) {
                  //alert("testcheck0")
                  if (this.selected[0].customerId == null) {
                    this.additem.customer_id = 0;
                  } else {
                    this.additem.customer_id = this.selected[0].customerId;
                  }
                } else {
                  this.additem.customer_id = 0;
                }

                this.additem.employee_id = localStorage.getItem(server.USER_ID);
                this.additem.create_by = localStorage.getItem(server.USER_ID);
                this.additem.period_from = this.period_from;
                this.additem.period_to = this.period_to;
                this.additem.timesheet_name = this.timesheetname;
                this.additem.company_id = localStorage.getItem(server.COMPANYID);
                //alert(this.additem.customer_id)
                this.additem.status = 1;
                // console.log(this.selected)
                // console.log(this.additem)
                let loader = this.$loading.show({
                  // Optional parameters
                  color: "green",
                  loader: "dots",
                  container: this.fullPage ? null : this.$refs.formContainer,
                  canCancel: false,
                  width: 120,
                  height: 120,
                  backgroundColor: "#ffffff",
                  opacity: 0.5,
                  zIndex: 999,
                  onCancel: this.onCancel,
                });
                // console.log(this.selected)
                const createA_h = await api.CreatetimesheetH(this.additem);

                if (createA_h.status == 200 || createA_h.status == 201) {
                  console.log("createA_h:", JSON.stringify(createA_h.data));
                  this.timesheet_id = createA_h.data.id;
                  this.timesheet_no = createA_h.data.timesheet_id;

                  this.selected.forEach(async (item) => {
                    //alert(item.customerId )
                    this.addA_D.timesheet_hid = createA_h.data.id;
                    this.addA_D.jobtitle_id = item.jobTitleId;

                    if (
                      item.customerId == "" ||
                      item.customerId == null ||
                      item.customerId == "null"
                    ) {
                      const getComName = await api.getAccountByid(
                        this.additem.employee_id
                      );
                      const getComtoCus = await api.getcomtocus(
                        getComName.data[0].company_name
                      );
                      //alert("test")
                      // alert(getComtoCus.data[0].id)
                      if (getComtoCus.data[0].id == "" || getComtoCus.data[0].id == 0) {
                        this.addA_D.customer_id = 0;
                        //alert("testcusnull")
                      } else {
                        this.addA_D.customer_id = getComtoCus.data[0].id;
                        // alert(this.addA_D.customer_id)
                        // alert("testcusnotnull")
                      }
                    } else {
                      this.addA_D.customer_id = item.customerId;
                    }
                    // this.addA_D.checkin = item.checkInDate;
                    // this.addA_D.checkout = item.checkOutDate;

                    this.addA_D.checkin = `${item.checkInDate_S} ${item.checkInDate.substring(11)}`;
                    this.addA_D.checkout = `${item.checkOutDate_S} ${item.checkOutDate.substring(11)}`;
                    this.addA_D.hours = item.duration * 60;
                    this.addA_D.chargetype = item.chargeType;
                    this.addA_D.prospect = item.prospect;
                    this.addA_D.detail = item.detail;
                    this.addA_D.activityid = item.id;
                    this.addA_D.employeeid = item.userId;
                    if (this.addA_D.jobtitle_id == "") {
                      this.addA_D.jobtitle_id = 0;
                    }
                    this.addA_D.employeeid = item.userId;
                    // console.log(this.addA_D)
                    await api.getaddtimesheetD(this.addA_D);
                  });
                  // this.$store.state.global_push_dialog = true;
                  // this.setupAlertDialogPush(
                  //   true,
                  //   "Information Saved",
                  //   "Information successfully saved",
                  //   "text-h5 green--text text-center",
                  //   "/my-activity"
                  // );
                  loader.hide();
                  this.$store.state.global_push_dialog_timesheet = true;
                  this.setupAlertDialogPushTimeSheet(
                    true,
                    "Timesheet Created Successfully",
                    `Timesheet No. : ${this.timesheet_no}`,
                    "text-h5 green--text text-center",
                    `/edit-mytimesheet/${this.timesheet_id}`,
                    this.timesheet_id,
                    this.timesheet_no
                  );
                } else {
                  loader.hide();
                  this.$store.state.global_dialog = true;
                  this.setupAlertDialog(
                    true,
                    "Information Saved",
                    "ไม่สามารถบันทึกได้",
                    "text-h5 red--text text-center"
                  );
                }
                
                this.closeaddItem();
        }else{
          this.$store.state.global_dialog = true;
          this.setupAlertDialog(
            true,
            "Information Saved",
            "Please kindly check the value of document running.",
            "text-h5 red--text text-center"
          );
          return;
        }
      }else{
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Information Saved",
          "ไม่สามารถบันทึกได้",
          "text-h5 red--text text-center"
        );
        return;
      }
    },
    setupAlertDialog(status, title, message, text_color) {
      this.title = title;
      this.message = message;
      this.dialogAdd = status;
      this.text_color = text_color;
    },
    setupAlertDialogPush(status, title, message, text_color, link) {
      this.title = title;
      this.message = message;
      this.dialogAdd = status;
      this.text_color = text_color;
      this.link = link;
    },
    setupAlertDialogPushTimeSheet(
      status,
      title,
      message,
      text_color,
      link,
      timesheet_id,
      timesheet_no
    ) {
      this.title = title;
      this.message = message;
      this.dialogAdd = status;
      this.text_color = text_color;
      this.link = link;
      this.timesheet_id = timesheet_id;
      this.timesheet_no = timesheet_no;
    },
    async openaddItemdetail() {
      this.dialogApprover = true;
    },
    enterSelect(values) {
      if (values.length > 0) {
        this.disableinstatus = false;
      } else {
        this.disableinstatus = true;
      }
    },
  },
};
