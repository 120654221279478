//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SuccessDialog from "@/components/cards/SuccessDialog";
import api from "@/services/api";
import { server } from "../services/constants";
import html2pdf from "html2pdf.js";
import unity from "@/unity/unity";

export default {
  async mounted() {
    // this.$showLoader();
    await api.checkVersion();
    this.mycompany_id = localStorage.getItem(server.COMPANYID);
    this.loadEmp();
    this.loadCustomer();
    this.loadJobtitle();
    await this.loadDepartmentByCompanyId();
    await this.loadCompany();
    setTimeout(() => {
      this.$hideLoader();
    }, 500);
  },
  components: {
    SuccessDialog,
  },
  computed: {
    computedDateFromFormatted() {
      return unity.formatDate(this.datasearch.datefrom);
    },
    computedDateToFormatted() {
      return unity.formatDate(this.datasearch.dateto);
    },
  },
  data() {
    return {
      showchargetype: "",
      checkdepartmentname: "",
      lengthAlldatasearch: 0,
      menudateto: false,
      menudatefrom: false,
      mycompany_id: 0,
      datasearch: {
        employeename: "",
        customername: "",
        jobtitle_id: 0,
        chargetype: "",
        department: 0,
        // datefrom: "",
        // dateto: "",
        datefrom: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
        dateto: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
        // company_id: 13,
        company_id: localStorage.getItem(server.COMPANYID),
      },
      getnamesignature: [],
      prpoheader: [],
      memoref_list: [],
      no_list: [],
      mysignature: "",
      approvesignature: "",
      opensummarize: false,
      datashowAll: [],
      setdatatoshow: [],
      dataforshowprpo: [],
      getheightcontentpr: 0,
      pageAll: 1,
      previewattachimage: "",
      dataformatpr: {
        title: "",
        subtitle: "Time Sheet Monthly Report",
        paymenttype: "Payment Type",
        companyname_addressrow1:
          "เลขที่ 142 อาคาร ทู แปซิฟิค เพลส ชั้น20 ถนนสุขุมวิท แขวงคลองเตย",
        companyname_addressrow2: "เขตคลองเตย กรุงเทพฯ 10110",
        pr_number: "เลขที่ใบขอซื้อ/PR Number",
        pr_date: "วันที่/PR Date",
        companyname_tel: "โทรศัพท์/Tel. 02-6530489-9",
        companyname_fax: "โทรสาร/Fax. 02-6533706",
        prtitle_th: "ใบขอซื้อ",
        prtitle_en: "PURCHASE REQUISTION",
      },
      dataformatprdetail: {
        project: "โครงการ/Project",
        department: "แผนก/Department",
        requist_date: "วันที่ต้องการ/Requist date",
        type: "ประเภท/Type",
        date_period: "วันที่เริ่มต้น-สิ้นสุด/Date period",
        objective: "วัตถุประสงค์/PR Name",
        vender: "ชื่อผู้จำหน่าย/Vender",
      },
      department_list: [],
      charge_list: [
        { value: "T/C", name: "Time Charge" },
        { value: "N/C", name: "No Charge" },
        { value: "F/C", name: "Fix Charge" },
        { value: "OFF", name: "ทำงานออฟฟิต" },
      ],
      isShowPassword: false,
      fullPage: true,
      dialogEdit: false,
      dialogDelete: false,
      dialogAdd: false,
      dialogSendMail: false,
      text_color: "text-h5 green--text text-center",
      title: "green",
      message: "green",
      link: "",
      closeDelete: false,
      deleteItemConfirm: false,
      emp_list: [],
      jobtitle_list: [],
      customer_list: [],
    };
  },
  methods: {
    async loadEmp() {
      const result = await api.getAccountslist_by_ComID(
        localStorage.getItem(server.COMPANYID)
      );

      this.emp_list = result.data;
    },
    async loadJobtitle() {
      const result = await api.getAllJobtitleByCompany(
        localStorage.getItem(server.COMPANYID)
      );

      this.jobtitle_list = result.data;
    },
    async loadCustomer() {
      const result = await api.getAllCustomerListactiveCompany(
        {
          params: {
            company_id: localStorage.getItem(server.COMPANYID),
          },
        }
      );
      this.customer_list = result.data;
    },
    async loadCompany() {
    let copany_datat = await api.getCompanyByID(localStorage.getItem(server.COMPANYID));
    this.dataformatpr.title = copany_datat.data[0].name;
    },
    async loadDepartmentByCompanyId() {
      let result = await api.getAllDepartment_By_CompanyID(this.mycompany_id);
      this.department_list = result.data;
    },
    findChaege(chargetype) {
      this.charge_list.filter((item, index) => {

        if (item.value == chargetype) {
          this.showchargetype = item.name;
          return;
        }
      });
    },
    async OnSearchDepartment() {

    },
    async SearchAlldata() {
      this.datashowAll = [];
      if (!this.datasearch.datefrom) {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "ค้นหาข้อมูล",
          "กรุณาเลือก DateFrom",
          "text-h5 red--text text-center"
        );
        return;
      }
      if (!this.datasearch.dateto) {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "ค้นหาข้อมูล",
          "กรุณาเลือก DateTo",
          "text-h5 red--text text-center"
        );
        return;
      }

      this.$showLoader();
      this.lengthAlldatasearch = 0;
      const result = await api.getfortimesheetAttendantreport({
        params: this.datasearch,
      });

      if (result.data.length == 0) {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "ค้นหาข้อมูล",
          "ไม่มีข้อมูล",
          "text-h5 red--text text-center"
        );
        this.$hideLoader();
        return;

      }
      
      this.lengthAlldatasearch = result.data.length;

      var dataList = [];
      var nameuser = "";

      for(const[index,item] of result.data.entries()){ 
        if(dataList.length == 0){

          dataList.push(item)
          nameuser = item.user_name;

          if(result.data.length == 1){
            this.dataforshowprpo[0] = dataList;
            await this.checkcontent(this.dataforshowprpo[0]);
          }

        }else{
          if(nameuser == item.user_name){
            dataList.push(item)

            if(index == (result.data.length-1)){
              this.dataforshowprpo[0] = dataList;
              await this.checkcontent(this.dataforshowprpo[0]);
            }

          }else{
            this.dataforshowprpo[0] = dataList;
            await this.checkcontent(this.dataforshowprpo[0]);
            dataList = [];
            dataList.push(item);
            nameuser = item.user_name;

            if(index == (result.data.length-1)){
              this.dataforshowprpo[0] = dataList;
              await this.checkcontent(this.dataforshowprpo[0]);
            }
          }
        }
       
      }
   
      console.log("this.datashowAll: ")
      console.log(this.datashowAll)
      this.$hideLoader();
    },
    async checkcontent(data) {
      let totalclient = 0;
      this.setdatatoshow = [];
      let linedetailprpo = 37;
      let datainlineprpo = 55;
      let addnewbutget = 1;
      let getdata = [];
      let getnewdata = [];
      let hllinedetails = 0;
      this.pageAll = 1;
      let newhllinedetails = 0;

      for (let i = 0; i < this.dataforshowprpo[0].length; i++) {
        let stringchecklength = "";
        let lengthofdataforshowprpo = this.dataforshowprpo[0][i].detail.length;

        let maiek = "่"; //ไม้เอก
        let maito = "้"; //ไม้โท
        let maitree = "๊"; //ไม้ตรี
        let maijattawa = "๋"; //ไม้จัตวา
        let vowelei = "ิ"; //สระอิ
        let vowelee = "ี"; //สระอี
        let voweleue = "ึ"; //สระอึ
        let voweleu = "ื"; //สระอือ
        let vowelu = "ุ"; //สระอุ
        let voweluu = "ู"; //สระอู
        let maihun = "ั"; //ไม้หันอากาศ
        let maiyamok = "็"; //ไม้เอก
        newhllinedetails = 0;
        getnewdata = [];
        this.dataforshowprpo[0][i].no = i + 1;
        totalclient += parseFloat(
          this.dataforshowprpo[0][i].duration
            ? this.dataforshowprpo[0][i].duration
            : 0
        );
        if (i == 0) {
          this.checkdepartmentname = this.dataforshowprpo[0][i].job_name;
          this.dataforshowprpo[0][i].changedepartment = true;
        } else {
          if (
            this.checkdepartmentname !== this.dataforshowprpo[0][i].job_name
          ) {
            this.dataforshowprpo[0][i].changedepartment = true;
            this.checkdepartmentname = this.dataforshowprpo[0][i].job_name;
            hllinedetails++;
          }
        }
        stringchecklength = this.dataforshowprpo[0][i].detail;

        for (let j = 0; j < this.dataforshowprpo[0][i].detail.length; j++) {
          if (
            stringchecklength[j] == maiek ||
            stringchecklength[j] == maito ||
            stringchecklength[j] == maitree ||
            stringchecklength[j] == maijattawa ||
            stringchecklength[j] == vowelei ||
            stringchecklength[j] == vowelee ||
            stringchecklength[j] == voweleue ||
            stringchecklength[j] == voweleu ||
            stringchecklength[j] == vowelu ||
            stringchecklength[j] == voweluu ||
            stringchecklength[j] == maihun ||
            stringchecklength[j] == maiyamok
          ) {
            lengthofdataforshowprpo--;
          }
        }
        newhllinedetails = Math.ceil(lengthofdataforshowprpo / datainlineprpo);

        getnewdata.push(this.dataforshowprpo[0][i]);
        
        if (hllinedetails + newhllinedetails > linedetailprpo) {
          this.setdatatoshow[this.pageAll - 1] = getdata;
          this.pageAll++;

          hllinedetails = 0;
          getdata = [];
          getdata.push(this.dataforshowprpo[0][i]);
          hllinedetails += newhllinedetails;
          if (i == this.dataforshowprpo[0].length - 1) {
            this.dataforshowprpo[0][i].totalclient = totalclient.toFixed(2);
            this.setdatatoshow[this.pageAll - 1] = getdata;
            getdata = [];
          }
        } else {
          hllinedetails += newhllinedetails;

          getdata.push(this.dataforshowprpo[0][i]);

          if (i == this.dataforshowprpo[0].length - 1) {
            this.dataforshowprpo[0][i].totalclient = totalclient.toFixed(2);
            this.setdatatoshow[this.pageAll - 1] = getdata;
            getdata = [];
          }
        }
      }

      this.pageAll += this.lengthAlldatasearch - 1;
      this.opensummarize = true;



      this.setdatatoshow.forEach((item, iitem) => {

        if (iitem == this.setdatatoshow.length - 1) {
          item[this.setdatatoshow[iitem].length - 1].lastdata = "lastindex";
        }

        this.datashowAll.push(item);
      });
    },

    async exportToPDF(advance_id) {
      // this.$refs.html2Pdf.generatePdf();
      let element = document.getElementById("mydivhtmltobase");

      const opt = {
        margin: [0, 0, 0, 0],
        image: {
          type: "jpeg",
          quality: 0.98,
        },
        // enableLinks: false,
        html2canvas: {
          scale: 2,
          dpi: 192,
          letterRendering: true,
          useCORS: true,
          // ignoreElements: (e) => {
          //   return e.classList.contains("cke_pagebreak") ||
          //     e.classList.contains("html2pdf__page-break")
          //     ? true
          //     : false;
          // },
        },
        jsPDF: {
          unit: "cm",
          format: "A4",
          orientation: "portrait",
          putOnlyUsedFonts: true,
          pagesplit: true,
        },
        // pagebreak: { mode: ["avoid-all"], after: ".cke_pagebreak" },
      };

      let abcd = await html2pdf()
        .from(element)
        .set(opt)
        .toPdf()
        .get("pdf")
        // .output("bloburl")
        .then(function(pdf) {

          const totalPages = pdf.internal.getNumberOfPages();
          pdf.deletePage(totalPages);
          // var filtered = pdf.internal.pages.filter(function(el) {
          //   return el != null;
          // });

          // pdf.internal.pages = filtered;
          // for (let i = 0; i < totalPages; i++) {
          //   if (i == totalPages - 1) {
          //     pdf.internal.pages.splice(i, 1);
          //   }
          // }
          // pdf.addPage();

  
          return pdf.output("bloburl");
        });

      window.open(abcd);
      let blob = await fetch(abcd).then((r) => r.blob());

      // let formData = new FormData();
      // formData.append(`file`, blob, "test.pdf");
      // const resultadd = api.creatememoMaster(formData);


      // };
    },
    setupAlertDialog(status, title, message, text_color) {
      this.title = title;
      this.message = message;
      this.dialogAdd = status;
      this.text_color = text_color;
    },
    setupAlertDialogPush(status, title, message, text_color, link) {
      this.title = title;
      this.message = message;
      this.dialogAdd = status;
      this.text_color = text_color;
      this.link = link;
    },
  },
  beforeCreate() {
    this.$store.state.navMenu = true;
  },
};
