//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SuccessDialog from "@/components/cards/SuccessDialog";
import SuccessDialogPush from "@/components/cards/SuccessDialogPush";
import api from "@/services/api";
import { server } from "@/services/constants";
import InvoiceFreeTextSalseorderTab from "./InvoiceFreeTextSalseorderTab";
import InvoiceFreetextTimesheetTab from "./InvoiceFreetextTimesheetTab";
import InvoiceFreetextAdvanceTab from "./InvoiceFreetextAdvanceTab";
import InvoiceMiscellaneousTab from "./InvoiceMiscellaneousTab";
import AdditionalNoteTab from "./AdditionalNoteTab";
import {
  tolocalestringnumber,
  stringcommatonumber,
} from "../computing/tolocalestringnumber";
var convert = require("decimal-number-to-words");
import html2pdf from "html2pdf.js";
import unity from "@/unity/unity";
import pdfvuer from "pdfvuer";

export default {
  async beforeMount() {
    this.$showLoader();
    this.caltotalAll();
    await this.loadCustomer();
    if (this.$route.params.mode == "invoice") {
      this.titleheader = "Free Text Invoice";
      this.add_h.inv_type = "I";
      this.callsoopentab = true;
    } else if (this.$route.params.mode == "performa") {
      this.titleheader = "Free Text  Proforma Invoice";
      this.add_h.inv_type = "P";
      this.callsoopentab = true;
    } else {
      this.getdatafrominv_h = true;
      this.loaddatainv();
    }
    if (this.$route.params.customer_id && this.$route.params.menurequest == "IM") {
      this.menurequest = this.$route.params.menurequest;
      this.add_h.dept_type = "IM";
      this.customer_name_list.forEach((x, index) => {
        if (x.id == this.$route.params.customer_id) {
          this.cus_code = x.cust_account;
        }
      });
    }else if (this.$route.params.customer_id && this.$route.params.menurequest == "IP") {
      this.menurequest = this.$route.params.menurequest;
      this.add_h.dept_type = "IP";
      this.customer_name_list.forEach((x, index) => {
        if (x.id == this.$route.params.customer_id) {
          this.cus_code = x.cust_account;
        }
      });
    }else{
      this.add_h.dept_type = "FreeText";
    }
    this.$hideLoader();
  },
  async mounted() {
    this.$showLoader();
    this.caltotalAll();
    await this.loadCustomer();
    // this.$showLoader();
    if (this.$route.params.mode == "invoice") {
      this.titleheader = "Free Text Invoice";
      this.add_h.inv_type = "I";
      this.add_h.company_inv = this.$route.params.company_inv;
      this.callsoopentab = true;
    } else if (this.$route.params.mode == "performa") {
      this.titleheader = "Free Text  Proforma Invoice";
      this.add_h.inv_type = "P";
      this.add_h.company_inv = this.$route.params.company_inv;
      this.callsoopentab = true;
    } else {
      this.getdatafrominv_h = true;
      await this.loaddatainv();
    }
    if (this.$route.params.customer_id && this.$route.params.menurequest == "IM") {
      this.menurequest = this.$route.params.menurequest;
      this.add_h.dept_type = "IM";
      this.customer_name_list.forEach((x, index) => {
        if (x.id === this.$route.params.customer_id) {
          this.cus_code = x.cust_account;
        }
      });
    }else if (this.$route.params.customer_id && this.$route.params.menurequest == "IP") {
      this.menurequest = this.$route.params.menurequest;
      this.add_h.dept_type = "IP";
      this.customer_name_list.forEach((x, index) => {
        if (x.id == this.$route.params.customer_id) {
          this.cus_code = x.cust_account;
        }
      });
    }else{
      this.add_h.dept_type = "FreeText";
    }

    this.$hideLoader();
  },
  components: {
    SuccessDialog,
    SuccessDialogPush,
    InvoiceFreeTextSalseorderTab,
    InvoiceFreetextTimesheetTab,
    InvoiceFreetextAdvanceTab,
    InvoiceMiscellaneousTab,
    AdditionalNoteTab,
  },
  computed: {
    version() {
      return this.initial_data;
    },
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
    computedDateFormatted() {
      return unity.formatDate(this.date);
    },
  },
  data() {
    return {
      //file
      preview_list2: [],
                getpreview_list: [],
                image: null,
                previewimage: null,
                previewimageedit: null,
                image_list: [],
                preview_list: [],
                dialogpreviewimage: false,
                checkfileapreview: false,
                previewpopupfile: [],
                previewpopupfile2: [],
                dialogpreviewfile: false,
                dialogDelete: false,
                dialogDeleteimage: false,
                filename: [],
                imagename: "",
                load_bg: false,
                checkfileadd: false,
                checkimageadd: false,
                //--
      po_no_cus: "",
      attention_cus: "",
      contact_position_cus:"",
      contact_position:"",
      vat_type:"",
      vat_type_cus:"",
      vat_cus: 0,
      payment_term_cus: 0,
      cusid_freetext_ts: 0,
      cus_code: "",
      Cus_Data: {},
      selected_showE: [],
      oper_sel: 1,
      inv_ts_list: [],
      selected_showAv: [],
      oper_sel_Av: 1,
      callsoopentab: false,
      dialogDeleteForm: false,
      datainv_disable: false,
      showinv_id: "####",
      menu2: false,
      desserts: [],
      getdatefrom_inv_so: [],
      getdatafrominv_h: false,
      dialogAdd: false,
      text_color: "text-h5 green--text text-center",
      title: "green",
      message: "green",
      link: "",
      addinv_docref_so: {
        inv_hid: 0,
        ref_type: "SO",
        ref_id: 0,
      },
      addinv_docref_ts: {
        inv_hid: 0,
        ref_type: "TS",
        ref_id: 0,
      },
      addinv_docref_ad: {
        inv_hid: 0,
        ref_type: "AD",
        ref_id: 0,
      },
      addinv_so: {
        inv_hid: 0,
        so_seq: 0,
        item_id: "",
        item_name: "",
        item_qty: 0,
        item_um: "",
        item_price: 0,
        item_disc: 0,
        item_line_amt: 0,
        user_create: localStorage.getItem(server.USER_ID),
      },
      getdatefrom_inv_h: {
        customerid: 0,
        customer_code: "",
        customer_name: "",
        customer_address: "",
        customer_taxid: "",
        customer_branch: "",
        customer_paymentterm: 0,
        customer_vat: 0,
        vat_type:"",
        saleorder_id: "",
        currency: "",
      },
      period_cover: "",
      wth_rate: 0,
      tax_rate: 0,
      wth_ratetotal: 0,
      tax_ratetotal: 0,
      amount_total: 0,
      invoice_address: "",
      so_total: 0,
      ts_total: 0,
      ad_total: this.$store.state.inv_advance_total,
      mis_total: this.$store.state.inv_miscellaneous_total,
      inv_subtotal: 0,
      titleheader: "Invoice",
      activetab: 1,
      isShowPassword: false,
      fullPage: true,
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      dateCurrent: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      add_h: {
        inv_id: "",
        pinv_id: "",
        company_id: localStorage.getItem(server.COMPANYID),
        inv_date: "",
        period_cover: "",
        inv_type: "",
        customerid: 0,
        customer_address: "",
        customer_taxid: "",
        customer_branch: "",
        customer_paymentterm: 0,
        customer_vat: 0,
        vat_type:"",
        so_total: 0,
        ts_total: 0,
        ad_total: 0,
        mis_total: 0,
        sub_total: 0,
        wth_rate: 0,
        tax_rate: 0,
        amount_total: 0,
        company_inv: "",
        status: 0,
        so_ref: 0,
        additional_top: "",
        additional_bottom: "",
        dept: localStorage.getItem(server.DEPARTMENT_ID),
        dept_type: "",
        user_create: localStorage.getItem(server.USER_ID),
      },
      disableinstatus: false,
      ts_status_oper: "",
      tsTAB_IdforCus: this.$route.params.id,
      getdataByCusStatus: "",
      ts_id: [],
      ad_id: [],
      ts_idTab: [],
      ad_idTab: [],
      ts_idTab: 0,
      datainv_mis: [],
      ad_inv_id: [],
      id_invoice: 0,
      print_header_list: [
        {
          id: "1",
          com_title: "STATEMENT",
          com_eng_1: "DEJ-UDOM & ASSOCIATES LTD.",
          com_eng_2: "ATTORNEYS AT LAW",
          com_thai: "บริษัท เดชอุดม แอนด์ แอสโซซิเอทส์ จำกัด",
          com_add_name: "CHARN ISSARA TOWER 1, 2nd FLOOR,",
          com_add_no: "942/69 RAMA IV ROAD,",
          com_add_city: "KWAENG SURIYAWONG, KHET BANGRAK,",
          com_add_country: "BANGKOK 10500, THAILAND",
          com_add_tel: "Tel : 0-2233-0055, 0-2267-5152",
          com_add_fax: "Fax : 0-2236-6681, 0-2233-0227",
          com_add_email: "E-MAIL : account@dejudom.com",
          com_add_tax_id: `TAX I.D. No. 0992000028227/"HEAD OFFICE"`,
        },
        {
          id: "2",
          com_title: "STATEMENT",
          com_eng_1: "DEJ-UDOM & ASSOCIATES",
          com_eng_2: "ATTORNEYS AT LAW",
          com_thai: "สำนักกฎหมาย เดชอุดม แอนด์ แอสโซซิเอทส์",
          com_add_name: "CHARN ISSARA TOWER 1, 2nd FLOOR,",
          com_add_no: "942/69 RAMA IV ROAD,",
          com_add_city: "KWAENG SURIYAWONG, KHET BANGRAK,",
          com_add_country: "BANGKOK 10500, THAILAND",
          com_add_tel: "Tel : 0-2233-0055, 0-2267-5152",
          com_add_fax: "Fax : 0-2236-6681, 0-2233-0227",
          com_add_email: "E-MAIL : account@dejudom.com",
          com_add_tax_id: `TAX I.D. No. 0992000028227`,
        },
      ],
      print_header: null,
      print_table_detail: [],
      pageAll: 1,
      opensummarize: false,
      datashowAll: [],
      setdatatoshow: [],
      dataforshowprpo: [],
      getheightcontentpr: 0,
      opensummarizetimesheet: false,
      pageAlltimesheet: 1,
      setdatatoshowtimesheet: [],
      print_table_detailtimesheet: [],
      datashowAlltimesheet: [],
      print_detail_timekeeper: [],
      datashowAlltimekeeper: [],
      showprint: "display:none;",
      haveSO: false,
      haveAD: false,
      indexinvADMIS: false,
      customer_name_list: [],
      menurequest:"",
    };
  },
  methods: {
    async loadCustomer() {
      const result = await api.getAllCustomerListactiveCompany({
        params: {
          company_id: localStorage.getItem(server.COMPANYID),
        },
      });

      this.customer_name_list = result.data;
      console.log(this.customer_name_list);
    },
    async exportToPDF(advance_id) {
      // this.$refs.html2Pdf.generatePdf();

      this.showprint = "";
      let element = document.getElementById("mydivhtmltobase");
      const opt = {
        margin: [0, 0, 0, 0],
        image: {
          type: "jpeg",
          quality: 0.98,
        },
        // enableLinks: false,
        html2canvas: {
          scale: 2,
          dpi: 192,
          letterRendering: true,
          useCORS: true,
          // ignoreElements: (e) => {
          //   return e.classList.contains("cke_pagebreak") ||
          //     e.classList.contains("html2pdf__page-break")
          //     ? true
          //     : false;
          // },
        },
        jsPDF: {
          unit: "cm",
          format: "A4",
          orientation: "portrait",
          putOnlyUsedFonts: true,
          pagesplit: true,
        },
        // pagebreak: { mode: ["avoid-all"], after: ".cke_pagebreak" },
      };

      let abcd = await html2pdf()
        .from(element)
        .set(opt)
        .toPdf()
        .get("pdf")
        // .output("bloburl")
        .then(function(pdf) {
          console.log(pdf);
          const totalPages = pdf.internal.getNumberOfPages();
          pdf.deletePage(totalPages);
          return pdf.output("bloburl");
        });
      // console.log(abcd);
      window.open(abcd);
      let blob = await fetch(abcd).then((r) => r.blob());
      this.showprint = "display:none;";

      return;
    },
    async getPrintOutData() {
      // console.log(this.id_invoice);
      this.inv_id = this.id_invoice;
      // this.showprint = "";
      this.haveSO = false;
      this.haveAD = false;
      this.datashowAll = [];
      this.print_table_detail = [];
      this.print_table_detailtimesheet = [];
      this.print_detail_timekeeper = [];
      this.dialogPrintOut = false;
      // alert(`Print Out Invoice ID: ${this.inv_id}, ${this.dialogPrintOutData}`);
      // if (this.dialogPrintOutData == "1") {
      //   this.print_header = this.print_header_list[0];
      // } else if (this.dialogPrintOutData == "2") {
      //   this.print_header = this.print_header_list[1];
      // }
      // console.log("print_header:", JSON.stringify(this.print_header));
      this.$showLoader();

      await this.loadRef_TypeCNById();
      await this.loadInvSoData();
      // await this.loadMainInvSoData();
      // await this.loadInvADData();
      await this.loadInvDocRefData();
      await this.loadInvTSGetTimekeeper();
      await this.loadInvMISData();
      await this.checkcontent();
      await this.checkcontenttimesheet();
      await this.exportToPDF();
      // this.showprint = "display:none;";
      this.$hideLoader();
    },
    async loadRef_TypeCNById() {
      // const res_docref_so = await api.getRef_TypeCNById(this.inv_id);
      // console.log(res_docref_so.data[0]);
      const res_hresult = await api.getdataByIdInvoice_H(this.inv_id);
      console.log("loadRef_TypeCNById");
      console.log(res_hresult.data);

      if (res_hresult.data[0].company_inv == "D") {
        this.print_header = this.print_header_list[0];
      } else if (res_hresult.data[0].company_inv == "L") {
        this.print_header = this.print_header_list[1];
      }

      this.print_header.inv_id = res_hresult.data[0].inv_id
        ? res_hresult.data[0].inv_id
        : res_hresult.data[0].pinv_id;
      this.print_header.customer_name = res_hresult.data[0].customer_name;
      this.print_header.invoice_date = res_hresult.data[0].date;
      this.print_header.addressall = res_hresult.data[0].addressall;
      this.print_header.customer_address = res_hresult.data[0].customer_address;

      this.print_header.province_invoice = res_hresult.data[0].province_invoice;
      this.print_header.country_invoice = res_hresult.data[0].country_invoice;
      this.print_header.zipcode_invoice = res_hresult.data[0].zipcode_invoice;

      this.print_header.period_cover = res_hresult.data[0].period_cover;
      this.print_header.customer_contact_name =
        res_hresult.data[0].customer_contact_name;
      this.print_header.customer_taxid = res_hresult.data[0].customer_taxid;

      this.print_header.ts_total = parseFloat(res_hresult.data[0].ts_total);
      this.print_header.sub_total = parseFloat(res_hresult.data[0].sub_total);
      this.print_header.wth_rate = res_hresult.data[0].wth_rate;
      this.print_header.tax_rate = res_hresult.data[0].tax_rate;
      this.print_header.wth_rate_amount = parseFloat(
        (res_hresult.data[0].sub_total * res_hresult.data[0].wth_rate) / 100
      );
      this.print_header.tax_rate_amount = parseFloat(
        (res_hresult.data[0].sub_total * res_hresult.data[0].tax_rate) / 100
      );

      this.textamount_total_en = convert.toWords(
        res_hresult.data[0].amount_total
      );
      this.print_header.amount_total = parseFloat(
        res_hresult.data[0].amount_total
      );
    },
    async loadInvSoData() {
      var tmp_inv_data;
      const res_inv_so = await api.printInvSO(this.inv_id);
      console.log("loadInvSoData");
      console.log(res_inv_so);

      tmp_inv_data = res_inv_so.data;
      if (tmp_inv_data.length > 0) {
        this.haveSO = true;
      }

      tmp_inv_data.forEach((inv_so, index) => {
        var data = {
          report_type: "SO",
          report_desc: "",
          report_amount: 0,
          first_item: 0,
          solastdata: "",
        };
        if (index == tmp_inv_data.length - 1) {
          data.solastdata = "SOlastindex";
        }
        // if (index == 0) {
        //   data.first_item = 1;
        // }

        data.report_desc = inv_so.report_desc;
        data.report_amount = parseFloat(inv_so.report_amount);
        this.print_table_detail.push(data);
      });

      // console.log("loadInvSoData");
      // console.log(this.print_table_detail);
    },
    async loadInvDocRefData() {
      const res_inv_doc_ref = await api.printInvDocRef(this.inv_id);
      // console.log("print_inv_doc_ref:", JSON.stringify(res_inv_doc_ref.data));
      this.indexinvADMIS = true;

      for await (const inv_doc_ref of res_inv_doc_ref.data) {
        if (inv_doc_ref.ref_type == "AD") {
          await this.loadInvAdvanceDetailData(inv_doc_ref.ref_id);
        }

        if (inv_doc_ref.ref_type == "TS") {
          await this.loadInvTimeSheetDetailData(inv_doc_ref.ref_id);
        }
      }

      // console.log("loadInvDocRefData");
      // console.log(this.print_table_detailtimesheet);

      // this.dataforshowprpo = this.print_table_detail;

      // await this.checkcontent();
    },
    async loadInvTSGetTimekeeper() {
      this.datashowAlltimekeeper = [];
      if (this.print_detail_timekeeper.length <= 0) {
        return;
      }
      let data = {};
      data.hid = this.print_detail_timekeeper;

      const resultc = await api.getAllBytimesheetCreeper_hid(data);
      this.datashowAlltimekeeper = resultc.data;
      this.datashowAlltimekeeper.forEach((item) => {
        item.tc_rateChargeHour_bath = (
          (Number(item.tc_actualchargehour) / 60) *
          Number(item.tc_rate)
        ).toFixed(2);
        item.tc_nocharge = Math.abs(
          (
            (Number(item.tc_actualchargehour) - Number(item.tc_hours)) /
            60
          ).toFixed(2)
        );
        item.tc_nochargeCal = (
          Math.abs(
            (Number(item.tc_actualchargehour) - Number(item.tc_hours)) / 60
          ) * Number(item.tc_rate)
        ).toFixed(2);

        //sum all
        this.sum_chargehour =
          this.sum_chargehour + Number(item.tc_actualchargehour / 60);
        this.sum_rateChargehour =
          this.sum_rateChargehour + Number(item.tc_rateChargeHour_bath);
        this.sum_noCharge_hour =
          this.sum_noCharge_hour + Number(item.tc_nocharge);
        this.sum_noCharge_bath =
          this.sum_noCharge_bath + Number(item.tc_nochargeCal);
        this.sum_grandTotal = this.sum_rateChargehour;

        // //show data in table
        item.tc_rate_show = tolocalestringnumber(item.tc_rate);
        item.tc_actualchargehour_show = tolocalestringnumber(
          Number(item.tc_actualchargehour / 60).toFixed(2)
        );
        item.tc_rateChargeHour_bath_show = tolocalestringnumber(
          item.tc_rateChargeHour_bath
        );
        item.tc_nocharge_show = tolocalestringnumber(item.tc_nocharge);
        item.tc_nochargeCal_show = tolocalestringnumber(item.tc_nochargeCal);
      });
    },
    async loadInvMISData() {
      var tmp_inv_data;
      // const res_docref_so = await api.getRef_TypeCNById(this.inv_id);
      const res_inv_mis = await api.printInvMIS(this.inv_id);
      console.log("loadInvMISData");
      console.log(res_inv_mis);
      tmp_inv_data = res_inv_mis.data;

      tmp_inv_data.forEach((inv_mis, index) => {
        var data = {
          report_type: "ADMIS",
          report_desc: "",
          report_amount: 0,
          first_item: 0,
        };

        if (index == 0 && this.haveAD == false) {
          data.first_item = 1;
        }
        data.report_desc = inv_mis.report_desc;
        data.report_amount = parseFloat(inv_mis.report_amount);
        this.print_table_detail.push(data);
      });
      // console.log("loadInvMISData");
      // console.log(this.print_table_detail);
    },
    async loadInvTimeSheetDetailData(ref_id) {
      var tmp_inv_data;
      this.print_detail_timekeeper.push(ref_id);
      const res_inv_time_sheet_detail = await api.printInvTS(ref_id);
      // console.log(
      //   "print_inv_time_sheet_detail:",
      //   JSON.stringify(res_inv_time_sheet_detail.data)
      // );
      tmp_inv_data = res_inv_time_sheet_detail.data;

      tmp_inv_data.forEach((inv_time_sheet_detail) => {
        var data = {
          report_type: "TS",
          report_desc: "",
          report_amount: 0,
          report_date: "",
          lawyer: "",
        };

        data.report_desc = inv_time_sheet_detail.report_desc;
        data.report_amount = parseFloat(inv_time_sheet_detail.report_amount);
        data.report_date = inv_time_sheet_detail.report_date;
        data.lawyer = inv_time_sheet_detail.lawyer;
        this.print_table_detailtimesheet.push(data);
      });

      // console.log(
      //   "print_table_detail:",
      //   JSON.stringify(this.print_table_detail)
      // );
    },
    async loadInvAdvanceDetailData(ref_id) {
      var tmp_inv_data;
      const res_inv_advance_detail = await api.printInvAD(ref_id);
      // console.log(
      //   "print_inv_advance_detail:",
      //   JSON.stringify(res_inv_advance_detail.data)
      // );
      tmp_inv_data = res_inv_advance_detail.data;
      if (tmp_inv_data.length > 0) {
        this.haveAD = true;
      }

      tmp_inv_data.forEach((inv_advance_detail, index) => {
        var data = {
          report_type: "ADMIS",
          report_desc: "",
          report_amount: 0,
          first_item: 0,
        };
        if (this.indexinvADMIS == true && index == 0) {
          data.first_item = 1;
        }

        data.report_desc = inv_advance_detail.report_desc;
        data.report_amount = parseFloat(inv_advance_detail.report_amount);
        this.print_table_detail.push(data);
      });
      this.indexinvADMIS = false;

      // console.log(
      //   "print_table_detail:",
      //   JSON.stringify(this.print_table_detail)
      // );
    },
    async checkcontent() {
      console.log("send data ====");
      console.log(this.print_table_detail);
      this.datashowAll = [];
      // alert(data.length);
      this.setdatatoshow = [];
      let linedetailprpo = 23;
      let datainlineprpo = 80;
      let addnewbutget = 1;
      let getdata = [];
      let getnewdata = [];
      let hllinedetails = 0;
      this.pageAll = 1;
      let newhllinedetails = 0;
      for (let i = 0; i < this.print_table_detail.length; i++) {
        let stringchecklength = "";
        let lengthofdataforshowprpo = this.print_table_detail[i].report_desc
          .length;

        let maiek = "่"; //ไม้เอก
        let maito = "้"; //ไม้โท
        let maitree = "๊"; //ไม้ตรี
        let maijattawa = "๋"; //ไม้จัตวา
        let vowelei = "ิ"; //สระอิ
        let vowelee = "ี"; //สระอี
        let voweleue = "ึ"; //สระอึ
        let voweleu = "ื"; //สระอือ
        let vowelu = "ุ"; //สระอุ
        let voweluu = "ู"; //สระอู
        let maihun = "ั"; //ไม้หันอากาศ
        let maiyamok = "็"; //ไม้เอก
        newhllinedetails = 0;
        getnewdata = [];
        this.print_table_detail[i].no = i + 1;

        for (
          let j = 0;
          j < this.print_table_detail[i].report_desc.length;
          j++
        ) {
          if (
            stringchecklength[j] == maiek ||
            stringchecklength[j] == maito ||
            stringchecklength[j] == maitree ||
            stringchecklength[j] == maijattawa ||
            stringchecklength[j] == vowelei ||
            stringchecklength[j] == vowelee ||
            stringchecklength[j] == voweleue ||
            stringchecklength[j] == voweleu ||
            stringchecklength[j] == vowelu ||
            stringchecklength[j] == voweluu ||
            stringchecklength[j] == maihun ||
            stringchecklength[j] == maiyamok
          ) {
            lengthofdataforshowprpo--;
          }
        }
        newhllinedetails = Math.ceil(lengthofdataforshowprpo / datainlineprpo);
        console.log("data lll");
        console.log(newhllinedetails);
        if (
          this.print_table_detail[i].first_item == 1 ||
          this.print_table_detail[i].solastdata == "SOlastindex" ||
          this.haveSO == false
        ) {
          newhllinedetails + 2;
        }

        // newhllinedetails = Math.ceil(
        //   this.print_table_detail[i].NAME.length / datainlineprpo
        // );

        // newhllinedetails += addnewbutget;
        getnewdata.push(this.print_table_detail[i]);
        console.log(
          `${hllinedetails} +${newhllinedetails} +${linedetailprpo} +${i}`
        );
        if (hllinedetails + newhllinedetails > linedetailprpo) {
          console.log(i + " ============== " + this.print_table_detail.length);
          this.setdatatoshow[this.pageAll - 1] = getdata;
          this.pageAll++;

          hllinedetails = 0;
          getdata = [];
          getdata.push(this.print_table_detail[i]);
          hllinedetails += newhllinedetails;
          if (i == this.print_table_detail.length - 1) {
            this.setdatatoshow[this.pageAll - 1] = getdata;
            getdata = [];
          }
        } else {
          hllinedetails += newhllinedetails;

          getdata.push(this.print_table_detail[i]);

          if (i == this.print_table_detail.length - 1) {
            this.setdatatoshow[this.pageAll - 1] = getdata;
            getdata = [];
          }
        }
      }
      this.opensummarize = true;
      console.log(this.pageAll);
      console.log("showwwwwwwwwwwwwwwwwwwww");
      console.log(this.setdatatoshow);

      this.setdatatoshow.forEach((item, iitem) => {
        console.log(item);
        console.log(iitem + " " + this.setdatatoshow[0].length);
        if (iitem == this.setdatatoshow.length - 1) {
          item[this.setdatatoshow[iitem].length - 1].lastdata = "lastindex";
        }

        this.datashowAll.push(item);
      });
      console.log(this.datashowAll);
    },

    async checkcontenttimesheet() {
      console.log("send data ====");
      console.log(this.print_table_detailtimesheet);
      this.datashowAlltimesheet = [];
      // alert(data.length);
      this.setdatatoshowtimesheet = [];
      let linedetailprpo = 42;
      let datainlineprpo = 60;
      let addnewbutget = 1;
      let getdata = [];
      let getnewdata = [];
      let hllinedetails = 0;
      this.pageAlltimesheet = 1;
      let newhllinedetails = 0;
      for (let i = 0; i < this.print_table_detailtimesheet.length; i++) {
        let stringchecklength = "";
        let lengthofdataforshowprpo = this.print_table_detailtimesheet[i]
          .report_desc.length;

        let maiek = "่"; //ไม้เอก
        let maito = "้"; //ไม้โท
        let maitree = "๊"; //ไม้ตรี
        let maijattawa = "๋"; //ไม้จัตวา
        let vowelei = "ิ"; //สระอิ
        let vowelee = "ี"; //สระอี
        let voweleue = "ึ"; //สระอึ
        let voweleu = "ื"; //สระอือ
        let vowelu = "ุ"; //สระอุ
        let voweluu = "ู"; //สระอู
        let maihun = "ั"; //ไม้หันอากาศ
        let maiyamok = "็"; //ไม้เอก
        newhllinedetails = 0;
        getnewdata = [];
        this.print_table_detailtimesheet[i].no = i + 1;

        for (
          let j = 0;
          j < this.print_table_detailtimesheet[i].report_desc.length;
          j++
        ) {
          if (
            stringchecklength[j] == maiek ||
            stringchecklength[j] == maito ||
            stringchecklength[j] == maitree ||
            stringchecklength[j] == maijattawa ||
            stringchecklength[j] == vowelei ||
            stringchecklength[j] == vowelee ||
            stringchecklength[j] == voweleue ||
            stringchecklength[j] == voweleu ||
            stringchecklength[j] == vowelu ||
            stringchecklength[j] == voweluu ||
            stringchecklength[j] == maihun ||
            stringchecklength[j] == maiyamok
          ) {
            lengthofdataforshowprpo--;
          }
        }
        newhllinedetails = Math.ceil(lengthofdataforshowprpo / datainlineprpo);
        console.log("data lll");
        console.log(newhllinedetails);

        // newhllinedetails = Math.ceil(
        //   this.print_table_detailtimesheet[i].NAME.length / datainlineprpo
        // );

        // newhllinedetails += addnewbutget;
        getnewdata.push(this.print_table_detailtimesheet[i]);
        console.log(
          `${hllinedetails} +${newhllinedetails} +${linedetailprpo} +${i}`
        );
        if (hllinedetails + newhllinedetails > linedetailprpo) {
          console.log(
            i + " ============== " + this.print_table_detailtimesheet.length
          );
          this.setdatatoshowtimesheet[this.pageAlltimesheet - 1] = getdata;
          this.pageAlltimesheet++;

          hllinedetails = 0;
          getdata = [];
          getdata.push(this.print_table_detailtimesheet[i]);
          hllinedetails += newhllinedetails;
          if (i == this.print_table_detailtimesheet.length - 1) {
            this.setdatatoshowtimesheet[this.pageAlltimesheet - 1] = getdata;
            getdata = [];
          }
        } else {
          hllinedetails += newhllinedetails;

          getdata.push(this.print_table_detailtimesheet[i]);

          if (i == this.print_table_detailtimesheet.length - 1) {
            this.setdatatoshowtimesheet[this.pageAlltimesheet - 1] = getdata;
            getdata = [];
          }
        }
      }
      this.opensummarizetimesheet = true;
      console.log(this.pageAlltimesheet);
      console.log("showwwwwwwwwwwwwwwwwwwww");
      console.log(this.setdatatoshowtimesheet);

      this.setdatatoshowtimesheet.forEach((item, iitem) => {
        console.log(item);
        console.log(iitem + " " + this.setdatatoshowtimesheet[0].length);
        if (iitem == this.setdatatoshowtimesheet.length - 1) {
          item[this.setdatatoshowtimesheet[iitem].length - 1].lastdata =
            "lastindex";
        }

        this.datashowAlltimesheet.push(item);
      });
      console.log(this.datashowAlltimesheet);
    },

    async loaddatainv() {
      this.$showLoader();
      const res_hresult = await api.getdataByIdInvoice_H(this.$route.params.id);
      const res_docref_so = await api.getRef_TypeSOById(this.$route.params.id);

      this.id_invoice = res_hresult.data[0].id;
      this.date = res_hresult.data[0].date;
      this.period_cover = res_hresult.data[0].period_cover;
      this.add_h.inv_id = res_hresult.data[0].inv_id;
      this.add_h.pinv_id = res_hresult.data[0].pinv_id;
      if (this.add_h.inv_id) {
        this.showinv_id = this.add_h.inv_id;
      } else if (this.add_h.pinv_id) {
        this.showinv_id = this.add_h.pinv_id;
      } else {
        this.showinv_id = "####";
      }
      if (
        res_hresult.data[0].status == "P.invoice" ||
        res_hresult.data[0].status == "Invoice"
      ) {
        this.datainv_disable = true;
        this.ts_status_oper = "Submit";
      } else {
        this.ts_status_oper = "draft";
      }
      this.add_h.inv_type = res_hresult.data[0].inv_type;
      this.add_h.customerid = res_hresult.data[0].customer_id;
      this.add_h.customer_address = res_hresult.data[0].customer_address;
      this.add_h.customer_taxid = res_hresult.data[0].customer_taxid;
      this.add_h.customer_branch = res_hresult.data[0].customer_branch;
      this.add_h.customer_paymentterm =
        res_hresult.data[0].customer_paymentterm;
      this.add_h.customer_vat = res_hresult.data[0].customer_vat;
      this.add_h.vat_type = res_hresult.data[0].vat_type;
      this.add_h.company_inv = res_hresult.data[0].company_inv;
      this.add_h.additional_top = res_hresult.data[0].additional_top;
      this.add_h.additional_bottom = res_hresult.data[0].additional_bottom;

      this.getdatefrom_inv_h.customerid = res_hresult.data[0].customer_id;
      this.getdatefrom_inv_h.customer_code = res_hresult.data[0].customer_code;
      this.getdatefrom_inv_h.customer_name = res_hresult.data[0].customer_name;
      this.getdatefrom_inv_h.po_no = res_hresult.data[0].po_no;
      this.getdatefrom_inv_h.attention = res_hresult.data[0].attention;
      this.getdatefrom_inv_h.customer_address =
        res_hresult.data[0].customer_address;
      this.getdatefrom_inv_h.customer_taxid =
        res_hresult.data[0].customer_taxid;
      this.getdatefrom_inv_h.customer_branch =
        res_hresult.data[0].customer_branch;
      this.getdatefrom_inv_h.customer_paymentterm =
        res_hresult.data[0].customer_paymentterm;
      this.getdatefrom_inv_h.customer_vat = res_hresult.data[0].customer_vat;
      this.getdatefrom_inv_h.vat_type = res_hresult.data[0].vat_type;
      if (res_docref_so.data[0]) {
        this.getdatefrom_inv_h.saleorder_id =
          res_docref_so.data[0].saleorder_id;
        this.getdatefrom_inv_h.currency = res_docref_so.data[0].currency;
      }

      this.inv_subtotal = res_hresult.data[0].sub_total;
      this.wth_rate = res_hresult.data[0].wth_rate;
      this.tax_rate = res_hresult.data[0].tax_rate;
      this.amount_total = res_hresult.data[0].amount_total;
      this.amount_total = tolocalestringnumber(this.amount_total);
      this.so_total = res_hresult.data[0].so_total;
      this.so_total = tolocalestringnumber(this.so_total);
      this.ts_total = res_hresult.data[0].ts_total;
      this.ts_total = tolocalestringnumber(this.ts_total);
      this.ad_total = res_hresult.data[0].ad_total;
      this.ad_total = tolocalestringnumber(this.ad_total);
      this.mis_total = res_hresult.data[0].mis_total;
      this.mis_total = tolocalestringnumber(this.mis_total);

      const res_misresult = await api.getdataByIdInv_Mis(this.$route.params.id);
      this.datainv_mis = res_misresult.data;

      this.caltotalAll();
      this.calwth_rate();
      this.caltax_rate();

      const res_soresult = await api.getdataByIdInv_SO(this.$route.params.id);
      this.getdatefrom_inv_so = res_soresult.data;

      const res_tsid = await api.getTs_hListId(this.$route.params.id);
      const dataAllTsId = res_tsid.data;
      // console.log(dataAllTsId)
      let indexAD = 0;
      let indexTS = 0;
      if (dataAllTsId.length > 0) {
        for (let i = 0; i < dataAllTsId.length; i++) {
          if (dataAllTsId[i].ref_type == "TS") {
            this.ts_id[indexTS] = dataAllTsId[i].ref_id;
            indexTS += 1;
          } else if (dataAllTsId[i].ref_type == "AD") {
            this.ad_id[indexAD] = dataAllTsId[i].ref_id;
            indexAD += 1;
          }
        }
      }
      this.ts_idTab = this.ts_id;
      this.ad_idTab = this.ad_id;
      this.ad_inv_id = this.ad_id;
      if (this.getdatafrominv_h == true) {
        this.getdataByCusStatus = "INV";
      }
      this.callsoopentab = true;
      this.$hideLoader();
    },
    async saveinvDraftandSubmit(mode) {
      if (mode == "Submit") {
        if (this.$route.params.mode == "invoice") {
          const resCheckDoc = await api.checkDocRunning("Invoice",localStorage.getItem(server.COMPANYID))
          if(resCheckDoc.status == 200){
            if(!resCheckDoc.data[0].resCheckDoc){
              this.$store.state.global_dialog = true;
              this.setupAlertDialog(
                true,
                "Information Saved",
                "Please kindly check the value of document running.",
                "text-h5 red--text text-center"
              );
              return;
            }
          }else{
            this.$store.state.global_dialog = true;
            this.setupAlertDialog(
              true,
              "Information Saved",
              "ไม่สามารถบันทึกได้",
              "text-h5 red--text text-center"
            );
            return;
          }
        } else if (this.$route.params.mode == "performa") {
          const resCheckDoc = await api.checkDocRunning("PInvoice",localStorage.getItem(server.COMPANYID))
          if(resCheckDoc.status == 200){
            if(!resCheckDoc.data[0].resCheckDoc){
              this.$store.state.global_dialog = true;
              this.setupAlertDialog(
                true,
                "Information Saved",
                "Please kindly check the value of document running.",
                "text-h5 red--text text-center"
              );
              return;
            }
          }else{
            this.$store.state.global_dialog = true;
            this.setupAlertDialog(
              true,
              "Information Saved",
              "ไม่สามารถบันทึกได้",
              "text-h5 red--text text-center"
            );
            return;
          }
        }
      }
      let formData = new FormData();

      formData.append("company_inv",  this.add_h.company_inv);

      formData.append("company_id",  this.add_h.company_id);


      formData.append("dept",  this.add_h.dept);

      formData.append("dept_type",  this.add_h.dept_type);

      formData.append("inv_type",  this.add_h.inv_type);

      formData.append("vat_type",  this.add_h.vat_type);

      formData.append("user_create",  this.add_h.user_create);

      formData.append("customerid",  this.add_h.customerid);
      // this.add_h.customerid = customer_id;

      formData.append("customer_address",  this.add_h.customer_address);
      //   this.add_h.customer_address = invoice_address;

      formData.append("customer_taxid",  this.add_h.customer_taxid);
      //   this.add_h.customer_taxid = tax_id;
      
      formData.append("customer_branch",  this.add_h.customer_branch);
      //   this.add_h.customer_branch = branch;

      this.image_list.forEach((x, index) => { formData.append(`file`, x); });

      formData.append("customer_paymentterm", this.payment_term_cus);
      this.add_h.customer_paymentterm = this.payment_term_cus;

      formData.append("customer_vat", this.vat_cus);
      this.add_h.customer_vat = this.vat_cus;

      formData.append("po_no", this.po_no_cus);
      this.add_h.po_no = this.po_no_cus;

      formData.append("attention", this.attention_cus);
      this.add_h.attention = this.attention_cus;

      formData.append("contact_position", this.contact_position_cus);
      this.add_h.contact_position = this.contact_position_cus;

      formData.append("inv_date", this.date);
      this.add_h.inv_date = this.date;

      formData.append("period_cover", this.period_cover);
      this.add_h.period_cover = this.period_cover;

      formData.append("so_total", parseFloat(stringcommatonumber(this.so_total.toString())));
      this.add_h.so_total = parseFloat(
        stringcommatonumber(this.so_total.toString())
      );

      formData.append("ts_total", parseFloat(stringcommatonumber(this.ts_total.toString())) );
      this.add_h.ts_total = parseFloat(
        stringcommatonumber(this.ts_total.toString())
      );

      formData.append("ad_total", parseFloat(stringcommatonumber(this.ad_total.toString())) );
      this.add_h.ad_total = parseFloat(
        stringcommatonumber(this.ad_total.toString())
      );

      formData.append("mis_total", parseFloat(stringcommatonumber(this.mis_total.toString())) );
      this.add_h.mis_total = parseFloat(
        stringcommatonumber(this.mis_total.toString())
      );

      formData.append("sub_total", parseFloat(stringcommatonumber(this.inv_subtotal.toString())) );
      this.add_h.sub_total = parseFloat(
        stringcommatonumber(this.inv_subtotal.toString())
      );

      formData.append("wth_rate", parseFloat(this.wth_rate) );
      this.add_h.wth_rate = parseFloat(this.wth_rate);

      formData.append("tax_rate", parseFloat(this.tax_rate) );
      this.add_h.tax_rate = parseFloat(this.tax_rate);

      formData.append("amount_total", parseFloat(stringcommatonumber(this.amount_total.toString())) );
      this.add_h.amount_total = parseFloat(
        stringcommatonumber(this.amount_total.toString())
      );

      // this.add_h.status = 1;
      // //console.log(this.add_h)

      let add_hresult = [];

      this.$showLoader();

      if (mode == "Draft") {

        formData.append("status", 1 );
        this.add_h.status = 1;
        add_hresult = await api.addInvoice_H(formData);

      } else if (mode == "Submit") {

        formData.append("status", 2 );
        this.add_h.status = 2;
        add_hresult = await api.addInvoice_H_Geninv_id(formData);
        
      } else {
        return;
      }

      if (add_hresult.status == 200 || add_hresult.status == 201) {
        //add inv_so
    //     await this.desserts.sort(function(a, b){
    // return a.amount - b.amount;
    //   });
        this.desserts.forEach(async (x, index) => {
          this.addinv_so.inv_hid = add_hresult.data.id;
          this.addinv_so.so_seq = index + 1;
          this.addinv_so.item_name = x.description;
          this.addinv_so.item_qty = Number(stringcommatonumber(x.unit));
          this.addinv_so.item_price = Number(
            stringcommatonumber(x.PricePerUnit)
          );
          this.addinv_so.item_line_amt = Number(stringcommatonumber(x.amount));
          await api.addInv_SO(this.addinv_so);
        });

        //add inv ts
        if (this.inv_ts_list.length > 0) {
          for (let i = 0; i < this.inv_ts_list.length; i++) {
            let addDataInvTS = {};
            addDataInvTS.inv_hid = add_hresult.data.id;
            addDataInvTS.user_id = this.inv_ts_list[i].emp_id;
            addDataInvTS.emp_rate = Number(this.inv_ts_list[i].tc_rate);
            addDataInvTS.tc_actualchargehour = Number(
              this.inv_ts_list[i].tc_actualchargehour_show
            );
            addDataInvTS.tc_ratechargehour_bath = Number(
              this.inv_ts_list[i].tc_rateChargeHour_bath
            );
            let req_addInvTS = await api.addInvTS(addDataInvTS);
            if (req_addInvTS.status == 200 || req_addInvTS.status == 201) {
              //add sucess
            } else {
              await api.deleteInvTS(add_hresult.data.id);
              break;
            }
          }
        }

        //add inv_docref ts
        if (this.ts_id.length > 0) {
          for (let i = 0; i < this.ts_id.length; i++) {
            this.addinv_docref_ts.inv_hid = add_hresult.data.id;
            this.addinv_docref_ts.ref_id = this.ts_id[i];
            const res_docts = await api.addInv_Docref(this.addinv_docref_ts);
            if (res_docts.status == 200 || res_docts.status == 201) {
              //add sucess
            } else {
            }
          }
        }

        //add doc_ref ad
        if (this.ad_id.length > 0) {
          for (let i = 0; i < this.ad_id.length; i++) {
            this.addinv_docref_ad.inv_hid = add_hresult.data.id;
            this.addinv_docref_ad.ref_id = this.ad_id[i];
            await api.addInv_Docref(this.addinv_docref_ad);
            //stam status Advance_H = 10 Invioce
            await api.updateAdvanceH(this.ad_id[i], { status: 10 });
          }
        }

        //add invoice miscellanuen
        this.datainv_mis.forEach(async (item, index) => {
          item.inv_hid = add_hresult.data.id;
          item.mis_seq = index+1;
          await api.addInv_Mis(item);
        });

        this.$hideLoader();

        //load succes
        this.$store.state.global_push_dialog = true;
        this.setupAlertDialogPush(
          true,
          "Information Saved",
          "Information successfully saved",
          "text-h5 green--text text-center",
          `/edit-invoice/${add_hresult.data.id}/inv_h/edit`
        );
      } else {
        this.$hideLoader();
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Information Saved",
          "ไม่สามารถบันทึกได้",
          "text-h5 red--text text-center"
        );
      }
    },

    async getdatafromSOcomponent(
      customer_id,
      invoice_address,
      tax_id,
      branch,
      payment_term,
      vat,
      vat_type,
      testsumso,
      so_hid
    ) {
      if (this.getdatafrominv_h === false) {
        if (!payment_term) {
          payment_term = 0;
        }
        this.so_total = testsumso;
        this.so_total = tolocalestringnumber(this.so_total);
        this.tax_rate = vat;
        this.add_h.customerid = customer_id;
        this.add_h.customer_address = invoice_address;
        this.add_h.customer_taxid = tax_id;
        this.add_h.customer_branch = branch;
        this.add_h.customer_paymentterm = payment_term;
        this.add_h.customer_vat = vat;
        this.add_h.vat_type = vat_type;
        this.invoice_address = invoice_address;
        this.addinv_docref_so.ref_id = so_hid;
        // console.log("this.add_h")
        // console.log(this.add_h)
      }

      this.caltotalAll();
      this.calwth_rate();
      this.caltax_rate();
    },
    async getdatafromSOtable(dataintable) {
      this.desserts = dataintable;
    },
    async getdatavatcus(VAT_CUS) {
      this.vat_cus = VAT_CUS;
    },
    async getdatapaymentcus(payment_CUS) {
      this.payment_term_cus = payment_CUS;
    },
    async getdataPonoCus(PONO_CUS) {
      this.po_no_cus = PONO_CUS;
    },
    async getdataAttentionCus(attention) {
      this.attention_cus = attention;
    },
    async getdatacontact_positionCus(contact_position) {
      this.contact_position_cus = contact_position;
    },
    async getdatavat_type(vat_type){
      this.vat_type_cus = vat_type;
      this.add_h.vat_type = vat_type;
      this.calgrandtotal();
    },

    async getdatafromTStotal(TStotal) {
      this.ts_total = TStotal;
      this.ts_total = tolocalestringnumber(this.ts_total);
      this.caltotalAll();
      this.calwth_rate();
      this.caltax_rate();
    },
    async getdatafromAdtotal(Adtotal) {
      this.ad_total = Adtotal;
      this.ad_total = tolocalestringnumber(this.ad_total);
      this.caltotalAll();
      this.calwth_rate();
      this.caltax_rate();
    },
    async getTsId(TS_ID) {
      this.ts_id = TS_ID;
    },
    async getAdId(AS_ID) {
      this.ad_id = AS_ID;
    },
    async getAdInvioceId(AD_Invioce_Id) {
      this.ad_inv_id = AD_Invioce_Id;
    },
    async getSelected_showE(Selected_ShowE) {
      this.selected_showE = Selected_ShowE;
    },
    async getOper_sel(Oper_Sel) {
      this.oper_sel = Oper_Sel;
    },
    async getInvTsList(invTs_List) {
      this.inv_ts_list = invTs_List;
    },
    async getSelected_showEAv(Selected_ShowAv) {
      this.selected_showAv = Selected_ShowAv;
    },
    async getOper_selAv(Oper_SelAv) {
      this.oper_sel_Av = Oper_SelAv;
    },
    async getdatafromSOisheader(testsumso) {
      this.so_total = testsumso;
      this.caltotalAll();
      this.calwth_rate();
      this.caltax_rate();
    },
    async getdatafromSOvat(VAT) {
      this.tax_rate = VAT;
      this.caltotalAll();
      this.calwth_rate();
      this.caltax_rate();
    },
    async getdatafromSOCustomer(Customer) {
      this.Cus_Data = Customer;
      console.log("this.Cus_Data");
      console.log(this.Cus_Data);
      this.cus_code = this.Cus_Data.cust_account;
      this.cusid_freetext_ts = this.Cus_Data.id;
      this.add_h.customerid = this.Cus_Data.id;
      this.add_h.customer_address = `${this.Cus_Data.address_invoice} ${this.Cus_Data.province_invoice} ${this.Cus_Data.zipcode_invoice}`;
      this.add_h.customer_taxid = this.Cus_Data.taxid;
      this.add_h.customer_branch = this.Cus_Data.branch;
      this.add_h.customer_paymentterm = this.Cus_Data.payment_term;
      this.add_h.customer_vat = this.Cus_Data.vat;
      this.add_h.vat_type = this.Cus_Data.vat_type;
    },

    async getdatafromMis(data) {
      this.mis_total = 0;
      this.datainv_mis = [];
      this.datainv_mis = data;
      this.datainv_mis.forEach((x, index) => {
        this.mis_total += parseFloat(x.amount);
      });
      this.mis_total = tolocalestringnumber(this.mis_total);
      this.caltotalAll();
      this.calwth_rate();
      this.caltax_rate();
    },

    async getdatafromadditional(data) {
      console.log(data);
      this.add_h.additional_top = data.additional_top;
      this.add_h.additional_bottom = data.additional_bottom;
    },

    caltotalAll() {
      // this.inv_subtotal =
      //   parseFloat(stringcommatonumber(this.so_total.toString())) +
      //   parseFloat(stringcommatonumber(this.ts_total.toString())) +
      //   parseFloat(stringcommatonumber(this.ad_total.toString())) +
      //   parseFloat(stringcommatonumber(this.mis_total.toString()));
      // this.inv_subtotal = tolocalestringnumber(this.inv_subtotal);
      this.calgrandtotal();
    },
    tabClicked(selectedTab) {
      // console.log("Current tab re-clicked:" + selectedTab.tab.name);
    },
    tabChanged(selectedTab) {
      // console.log("Tab changed to:" + selectedTab.tab.name);
    },
    closeDelete() {},
    deleteItemConfirm() {},
    calwth_rate() {
      // this.wth_ratetotal = parseFloat(
      //   (parseFloat(stringcommatonumber(this.inv_subtotal.toString())) *
      //     parseFloat(stringcommatonumber(this.wth_rate.toString()))) /
      //     100
      // );
      // this.wth_ratetotal = tolocalestringnumber(this.wth_ratetotal);
      this.calgrandtotal();
    },
    caltax_rate() {
      // this.tax_ratetotal = parseFloat(
      //   (parseFloat(stringcommatonumber(this.inv_subtotal.toString())) *
      //     parseFloat(stringcommatonumber(this.tax_rate.toString()))) /
      //     100
      // );
      // this.tax_ratetotal = tolocalestringnumber(this.tax_ratetotal);
      this.calgrandtotal();
    },

    calgrandtotal() {
      this.inv_subtotal =
        parseFloat(stringcommatonumber(this.so_total.toString())) +
        parseFloat(stringcommatonumber(this.ts_total.toString())) +
        parseFloat(stringcommatonumber(this.ad_total.toString())) +
        parseFloat(stringcommatonumber(this.mis_total.toString()));
      this.inv_subtotal = tolocalestringnumber(this.inv_subtotal);
    
      if(this.add_h.vat_type == "E"){
      this.tax_ratetotal = parseFloat(
        (parseFloat(stringcommatonumber(this.inv_subtotal.toString())) *
          parseFloat(stringcommatonumber(this.tax_rate.toString()))) /
          100
      );
      this.tax_ratetotal = tolocalestringnumber(this.tax_ratetotal);
      this.wth_ratetotal = parseFloat(
        (parseFloat(stringcommatonumber(this.inv_subtotal.toString())) *
          parseFloat(stringcommatonumber(this.wth_rate.toString()))) /
          100
      );
      this.wth_ratetotal = tolocalestringnumber(this.wth_ratetotal);
     

        this.amount_total = parseFloat(
        parseFloat(stringcommatonumber(this.inv_subtotal.toString())) -
          parseFloat(stringcommatonumber(this.wth_ratetotal.toString())) +
          parseFloat(stringcommatonumber(this.tax_ratetotal.toString()))
      ).toFixed(2);
      this.amount_total = tolocalestringnumber(this.amount_total);
      
      }else if(this.add_h.vat_type == "I"){
        
        this.amount_total = (stringcommatonumber(this.inv_subtotal)/(1 + this.wth_rate / 100));
        this.wth_ratetotal = (stringcommatonumber(this.inv_subtotal) - this.amount_total).toFixed(2);
        this.inv_subtotal = (this.amount_total / (1 + this.tax_rate / 100)).toFixed(2);
        this.tax_ratetotal = (this.amount_total - this.inv_subtotal).toFixed(2);
      
        this.amount_total = tolocalestringnumber(this.amount_total);
        this.inv_subtotal = tolocalestringnumber(this.inv_subtotal);
        this.tax_ratetotal = tolocalestringnumber(this.tax_ratetotal);
        this.wth_ratetotal = tolocalestringnumber(this.wth_ratetotal);
       
      }else{
      }
    },
    async deleteItemConfirmForm() {
      this.$router.push(`/invoice`);
    },
    async closeDeleteForm() {
      this.dialogDeleteForm = false;
    },
    setupAlertDialog(status, title, message, text_color, link) {
      this.title = title;
      this.message = message;
      this.dialogAdd = status;
      this.text_color = text_color;
    },
    setupAlertDialogPush(status, title, message, text_color, link) {
      this.title = title;
      this.message = message;
      this.dialogAdd = status;
      this.text_color = text_color;
      this.link = link;
    },
    //---------------function file------------//
    async popupdeletefile(index) {
            this.fileindex = index;
            this.dialogDelete = true;
          },

          async closeDelete() {
            this.fileindex = -1;
            this.dialogDelete = false;
          },
          async deleteItemConfirm() {
            console.log(this.image_list);
            this.preview_list2 = [];
            this.getpreview_list.splice(this.fileindex, 1);
            this.image_list.splice(this.fileindex, 1);
            await this.getPdfforAdd(this.getpreview_list);

            this.filename.splice(this.fileindex, 1);
            this.preview_list2 = this.preview_list;
            this.fileindex = -1;
            this.dialogDelete = false;
          },
          async popupdeleteimage() {
            this.dialogDeleteimage = true;
          },
          async closeDeleteimage() {
            this.dialogDeleteimage = false;
          },
          async deleteItemConfirmimage() {
            this.checkimageadd = false;
            this.image = null;
            this.previewimage = "";
            this.imagename = "";
            this.dialogDeleteimage = false;
          },
          async previewnoticefilepdf(file_name) {
            this.previewpopupfile2 = [];
            console.log(file_name)
            let file_name_s = file_name;
            let pdf_newTab = window.open(
                `${POAUrl}/${this.$route.params.id}/${file_name_s}`
            );

              return;
          },
          async popuppreviewfile(fileindex) {
            let pdf_newTab = window.open("");
            pdf_newTab.document.write(
              "<html><head><title>pdf file</title></head><body><iframe title='MY title'  width='100%' height='100%' src='" +
                this.getpreview_list[fileindex] +
                "'></iframe></body></html>"
            );

            return;
            this.previewpopupfile2 = [];
            await this.getPdfforpreview(this.getpreview_list[fileindex]);
            console.log(this.previewpopupfile);
            this.previewpopupfile2 = this.previewpopupfile;
            this.checkfileapreview = true;
            this.dialogpreviewfile = true;
          },
          async closepreviewfile() {
            this.dialogpreviewfile = false;
          },
          async popuppreviewimage() {
            this.dialogpreviewimage = true;
          },
          async closepreviewimage() {
            this.dialogpreviewimage = false;
          },
          previewImage: function(event) {
            var input = event.target;
            if (input.files) {
              var reader = new FileReader();
              reader.onload = (e) => {
                this.previewimage = e.target.result;
                this.checkimageadd = true;
              };
              this.image = input.files[0];
              reader.readAsDataURL(input.files[0]);
            }

            this.imagename = this.image.name;
          },
          async previewMultiImage(event) {
            this.filename = [];
            this.preview_list = [];
            this.getpreview_list = [];
            // this.image_list = [];
            var input = event.target;
            var count = input.files.length;
            var index = 0;

            if (input.files) {
              while (count--) {
                var reader = new FileReader();
                reader.onload = (e) => {
                  this.getpreview_list.push(e.target.result);
                  this.preview_list.push(e.target.result);
                };
                if (input.files[index].type === "application/pdf") {
                  this.image_list.push(input.files[index]);
                  reader.readAsDataURL(input.files[index]);
                }
                index++;
              }
            }

            await this.getPdfforAdd(this.getpreview_list);

            this.preview_list2 = this.preview_list;

            this.image_list.forEach((x,index) => {
              this.filename.push(x.name);
              
             if(index == this.image_list.length - 1){
              console.log("this.image_list")
              console.log(this.filename)
             }
            });
              
            this.checkfileeadd = true;
    
          },

          reset: function() {
            this.image = null;
            this.previewimage = null;
            this.image_list = [];
            this.preview_list = [];
          },
          async getPdfforpreview(datafile) {
            this.previewpopupfile = [];
            let getfile = [];
            getfile = datafile;
            this.previewpopupfile.push(pdfvuer.createLoadingTask(`${getfile}`));
            this.previewpopupfile.forEach((item, index) => {
              item.then((pdf) => {
                this.numPagespreview = pdf.numPages;
              });
            });
            console.log(this.previewpopupfile);
          },

          async getPdfforAdd(item) {
            this.preview_list = [];
            let getfile = [];
            getfile = item;
            getfile.forEach((file, index) => {
              this.preview_list.push(pdfvuer.createLoadingTask(`${file}`));
            });
            this.preview_list.forEach((item, index) => {
              item.then((pdf) => {
                this.numPages = pdf.numPages;
                // window.onscroll = function() {
                //   changePage();
                //   stickyNav();
                // };
                // Get the offset position of the navbar
                // var sticky = $("#buttons")[0].offsetTop;
                // // Add the sticky class to the this.$refs.nav when you reach its scroll position. Remove "sticky" when you leave the scroll position
                // function stickyNav() {
                //   if (window.pageYOffset >= sticky) {
                //     $("#buttons")[0].classList.remove("hidden");
                //   } else {
                //     $("#buttons")[0].classList.add("hidden");
                //   }
                // }
                function changePage() {
                  var i = 1,
                    count = Number(pdf.numPages);
                  do {
                    if (
                      window.pageYOffset >=
                        this.findPos(document.getElementById(i)) &&
                      window.pageYOffset <=
                        this.findPos(document.getElementById(i + 1))
                    ) {
                      this.page = i;
                    }
                    i++;
                  } while (i < count);
                  if (
                    window.pageYOffset >= this.findPos(document.getElementById(i))
                  ) {
                    this.page = i;
                  }
                }
              });
            });
            this.checkfileadd = true;
          },
          handle_pdf_link: function(params) {
            // Scroll to the appropriate place on our page - the Y component of
            // params.destArray * (div height / ???), from the bottom of the page div
            var page = document.getElementById(String(params.pageNumber));
            page.scrollIntoView();
          },
          findPos(obj) {
            return obj.offsetTop;
          },
          //--------------------end funcfile--------------------//

  },
  beforeCreate() {
    // console.log("beforeCreate");
    this.$store.state.navMenu = true;
  },
};
